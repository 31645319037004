import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BlockUIModule } from 'ng-block-ui';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { ScriptLoaderService } from '../script-loader.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountComponent } from './pages/account/account.component';
import { AttendanceEmployeeComponent } from './pages/attendance-employee/attendance-employee.component';
import { ChatComponent } from './pages/chat/chat.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EmployeeLessonsComponent } from './pages/employeeLessons/employeeLessons.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { InstallmentComponent } from './pages/installment/installment.component';
import { LibraryComponent } from './pages/library/library.component';
import { LoginComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { News2Component } from './pages/news2/news2.component';
import { News3Component } from './pages/news3/news3.component';
import { NewsEmployeeComponent } from './pages/newsEmployee/newsEmployee.component';
import { QRComponent } from './pages/qr/qr.component';
import { QRLeaveComponent } from './pages/qrLeave/qr.component';
import { SalaryComponent } from './pages/salary/salary.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SoonComponent } from './pages/soon/soon.component';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { StatsComponent } from './pages/stats/news.component';
import { StudentGroupsComponent } from './pages/studentGroups/studentGroups.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { TransactionOutComponent } from './pages/transactionOUT/transaction.component';
import { PrintComponent } from './print/print/print.component';
import { TestComponent } from './print/template/test/test.component';
import { ImageBox } from './services/image-box.service';
import { SharedModule } from './shared.module';
import { FooterComponent } from './structure/footer/footer.component';
import { HeaderComponent } from './structure/header/header.component';
import { SideContentComponent } from './structure/side/parts/side-content/side-content.component';
import { SideNavComponent } from './structure/side/parts/side-nav/side-nav.component';
import { SideComponent } from './structure/side/side.component';
import {NotificationComponent} from './pages/notification/notification.component';
import {InstallmentHistoryComponent} from './pages/installment-history/installment-history.component';
import {InstallmentClassComponent} from './pages/installment-class/installment-class.component';
import { ZoomPipe } from './zoom.pipe';
import {ChatGroupComponent} from './pages/chatGroup/chat-group.component';
import {PollComponent} from './pages/poll/poll.component';
import {AttendPostComponent} from './pages/attend-post/attend-post.component';
import {StorageComponent} from './pages/storage/storage.component';
import {StorageLogComponent} from './pages/storage-log/storage-log.component';
import {EmployeeStatsComponent} from './pages/employee-stats/employee-stats.component';
import {InstallmentBusComponent} from "./pages/installment-bus/installment-bus.component";
import {RoleComponent} from "./pages/role/role.component";
import {StudentFullReportComponent} from "./pages/student-full-report/student-full-report.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// import * as Sentry from '@sentry/angular';
// import { Integrations } from '@sentry/tracing';
// import { Router } from '@angular/router';

// import { AppModule } from './app/app.module';

// Sentry.init({
//   dsn: 'https://8e96dfc8ded64a4bae893c68b4bd9ce1@o476259.ingest.sentry.io/5515558',
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ['localhost', 'https://yourserver.io/api'],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });
// export class SentryErrorHandler implements ErrorHandler {
//   handleError(err: any): void {
//     Sentry.captureException(err.originalError || err);
//   }
// }

// enableProdMode();
// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .then(success => console.log(`Bootstrap success`))
//   .catch(err => console.error(err));
@NgModule({
  declarations: [
    AppComponent,
    SideComponent,
    SideNavComponent,
    SideContentComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ChatComponent,
    StudentGroupsComponent,
    EmployeesComponent,
    NewsComponent,
    News2Component,
    News3Component,
    PrintComponent,
    TestComponent,
    SettingsComponent,
    SoonComponent,
    LibraryComponent,
    NewsEmployeeComponent,
    EmployeeLessonsComponent,
    AttendanceEmployeeComponent,
    QRComponent,
    QRLeaveComponent,
    StatsComponent,
    InstallmentComponent,
    AccountComponent,
    TransactionComponent,
    TransactionOutComponent,
    SalaryComponent,
    StatisticsComponent,
    NotificationComponent,
    InstallmentHistoryComponent,
    InstallmentClassComponent,
    PollComponent,
    AttendPostComponent,
    ChatGroupComponent,
    StorageComponent,
    StorageLogComponent,
    EmployeeStatsComponent,
    InstallmentBusComponent,
    RoleComponent,
    StudentFullReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BlockUIModule.forRoot(),
    NgsRevealModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    LeafletModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [ScriptLoaderService, ImageBox, DatePipe,
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
