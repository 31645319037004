import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {Conversation, GroupConversation, IGroupMessage, IMessage, Student} from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ImageBox } from '../../services/image-box.service';
import { ResponsiveService } from '../../services/responsive.service';
import { WebsocketService } from '../../services/websocket.service';
declare var $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('1s ease-in', style({opacity: '1'})),
      ]),
    ]),
  ],
})
export class ChatGroupComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @BlockUI('main') blockUI: NgBlockUI;
  @BlockUI('chat') blockUIChat: NgBlockUI;
  @ViewChild('imageUpload', {static: true})imageUpload: ElementRef;
  @ViewChild('pdfUpload', {static: true})pdfUpload: ElementRef;
  isFetching = false;
  isScrolling = false;
  page = 0;
  isFetchingConversations = false;
  isScrollingConversations = false;
  conversations: GroupConversation[] = [];
  conversationsData: GroupConversation[] = [];
  students: Student[] = [];
  studentsData: Student[] = [];
  selectedConversationIndex = null;
  sending = false;
  query = '';
  type: 'all' | 'seen' | 'not_seen' = 'all';
  queryModal = '';
  message = new FormControl('', Validators.required);
  studentId;
  @ViewChild('scrollMe', {static: false}) private myScrollContainer: ElementRef;

  constructor(
    public responsive: ResponsiveService,
    public API: APIService,
    private websocket: WebsocketService,
    private router: ActivatedRoute,
    public imageBox: ImageBox,
  ) {
    this.router.params.subscribe(({studentId}) => {
      this.studentId = studentId ? studentId : null;
    });
    this.websocket.groupMessageEmitter.subscribe((message: { message: IGroupMessage }) => {
      this.conversations.forEach((conversation, index) => {
        conversation.messages.push(message.message);
        if (this.selectedConversationIndex !== null) {
          this.selectedConversationIndex = 0;
          setTimeout(() => this.scrollToBottom(), 50);
          this.API.seenGroupConversation().then(() => this.conversationsData[this.selectedConversationIndex].unseen = 0);
        } else {
          conversation.unseen++;
        }
      });
      this.conversations = this.conversations.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      this.conversationsData = this.conversationsData.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      this.responsive.playNotify('notify', true);
    });
  }


  ngOnInit() {
    this.scrollToBottom();
    this.responsive.check();
  }

  ngAfterViewInit(): void {
    if (!this.studentId) {
      this.fetch();
    } else {
      this.fetchConversation(this.studentId).then(
        conversation => this.fetch(conversation)
      );
    }
  }

  ngAfterViewChecked() {
    if (this.isScrolling) {
      this.scrollToBottom();
    }
  }

  fetch(conversation: Conversation = null, filter = false) {
    if (!this.isFetchingConversations) {
      this.isFetching = true;
      this.isFetchingConversations = true;
      this.blockUI.stop();
      this.blockUI.start('جاري التحميل...');
      if (filter) {
        this.page = 0;
        this.conversations = [];
        this.conversationsData = [];
        this.selectedConversationIndex = null;
      }
      this.API.fetchGroupConversations(this.page, this.type).then((conversations: GroupConversation[]) => {
        this.page++;
        conversations = conversations.map((myConversation) => {
          myConversation.messages = [];
          return myConversation;
        });
        this.conversationsData.push(...conversations);
        this.conversations.push(...conversations);
        if (conversation !== null) {
          this.conversations.forEach((myConversation, index) => {
            if (myConversation._id === conversation._id) {
              this.selectedConversationIndex = index;
            }
          });
        }
        this.blockUI.stop();
        this.isFetchingConversations = false;
        this.isFetching = false;
      });
    }
  }

  fetchConversation(studentId): Promise<Conversation> {
    return this.API.fetchConversation('student', studentId);
  }

  selectConversation(index: number) {
    this.selectedConversationIndex = index;

    if (this.conversationsData[this.selectedConversationIndex].messages.length < 30) {
      this.fetchMessages();
    }
    if (this.conversationsData[this.selectedConversationIndex].unseen > 0) {
      this.API.seenGroupConversation().then(result => this.conversationsData[this.selectedConversationIndex].unseen = 0);
    }
  }
  fetchMessages() {
    if (!this.isFetching) {
      this.blockUIChat.start('جاري التحميل');
      this.isFetching = true;
      const lastId = this.conversationsData[this.selectedConversationIndex].messages[0] ? this.conversationsData[this.selectedConversationIndex].messages[0]._id : null;
      this.API.fetchGroupMessages(this.conversationsData[this.selectedConversationIndex]._id, lastId).then((messages: IGroupMessage[]) => {
        this.isScrolling = lastId === null;
        this.conversationsData[this.selectedConversationIndex].messages.unshift(...messages);
        setTimeout(() => this.blockUIChat.stop(), lastId ? 250 : 0);
        this.isFetching = false;
      });
    }
  }
  scrollToBottom(): void {
    try {
      if (this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      }
    } catch (err) {
      console.error('scrollToBottom', err);
    }
  }

  newLine() {
    this.message.patchValue(this.message.value + '\r\n');
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.sending = true;
      const blob = event.target.files[0];
      const type = blob.type.split('/')[0];
      this.API.uploadFileConversationGroup(blob).then(res => {
        this.sending = false;
        this.imageUpload.nativeElement.value = '';
        this.pdfUpload.nativeElement.value = '';
      });
    }
  }
  sendMessage(event: Event) {
    event.preventDefault();
    this.sending = true;
    this.API.sendGroupCMessage(this.message.value).then(result => {
        this.sending = false;
        this.message.reset();
      },
      error => {
        this.sending = false;
        console.error('sendMessage', error);
      });
  }
  newConversationModal() {
    this.queryModal = '';
    $('#modal-new').modal('show');
  }
  createConversation(studentId: string) {
    this.fetchConversation(studentId).then(
      conversation => {
        this.fetch(null, true);
        $('#modal-new').modal('hide');
      }
    );
  }
  isOnline(date: Date) {
    return Math.floor(new Date(date).getTime() / 1000) >= (Math.floor(new Date().getTime() / 1000) - 300);
  }
}
