import {Injectable} from '@angular/core';
import * as $ from 'jquery';

declare let document: any;
interface Script {
  src: string;
  loaded: boolean;
}

@Injectable()
export class ScriptLoaderService {
  public scripts: Script[] = [];

  load(tag, ...scripts: string[]) {
    scripts.forEach((src: string) => {
      if (!this.scripts[src]) {
        this.scripts[src] = {src, loaded: false};
      }
    });

    const promises: any[] = [];
    scripts.forEach((src) => promises.push(this.loadScript(tag, src)));

    return Promise.all(promises);
  }
  loadScripts(tag, scripts, loadOnce?: boolean) {
    loadOnce = loadOnce || false;

    scripts.forEach((script: string) => {
      if (!this.scripts[script]) {
        this.scripts[script] = {src: script, loaded: false};
      }
    });

    const promises: any[] = [];
    scripts.forEach(
      (script) => promises.push(this.loadScript(tag, script, loadOnce)));

    return Promise.all(promises);
  }
  loadScript(tag, src: string, loadOnce?: boolean) {
    loadOnce = loadOnce || false;

    if (!this.scripts[src]) {
      this.scripts[src] = {src, loaded: false};
    }

    return new Promise((resolve, reject) => {
      // resolve if already loaded
      if (this.scripts[src].loaded && loadOnce) {
        resolve({src, loaded: true});
      } else {
        // load script tag
        const scriptTag = $('<script/>').attr('type', 'text/javascript').attr('src', this.scripts[src].src);

        $(tag).append(scriptTag);

        this.scripts[src] = {src, loaded: true};
        resolve({src, loaded: true});
      }
    });
  }
}
