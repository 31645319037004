export const environment = {
  production: true,
  Web: {
    PROTOCOL: 'https',
    URI: 'beta.sadeemlight.com/web_api_v4',
    PORT: '',
  },
  WS: {
    PROTOCOL: 'wss',
    //  URI: 'ameer.sadeemplus.com/api',
    // PORT: '',
    path: '/ws3/socket.io/',
    URI: 'beta.sadeemlight.com',
    PORT: '',
  },
  firebase: {
    apiKey: 'AIzaSyB5ArUdJBaZjxwOCztKozAsV-wvKUb64p`M`',
    authDomain: 'sadeem-plus-3ec32.firebaseapp.com',
    databaseURL: 'https://sadeem-plus-3ec32.firebaseio.com',
    projectId: 'sadeem-plus-3ec32',
    storageBucket: 'sadeem-plus-3ec32.appspot.com',
    messagingSenderId: '562136612451',
    appId: '1:562136612451:web:2b57bd043bed871565c256'
  },
};
