import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';
import {ScriptLoaderService} from '../../../script-loader.service';
import {DataTableDirective} from 'angular-datatables';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Employee, ExamSchedule, Library} from '../../interfaces/API';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {APIService} from '../../services/API.service';

// @ts-ignore
import * as findIndex from 'lodash/findIndex';

declare var $: any;

interface Form {
  subject: FormControl;
  date: FormControl;
  details: FormControl;
  comment: FormControl;
}

@Component({
  selector: 'app-exam-schedule',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  @BlockUI('modal') modalBlockUi: NgBlockUI;
  uploadProgress = 0;
  selectedClassIndex = null;
  isTable = true;
  filter = {
    class: new FormControl('-1', [Validators.required]),
    subject: new FormControl({value: '-1', disabled: 1}, [Validators.required, Validators.pattern('^[0-9a-fA-F]{24}$')]),
  };
  formMode: 'adding' | 'editing' | 'deleting' | 'details' = 'adding';
  unsaved = false;
  alert = null;
  selectedFormClassIndex;
  statistics = {
    all: 0,
    video: 0,
    audio: 0,
    pdf: 0
  };
  fileForm: FormGroup;
  library: Library[] = [];
  libraryData: Library[] = [];
  dropdown = false;
  dropdownData: Library = null;
  dtOptions: DataTables.Settings = {
    info: false,
    search: true,
    lengthChange: true,
    pageLength: 10,
    dom: 'Rfrtlip',
    scrollY:        '70vh',
    scrollCollapse: true,
    columnDefs: [
      {orderable: true, targets: [2]}
    ],

  };
  dtTrigger: Subject<any> = new Subject();
  file: File;
  filtering = 'all';
  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, public API: APIService, private scriptLoader: ScriptLoaderService, private fb: FormBuilder) {
    this.fileForm = this.fb.group({
      title: ['', [Validators.required]],
      class: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      comment: ['', []],
      type: ['', [Validators.required]],
    });
  }


  ngAfterViewInit() {
    this.redrawDataTable();
    // this.scriptLoader
    //   .loadScripts('body', [
    //     'assets/js/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
    //   ])
    //   .then(() => {
    //     $.fn.datepicker.dates.en = {
    //       ...$.fn.datepicker.dates.en,
    //       today: 'تاريخ اليوم',
    //       clear: 'مسح',
    //       format: 'yyyy-mm-dd'
    //     };
    //     $('[data-plugin="datepicker"]').datepicker({
    //       todayBtn: 'linked',
    //       today: 'اليوم',
    //       format: 'yyyy-mm-dd'
    //     }).on('changeDate', (event) => this.dateChanged(event));
    //   });
    // this.fetch();
  }

  showModal(mode: 'adding' | 'editing' | 'deleting' | 'details') {
    this.formMode = mode;
    switch (mode) {
      case 'adding':
        this.file = null;
        this.fileForm.reset();
        $('#modal-animate').modal('show');
        break;
      case 'editing':
        this.fileForm.patchValue(this.dropdownData);
        $('#modal-animate').modal('show');
        break;
      case 'deleting':
        $('#modal-animate').modal('show');
        break;
    }
  }
  submit() {
    this.modalBlockUi.start('جاري الرفع');
    if (this.formMode === 'adding') {
      const form = new FormData();
      form.append('file', this.file);
      Object.keys(this.fileForm.value).forEach((key) =>
        form.append(key, this.fileForm.value[key])
      );
      form.delete('class');
      form.delete('subject');
      form.append('class', this.API.classes[this.selectedClassIndex]._id);
      form.append('subject', this.fileForm.value.subject);
      this.API.uploadLibraryFile(form).subscribe((data) => {
        if (data.status === 'progress') {
          this.uploadProgress = data.data;
        } else if (data.status === 'done') {
          this.API.showAlert('تم الرفع بنجاح', 'success');
          this.fetch(this.filter.subject.value);
        }
      },
        error => {
          console.log(error);
          this.API.showAlert('حدث خطأ في الرفع', 'error');
        });
    } else if (this.formMode === 'editing') {
      this.API.updateLibraryFile(this.dropdownData._id, {
        title: this.fileForm.value.title,
        comment: this.fileForm.value.comment,
      }).then(response => {
        this.fetch();
      });
    } else if (this.formMode === 'deleting') {
      this.API.deleteLibraryFile(this.dropdownData._id).then(response => {
        this.fetch();
      });
    }
  }

  fetch(subject: string = this.filter.subject.value) {
    this.isTable = true;
    $('#modal-animate').modal('hide');
    this.blockUI.stop();
    console.log(this.filter.class.valid);
    console.log(this.filter.subject.valid);
    if (!this.filter.class.valid || !this.filter.subject.valid) {
      return;
    }
    this.unsaved = false;
    this.blockUI.start('جاري التحميل...');
    this.statistics = {
      all: 0,
      video: 0,
      audio: 0,
      pdf: 0
    };
    this.API.fetchLibrary(subject).then((library: Library[]) => {
      // students.forEach(student => this.initFormControl(student._id));
      this.library = library;
      this.libraryData = library;
      this.library.forEach((file) => this.statistics[file.type]++);
      this.calculateStatistics();

      this.redrawDataTable();
    });
  }

  calculateStatistics() {
    // this.statistics = {
    //   all: 0,
    //   success: 0,
    //   fail: 0,
    //   not: 0
    // };
    // this.students.forEach((student) => {
    //   const studentMark = this.dailyMarksForms.get(student._id).mark.value;
    //   if (studentMark !== null && studentMark !== '') {
    //     // if (studentMark >= this.filter.success.value) {
    //     //   this.statistics.success++;
    //     // } else {
    //     //   this.statistics.fail++;
    //     // }
    //   }
    // });
    // this.statistics.not = this.students.length - this.statistics.success - this.statistics.fail;
    // this.statistics.all = this.students.length;
  }

  ngOnInit(): void {
    this.responsive.check();
  }

  dropDown(event: MouseEvent, library: Library): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData = library;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  classChanged(event: any, type: string) {
    const {value} = event.target;
    if (this.unsaved) {
      event.preventDefault();
      this.alert = 'يوجد تغييرات في هذه الشعبة لم يتم حفظها الرجاء حفظها او الغائها';
      $('#modal-alert').modal();
      return;
    }
    if (type === 'class') {
      this.filter.subject.enable();
      this.filter.subject.patchValue('-1');
      this.library = [];
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          this.blockUI.stop();
        });
      }
    } else if (type === 'subject') {
      this.fetch(value);
      // this.exams = this.examsData.filter((student: any) => student.branch._id === value);
      // this.calculateStatistics();
      // if (this.dtElement.dtInstance) {
      //   this.blockUI.start('جاري التحميل...');
      //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //     dtInstance.destroy();
      //     this.dtTrigger.next();
      //     this.blockUI.stop();
      //   });
      // } else {
      //   this.dtTrigger.next();
      //   this.blockUI.stop();
      // }
    }
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const blob = event.target.files[0];
      const name = blob.name.split('.').slice(0, -1).join('.');
      let type = blob.type.split('/')[0];
      if (type === 'application') {
        type = 'pdf';
      }
      this.file = blob;
      const patch: any = {
        type,
      };
      if (this.fileForm.value.title === '') {
          patch.title = name;
      }
      this.fileForm.patchValue(patch);
      console.log(blob);
    }
  }
  filterLibrary(type: string) {
    this.filtering = type;
    this.library = this.libraryData.filter((file) => type === 'all' || file.type === type);
    this.redrawDataTable();
  }
  redrawDataTable() {
    const instance = this.dtElement.dtInstance;
    if (instance) {
      instance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.blockUI.stop();
      });
    } else {
      this.dtTrigger.next();
      this.blockUI.stop();
    }
  }
  classChangedForm(event: any) {
    const {value} = event.target;
    this.selectedClassIndex = value;
    this.fileForm.controls.branch.enable();
    this.fileForm.controls.branch.patchValue(null);
    this.fileForm.controls.class.patchValue(this.API.classes[this.selectedClassIndex]._id);
  }
}
