import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';
import {ScriptLoaderService} from '../../../script-loader.service';
import {DataTableDirective} from 'angular-datatables';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Employee, ExamSchedule, IStorage, Library, Student} from '../../interfaces/API';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {APIService} from '../../services/API.service';

// @ts-ignore
import * as findIndex from 'lodash/findIndex';
import {APIConfig} from '../../config/API';
import {HttpClient} from '@angular/common/http';

declare var $: any;

interface Form {
  subject: FormControl;
  date: FormControl;
  details: FormControl;
  comment: FormControl;
}

@Component({
  selector: 'app-exam-schedule',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  @BlockUI('modal') modalBlockUi: NgBlockUI;
  uploadProgress = 0;
  filter = {
    barcode: new FormControl('', []),
    from_date: new FormControl('', []),
    to_date: new FormControl('', []),
  };
  size_types = {
    quantity: 'قطعة',
    weight: 'كيلوغرام',
    height: 'متر',
  };
  formMode: 'adding' | 'editing' | 'deleting' | 'details' | 'IN' | 'OUT' | 'RETURN' | 'LOST' = 'adding';
  alert = null;
  selectedFormClassIndex;
  mainForm: FormGroup;
  logForm: FormGroup;
  items: IStorage[] = [];
  itemsData: IStorage[] = [];
  dropdown = false;
  dropdownData: IStorage = null;
  dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  filtering = 'all';
  constructor(public responsive: ResponsiveService, private http: HttpClient, public sanitizer: DomSanitizer, public API: APIService, private scriptLoader: ScriptLoaderService, private fb: FormBuilder) {
    this.mainForm = this.fb.group({
      name: ['', [Validators.required]],
      size_type: ['', [Validators.required]],
      barcode: ['', [Validators.required]],
      type: ['', [Validators.required]],
    });
    this.logForm = this.fb.group({
      size: [0, [Validators.required]],
      price: [0, []],
      comment: ['', []],
    });
  }


  ngAfterViewInit() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.on('draw.dt', () => {
        if (this.itemsData.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    // this.scriptLoader
    //   .loadScripts('body', [
    //     'assets/js/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
    //   ])
    //   .then(() => {
    //     $.fn.datepicker.dates.en = {
    //       ...$.fn.datepicker.dates.en,
    //       today: 'تاريخ اليوم',
    //       clear: 'مسح',
    //       format: 'yyyy-mm-dd'
    //     };
    //     $('[data-plugin="datepicker"]').datepicker({
    //       todayBtn: 'linked',
    //       today: 'اليوم',
    //       format: 'yyyy-mm-dd'
    //     });
    //   });
  }

  showModal(mode: 'adding' | 'editing' | 'deleting' | 'details' | 'IN' | 'OUT' | 'RETURN' | 'LOST') {
    this.formMode = mode;
    this.mainForm.reset();
    if (this.dropdownData) {
      this.mainForm.patchValue(this.dropdownData);
    }
    this.logForm.reset();
    $('#modal-animate').modal('show');
  }
  submit() {
    this.modalBlockUi.start('جاري العمل ...');
    if (this.formMode === 'adding') {
      this.API.createStorage(this.mainForm.value).subscribe((result) => {
        this.API.showAlert('تمت الاضافة بنجاح', 'success');
        this.fetch();
      }, (error) => {
        this.modalBlockUi.stop();
        this.API.showAlert('حدث خطأ', 'error');
      }, () => {
        this.modalBlockUi.stop();
      });
    } else if (this.formMode === 'editing') {
      this.API.updateStorage(this.dropdownData._id, this.mainForm.value).subscribe((result) => {
        this.API.showAlert('تم التعديل بنجاح', 'success');
        this.fetch();
      }, (error) => {
        this.modalBlockUi.stop();
        this.API.showAlert('حدث خطأ', 'error');
      }, () => {
        this.modalBlockUi.stop();
      });
    } else if (this.formMode === 'deleting') {
      this.API.deleteStorage(this.dropdownData._id).subscribe((result) => {
        this.API.showAlert('تم الحذف بنجاح', 'success');
        this.fetch();
      }, (error) => {
        this.modalBlockUi.stop();
        this.API.showAlert('حدث خطأ', 'error');
      }, () => {
        this.modalBlockUi.stop();
      });
    } else {
      this.API.logStorage(this.dropdownData._id, {
        ...this.logForm.value,
        type: this.formMode
      }).subscribe((result) => {
        this.API.showAlert('تم الادراج بنجاح', 'success');
        this.fetch();
      }, (error) => {
        this.modalBlockUi.stop();
        this.API.showAlert('حدث خطأ', 'error');
      }, () => {
        this.modalBlockUi.stop();
      });
    }
  }

  fetch() {
    $('#modal-animate').modal('hide');
    this.blockUI.stop();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  calculateStatistics() {
    // this.statistics = {
    //   all: 0,
    //   success: 0,
    //   fail: 0,
    //   not: 0
    // };
    // this.students.forEach((student) => {
    //   const studentMark = this.dailyMarksForms.get(student._id).mark.value;
    //   if (studentMark !== null && studentMark !== '') {
    //     // if (studentMark >= this.filter.success.value) {
    //     //   this.statistics.success++;
    //     // } else {
    //     //   this.statistics.fail++;
    //     // }
    //   }
    // });
    // this.statistics.not = this.students.length - this.statistics.success - this.statistics.fail;
    // this.statistics.all = this.students.length;
  }

  ngOnInit(): void {
    this.responsive.check();
    this.dtOptions = {
      info: false,
      search: true,
      lengthMenu: [10, 25, 50],
      lengthChange: true,
      pageLength: 10,
      dom: 'Rfrtlip',
      scrollY: '70vh',
      scrollCollapse: true,
      columnDefs: [
        { orderable: true, targets: [2] }
      ],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.modalBlockUi.stop();
        this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: IStorage[];
            recordsTotal: number;
            recordsFiltered: number;
          }>(
            APIConfig.uri + 'storage/datatable',
            { ...dataTablesParameters, barcode: this.filter.barcode.value, from_date: this.filter.from_date.value, to_date: this.filter.to_date.value }, { headers: this.API.AuthHeaders }
          ).subscribe(resp => {
          this.blockUI.stop();
          this.items = resp.data;
          this.itemsData = resp.data;
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      },
      language: {
        search: 'بحث :',
        lengthMenu: 'عرض _MENU_ حقل في الصفحه',
        paginate: {
          first: 'الاولى',
          last: 'الاخيرة',
          next: 'التالي',
          previous: 'السابق'
        },
        info: 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable: 'لا يوجد معلومات لعرضها'
      }
    };
  }

  dropDown(event: MouseEvent, items: IStorage): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData = items;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }
}
