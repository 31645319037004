import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {ScriptLoaderService} from '../script-loader.service';
import {AuthService} from './services/auth.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {ResponsiveService} from './services/responsive.service';
import {APIService} from './services/API.service';
import {WebsocketService} from './services/websocket.service';
import {Title} from '@angular/platform-browser';
import {fader, slider, stepper, transformer} from './slide-route-animation';
import { TranslateService } from '@ngx-translate/core';

declare let $: any;
declare var feather: any;

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slider,
  ]
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {
  loading = false;
  animation = 'isRight';
  constructor(
    private scriptLoader: ScriptLoaderService,
    public Auth: AuthService,
    private router: Router,
    private responsive: ResponsiveService,
    public API: APIService,
    private WS: WebsocketService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private changeDetector: ChangeDetectorRef,
    translate: TranslateService
  ) {
    translate.setDefaultLang('ar');
    // translate.use("es");
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.animation = this.animation === 'isRight' ? 'isLeft' : 'isRight';
        this.loading = true;
      } else if (val instanceof NavigationEnd) {
        feather.replace({width: 16, height: 16});
        this.loading = false;
        const title = this.getTitle(router.routerState, router.routerState.root).join(' | ');
        titleService.setTitle(title);
      }
    });
    if (!this.Auth.isAuthenticated()) {
      this.router.navigate(['login']);
    } else {
      this.API.fetch();
    }
  }
  prepareRoute(outlet: RouterOutlet) {
    if (localStorage.getItem('route_change_effect') !== 'true') {
      return 'none';
    }
    return this.animation;
  }
  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  ngOnInit(): void {
    this.scriptLoader.loadScripts('body', [
      'assets/js/theme.js'
    ]).then(() => console.log('done'));
    $('[data-plugin=imagebox]').on('click', (e) => {
      alert($(e).attr('src'));
    });
    // setTimeout(() => this.signed = true, 3000);
  }

  ngAfterViewChecked(): void {
    feather.replace({width: 16, height: 16});
    // const sr = ScrollReveal();
    // sr.reveal('.sr .card, .sr .list-item, .sr .item, .sr .sr-item, .sr-custom',
    //   { afterReveal(el) {$(el).css('transform', 'none').css('transition', 'none'); } }, 50);
    // sr.sync();
  }

  ngAfterViewInit(): void {
  }
}
