import {HostListener, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  isMobile = false;
  constructor() {
  }

  playNotify(type: string, inactiveOnly: boolean) {
    if (document.hasFocus() && inactiveOnly && localStorage.getItem('notifications_sounds') === 'true') {
      return;
    }
    const noty = new Notification('لديك راسلة جديدة');
    const audio = new Audio();
    audio.src = `./assets/${type}.mp3`;
    audio.load();
    audio.play().then(() => console.log('notification sound played.'));
  }
  check() {
    this.isMobile = window.innerWidth  <= 991.98;
    window.onresize = () => {
      this.isMobile = window.innerWidth  <= 991.98;
    };
  }
}
