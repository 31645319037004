import {AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {
  Attendance,
  AttendanceResponse,
  Conversation,
  DailyMarks,
  FullReport,
  IMessage,
  Student
} from '../../interfaces/API';
import {FormControl, Validators} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {APIService} from '../../services/API.service';
import {WebsocketService} from '../../services/websocket.service';
import {ActivatedRoute, Router} from '@angular/router';
import {promise} from 'selenium-webdriver';
import {ImageBox} from '../../services/image-box.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {mark} from '@angular/compiler-cli/src/ngtsc/perf/src/clock';
import {PrintService} from "../../services/print.service";
declare var $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './student-full-report.component.html',
  styleUrls: ['./student-full-report.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('.5s ease-out', style({opacity: '1'})),
      ]),
    ]),
  ],
})
export class StudentFullReportComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  @BlockUI('main') blockUI: NgBlockUI;
  @BlockUI('chat') blockUIChat: NgBlockUI;
  statistics = {
    success: 0,
    fail: 0,
  };
  isFetching = false;
  isScrolling = false;
  students: Student[] = [];
  studentsData: Student[] = [];
  selectedStudentIndex = null;
  report: FullReport;
  marks: DailyMarks[] = [];
  query = '';
  studyMonths = {
    semester: {month_1: 'كانون الاول', month_2: 'كانون الثاني', month_3: 'تشرين الاول', month_4: 'تشرين الثاني', middle_year: 'نصف السنه', month_5: 'شباط', month_6: 'اذار', month_7: 'نيسان', month_8: 'ايار', last_year: 'الامتحان النهائي', completion_1: 'الدور التكميلي الاول', completion_2: 'الدور التكميلي الثاني'},
    course: {month_1: 'شهر الاول كورس اول', month_2: 'شهر الثاني كورس اول', middle_year: 'كورس اول', month_5: 'شهر الاول كورس الثاني', month_6: 'شهر الثاني كورس الثاني', last_year: 'كورس ثاني', completion_1: 'الدور التكميلي الاول', completion_2: 'الدور التكميلي الثاني'},
  };
  ratesTrans = { Excellent: 'ممتاز', 'Very Good': 'جيد جداً', Good: 'جيد', Average: 'متوسط', Failure: 'ضعيف'};

  translate: {[key: string]: string} = {
    attended: 'حاضر',
    leave: 'مجاز',
    absent: 'غائب'
  };
  queryModal = '';
  message = new FormControl('', Validators.required);
  studentId;
  dtOptions: DataTables.Settings = {
    info: false,
    search: true,
    lengthChange: false,
    pageLength: 10,
    dom: 'Rfrtlip',
    scrollY:        '70vh',
    scrollCollapse: true,
    columnDefs: [
      {orderable: true, targets: [2]}
    ],

  };
  dtTrigger: Subject<any> = new Subject();

  constructor(
    public responsive: ResponsiveService,
    public API: APIService,
    private websocket: WebsocketService,
    private router: Router,
    private route: ActivatedRoute,
    public imageBox: ImageBox,
    public printService: PrintService
  ) {
    this.route.params.subscribe(({studentId}) => {
      this.studentId = studentId ? studentId : null;
    });
  }

  fetchStudents(reload: boolean = false) {
    this.API.fetchStudents().then((students: Student[]) => {
      this.studentsData = students;
      this.students = students;
    });
  }

  ngOnInit() {
    this.responsive.check();
  }

  ngAfterViewInit(): void {
    if (!this.studentId) {
      this.fetchStudents();
    } else {
      this.fetch(this.studentId);
    }
  }


  fetch(studentsId: string = null) {
    if (this.selectedStudentIndex) {
      this.fetchReport();
      return;
    }
    this.blockUI.stop();
    this.blockUI.start('جاري التحميل...');
    this.API.fetchStudents().then((students: Student[]) => {
      this.studentsData = students;
      this.students = students;
      if (studentsId !== null) {
        this.studentsData.forEach((myConversation, index) => {
          if (myConversation._id === studentsId) {
            this.selectedStudentIndex = index;
            this.fetchReport();
          }
        });
      }
      this.blockUI.stop();
    });
  }

  fetchConversation(studentId): Promise<Conversation> {
    return this.API.fetchConversation('student', studentId);
  }

  selectStudent(index: number) {
    this.router.navigate(['/', 'fullReport', this.students[index]._id]);
    this.selectedStudentIndex = index;
    this.fetchReport();
  }
  fetchReport() {
    this.blockUIChat.start('جاري التحميل');
    this.isFetching = true;
    this.statistics = {
      success: 0,
      fail: 0,
    };
    this.API.fetchStudentReport(this.studentsData[this.selectedStudentIndex]._id).then((report: FullReport) => {
      this.report = report;
      // this.conversationsData[this.selectedStudentIndex].messages.unshift(...messages);
      this.blockUIChat.stop();
      this.isFetching = false;
      // this.redrawDataTable();
    });
  }
  isOnline(date: Date) {
    return Math.floor(new Date(date).getTime() / 1000) >= (Math.floor(new Date().getTime() / 1000) - 300);
  }
  redrawDataTable() {
    const instance = this.dtElement.dtInstance;
    if (instance) {
      instance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.blockUI.stop();
      });
    } else {
      this.dtTrigger.next();
      this.blockUI.stop();
    }
  }
  print() {
    this.printService.data = this.report;
    this.printService.printDocument('student', 'report');
  }
}
