import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { APIConfig } from 'src/app/config/API';
import {Installment, Salary} from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ImageBox } from '../../services/image-box.service';
import { ResponsiveService } from '../../services/responsive.service';
import { WebsocketService } from '../../services/websocket.service';
import { Employee } from './../../interfaces/API';
import {DatePipe} from "@angular/common";
declare var $: any;

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('1s ease-in', style({opacity: '1'})),
      ]),
    ]),
  ],
})
export class SalaryComponent implements OnInit, AfterViewInit {
  @BlockUI('main') blockUI: NgBlockUI;
  @BlockUI('chat') blockUIChat: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  page = 1;
  paid = 0;
  employees: Employee[] = [];
  selectedStudentIndex = null;
  selectedEmployee: Employee;
  salarys: Salary[] = [];
  mainForm: FormGroup = this.fb.group({
    amount: [0, [Validators.required, Validators.min(0)]],
    adductions: [0, [Validators.required, Validators.min(0)]],
    deductions: [0, [Validators.required, Validators.min(0)]],
    no: ['', [Validators.required]],
    comment: ['', []],
  });
  query = '';
  type: 'all' | 'seen' | 'not_seen' = 'all';
  dtOptions: DataTables.Settings = {};
  salary = new FormControl('', [Validators.required]);
  isEditing = false;
  isSaving = false;

  constructor(
    public responsive: ResponsiveService,
    public API: APIService,
    private websocket: WebsocketService,
    private router: ActivatedRoute,
    private fb: FormBuilder,
    public imageBox: ImageBox,
    public http: HttpClient,
    private datePipe: DatePipe,
  ) {
  }
  editSalary() {
    this.isSaving = true;
    this.API.updateEmployee({
      salary: this.salary.value,
    }, this.selectedEmployee._id).then(() => {
      this.isEditing = false;
      this.isSaving = false;
      this.selectedEmployee.salary = this.salary.value;
    });
  }
  selectEmployee(employee: Employee) {
    this.selectedEmployee = employee;
    this.isEditing = false;
    this.salary.patchValue(employee.salary);
    setTimeout(() => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        if (this.salarys.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    });
  }
  fetchStudents(reload: boolean = false) {
    this.blockUI.start();
    if (reload) {
      this.page = 1;
      this.employees = [];
    } else {
      this.page++;
    }
    this.API.fetchSalaryEmployees(this.page, this.query).then((employees: Employee[]) => {
      this.employees.push(...employees);
      this.blockUI.stop();
    });
  }

  ngOnInit() {
    this.responsive.check();
    this.dtOptions = {
      info: false,
      search: true,
      lengthMenu: [10, 25, 50],
      lengthChange: true,
      pageLength: 10,
      dom: 'Rfrtlip',
      scrollY: '70vh',
      scrollCollapse: true,
      columnDefs: [
        { orderable: true, targets: [2] }
      ],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        if (!this.selectedEmployee) {
          return;
        }
        this.blockUI.stop();
        this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: Salary[];
            recordsTotal: number;
            recordsFiltered: number;
            paid: number;
          }>(
            APIConfig.uri + 'salary/datatable?' + (this.selectEmployee ? '&employee=' + this.selectedEmployee._id : ''),
            { ...dataTablesParameters, filter: this.type }, { headers: this.API.AuthHeaders }
          ).subscribe(resp => {
            this.blockUI.stop();
            this.salarys = resp.data;
            this.paid = resp.paid;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      language: {
        search: 'بحث :',
        lengthMenu: 'عرض _MENU_ حقل في الصفحه',
        paginate: {
          first: 'الاولى',
          last: 'الاخيرة',
          next: 'التالي',
          previous: 'السابق'
        },
        info: 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable: 'لا يوجد معلومات لعرضها'
      }
    };
  }

  ngAfterViewInit(): void {
    this.fetchStudents(true);
  }
  showModal() {
    this.mainForm.reset();
    $('#modal-animate').modal();
  }
  submit() {
    this.blockUIChat.start('جاري الحفظ');
    this.API.createSalary({
      ...this.mainForm.value,
      employee: this.selectedEmployee._id,
    }).then(student => {
      this.blockUIChat.stop();
      if (student) {
        this.selectEmployee(this.selectedEmployee);
        setTimeout(() => {
          this.mainForm.reset();
          $('#modal-animate').modal('hide');
        }, 1000);
      }
    }).catch((error) => {
      this.blockUIChat.stop();
    });
  }
  printItem(item: Salary) {
    const win = window.open('', 'Print', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + screen.width + ',height=' + screen.height + ',top=0,left=0');
    const date = this.datePipe.transform(new Date(), 'yyyy/MM/dd', '+0300');
    win.document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    win.document.body.innerHTML = `<html dir="rtl" lang="ar">
<style>
    body {
        width:210mm;
        margin-left: auto;
        margin-right: auto;
    }
@page {
    size: 7in 9.25in;
    margin: 27mm 16mm 27mm 16mm;
}
h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
  margin: 10px;
}
.bold {
  font-weight: bold;
}
div {
  float: right;
}
table{
    margin: 0 auto;
    border-collapse:collapse;
    text-align:center;
    border:1px solid #000;
    font-size:12px;
    }</style>
  <section>
  <div style="width: 70%; float: right">
    <h1 class="bold" style="font-size: 20pt">${this.API.school.name}</h1>
    <h2>مؤسسة تعليمية</h2>
    <h3>هاتف : ${this.API.school.phone1}</h3>
    <h3>هاتف : ${this.API.school.phone2}</h3>
  </div>
  <div style="width: 25%; float: right; text-align:left">
    <h1 style="font-size: 20pt; margin-bottom: 35px">وصل استلام راتب</h1>
  </div>
  </section>
  <section>
    <div style="width: 100%">
      <h2 class="bold">الى :</h2>
      <h3>الاستاذ : ${this.selectedEmployee.name}</h3>
      <h3>اسم المستخدم : ${this.selectedEmployee.username}</h3>
      <h3 class="bold">الملاحظات او التعليقات الخاصة او التعليمات العامة</h3>
      <div style="height: 100px!important;">${item.comment || ''}</div>
    </div>
  </section>
  <section>
    <table style="width: 693px; height: 192px;" border="1">
      <tbody>
        <tr style="height: 25px;">
          <td class="bold" style="width: 137.125px; height: 25px;">الراتب الاسمي</td>
          <td class="bold" style="width: 137.125px; height: 25px;">المكافئات</td>
          <td class="bold" style="width: 137.125px; height: 25px;">الحسم</td>
          <td class="bold" style="width: 137.125px; height: 25px;">المبلغ الصافي</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 153.453px; height: 25px;">${item.amount}</td>
          <td style="width: 153.453px; height: 25px;">${item.adductions || 0}</td>
          <td style="width: 153.453px; height: 25px;">${item.deductions || 0}</td>
          <td style="width: 153.453px; height: 25px;">${item.amount + item.adductions - item.deductions}</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
        </tr>
          <tr style="height: 25px;">
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td colspan="3">الاجمالي</td>
          <td style="width: 137.125px; height: 25px;">${item.amount + item.adductions - item.deductions}</td>
        </tr>
      </tbody>
    </table>
  </section>
  <section>
      <h3 class="bold">اسم المحاسب</h3>
      <br><br>
      <h3 class="bold">التوقيع</h3>
      <br><br>
    <div dir="ltr" style="width: 100%; margin-top: 20px; text-align: left">
      © Al Sadeem Systems
    </div>
  </section>
</html>`;
    win.print();
    win.onafterprint = () => {
      win.close();
    };
  }
}
