import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';
import {ScriptLoaderService} from '../../../script-loader.service';
import {DataTableDirective} from 'angular-datatables';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {FormControl, Validators} from '@angular/forms';
import {
  Attendance,
  AttendanceResponse,
  Branch,
  Employee, EmployeeAttendance,
  EmployeeAttendanceResponse,
  Student
} from '../../interfaces/API';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {APIService} from '../../services/API.service';

declare var $: any;

interface Form {
  status: FormControl;
  comment: FormControl;
}

@Component({
  selector: 'app-attendance-employee',
  templateUrl: './attendance-employee.component.html',
  styleUrls: ['./attendance-employee.component.scss']
})
export class AttendanceEmployeeComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  selectedClassIndex;
  attendanceForms: Map<string, Form> = new Map<string, Form>();
  selectedFormClassIndex;
  selectedDate = null;
  statistics = {
    all: 0,
    attended: 0,
    absent: 0,
    leave: 0,
    notAttended: 0
  };
  employees: Employee[] = [];
  employeesData: Employee[] = [];
  attendance: EmployeeAttendanceResponse = {
    attendance: [],
    notAttended: [],
  };
  attendanceData: Map<string, EmployeeAttendance> = new Map<string, EmployeeAttendance>();
  dropdown = false;
  dropdownData: Employee = null;
  dtOptions: DataTables.Settings = {
    info: false,
    search: true,
    lengthChange: false,
    pageLength: 10,
    dom: 'Rfrtlip',
    scrollY: '70vh',
    scrollCollapse: true,
    columnDefs: [
      {orderable: true, targets: [2]}
    ],

  };
  dtTrigger: Subject<any> = new Subject();
  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, public API: APIService, private scriptLoader: ScriptLoaderService) {
  }

  updateStatus(studentId, status) {
    this.attendanceForms.get(studentId).status.patchValue(status);
  }


  dateChanged({target}) {
    const {value} = target;
    this.selectedDate = value;
    this.fetch(value);
  }

  ngAfterViewInit() {
    this.redrawDataTable();
    this.scriptLoader
      .loadScripts('body', [
        'assets/js/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
      ])
      .then(() => {
        $.fn.datepicker.dates.en = {
          ...$.fn.datepicker.dates.en,
          today: 'تاريخ اليوم',
          clear: 'مسح',
          format: 'yyyy-mm-dd'
        };
        $('[data-plugin="datepicker"]').datepicker({
          todayBtn: 'linked',
          today: 'اليوم',
          format: 'yyyy-mm-dd'
        }).on('changeDate', (event) => this.dateChanged(event));
      });
    // this.fetch();
  }

  initFormControl(employeeId): Form {
    const status = new FormControl('attended', [Validators.required]);
    const comment = new FormControl('', [Validators.required]);
    if (this.attendanceData.get(employeeId)) {
      comment.patchValue(this.attendanceData.get(employeeId).comment);
      status.patchValue(this.attendanceData.get(employeeId).status);
    }
    this.attendanceForms.set(employeeId, {
      status,
      comment
    });
    return {
      status,
      comment
    };
  }

  getAttendanceForm(employeeId): Form {
    return this.attendanceForms.get(employeeId) ? this.attendanceForms.get(employeeId) : this.initFormControl(employeeId);
  }

  submit() {
    this.blockUI.stop();
    this.blockUI.start('جاري التحميل...');
    const attendanceList: { status: string, comment: string, employeeId: string }[] = [];
    this.attendanceForms.forEach((form: Form, key: string) => {
      // if (form.status.touched || form.comment.touched) {
      attendanceList.push({
        status: form.status.value,
        comment: form.comment.value,
        employeeId: key
      });
      // }
    });
    const payload = {
      attendances: attendanceList,
      date: this.selectedDate.toString()
    };
    this.API.createEmployeeAttendances(payload).then(response => {
      this.fetch(this.selectedDate);
    });
    // this.attendanceForms.forEach(form => {
    //   console.log(form.status);
    // });
  }

  fetch(date: string = new Date().toISOString().substring(0, 10)) {
    this.blockUI.stop();
    this.blockUI.start('جاري التحميل...');
    this.attendanceData.clear();
    this.attendanceForms.clear();
    this.attendance.attendance = [];
    this.attendance.notAttended = [];
    this.statistics = {
      all: 0,
      attended: 0,
      absent: 0,
      leave: 0,
      notAttended: 0
    };
    this.API.fetchEmployeeAttendances(date).then(attendance => {
      this.attendance = attendance;
      attendance.attendance.forEach(employeeAttendance => {
        this.attendanceData.set(employeeAttendance.employee, employeeAttendance);
        this.statistics[employeeAttendance.status]++;
      });
      this.statistics.notAttended = attendance.notAttended.length;
      this.API.fetchEmployees().then((employees: Employee[]) => {
        employees.forEach(employee => this.initFormControl(employee._id));
        this.employeesData = employees;
        this.employees = employees;
        this.statistics.all = employees.length;
        this.redrawDataTable();
      });
    });
  }

  ngOnInit(): void {
    this.responsive.check();
  }

  dropDown(event: MouseEvent, employee: Employee): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData = employee;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }


  redrawDataTable() {
    const instance = this.dtElement.dtInstance;
    if (instance) {
      instance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.blockUI.stop();
      });
    } else {
      if (this.employees.length > 0) {
        this.dtTrigger.next();
      }
      this.blockUI.stop();
    }
  }
}
