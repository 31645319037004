import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';

declare var $: any;

@Component({
  selector: 'app-studentGroups',
  templateUrl: './studentGroups.component.html',
  styleUrls: ['./studentGroups.component.scss']
})
export class StudentGroupsComponent implements AfterViewInit, OnInit {
  students = [{
    id: 'test1',
    name: 'ahmed',
    age: 16,
    record_id: 18
  },
    {
      id: 'test2',
      name: 'zaid',
      age: 16,
      record_id: 15
    },
    {
      id: 'test3',
      name: 'majid',
      age: 14,
      record_id: 17
    },
    {
      id: 'test4',
      name: 'ali',
      age: 15,
      record_id: 19
    }];

  constructor(public responsive: ResponsiveService) {
  }
  ngAfterViewInit() {
    const table = $('#datatable').DataTable({
      info: false,
      filter: true,
      bLengthChange: false,
      pageLength: 10,
      scrollY:        '70vh',
      scrollCollapse: true,
      columnDefs: [
        {orderable: true, targets: [2]}
      ],
      language: {
        search: 'بحث :',
        lengthMenu: 'عرض _MENU_ طالب في الصفحه',
        paginate: {
          first:      'الاولى',
          last:       'الاخيرة',
          next:       'التالي',
          previous:   'السابق'
        },
        info: 'عرض _END_ من اصل _TOTAL_ مجموع الطلاب',
        zeroRecords: 'لا يوجد يوجد تطابق مع البحث',
        emptyTable: 'لا يوجد طلاب لعرضهم'
      }
    });
    $('#datatable tbody').on('click', '.trash', function() {
      table
        .row($(this).closest('tr'))
        .remove()
        .draw();
    });
  }

  ngOnInit(): void {
    this.responsive.check();
  }

}
