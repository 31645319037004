import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { APIConfig } from 'src/app/config/API';
import { Student } from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ImageBox } from '../../services/image-box.service';
import { ResponsiveService } from '../../services/responsive.service';
import { WebsocketService } from '../../services/websocket.service';
import { Installment } from './../../interfaces/API';
declare var $: any;

@Component({
  selector: 'app-installment',
  templateUrl: './installment-bus.component.html',
  styleUrls: ['./installment-bus.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('1s ease-in', style({opacity: '1'})),
      ]),
    ]),
  ],
})
export class InstallmentBusComponent implements OnInit, AfterViewInit {
  @BlockUI('main') blockUI: NgBlockUI;
  @BlockUI('chat') blockUIChat: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  page = 1;
  paid = 0;
  isEditing = false;
  installment = new FormControl('', [Validators.required]);
  students: Student[] = [];
  selectedStudentIndex = null;
  selectedStudent: Student;
  installments: Installment[] = [];
  mainForm: FormGroup = this.fb.group({
    amount: [0, [Validators.required, Validators.min(0)]],
    no: ['', [Validators.required]],
    selected_date: ['', []],
    comment: ['', []],
  });
  insallment: Installment;
  query = '';
  type: 'all' | 'seen' | 'not_seen' = 'all';
  dtOptions: DataTables.Settings = {};
  mode: 'adding' | 'editing' = 'adding';
  constructor(
    public responsive: ResponsiveService,
    public API: APIService,
    private websocket: WebsocketService,
    private router: ActivatedRoute,
    private fb: FormBuilder,
    public imageBox: ImageBox,
    public http: HttpClient,
    private datePipe: DatePipe,
  ) {
  }
  editInstallment() {
    this.installment.patchValue(this.selectedStudent.installment);
    this.isEditing = true;
  }
  saveInstallment(save: boolean = true) {
    this.isEditing = false;
    if (save) {
      this.selectedStudent.installment = this.installment.value;
      this.blockUIChat.start();
      this.API.updateStudent({
        installment: this.installment.value,
      }, this.selectedStudent._id).then(() => {}).finally(() => this.blockUIChat.stop());
    }
  }
  selectStudent(student: Student) {
    this.selectedStudent = student;
    setTimeout(() => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        if (this.installments.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    });
  }
  fetchStudents(reload: boolean = false) {
    this.blockUI.start();
    if (reload) {
      this.page = 1;
      this.students = [];
    } else {
      this.page++;
    }
    this.API.fetchInstallmentBusStudents(this.page, this.query).then((students: Student[]) => {
      this.students.push(...students);
      this.blockUI.stop();
    });
  }

  ngOnInit() {
    this.responsive.check();
    this.dtOptions = {
      info: false,
      search: true,
      lengthMenu: [10, 25, 50],
      lengthChange: true,
      pageLength: 10,
      dom: 'Rfrtlip',
      scrollY: '70vh',
      scrollCollapse: true,
      columnDefs: [
        { orderable: true, targets: [2] }
      ],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        if (!this.selectedStudent) {
          return;
        }
        this.blockUI.stop();
        this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: Installment[];
            recordsTotal: number;
            recordsFiltered: number;
            paid: number;
          }>(
            APIConfig.uri + 'installment-bus/datatable?' + (this.selectStudent ? '&student=' + this.selectedStudent._id : ''),
            { ...dataTablesParameters, filter: this.type }, { headers: this.API.AuthHeaders }
          ).subscribe(resp => {
            this.blockUI.stop();
            this.installments = resp.data;
            this.paid = resp.paid;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      language: {
        search: 'بحث :',
        lengthMenu: 'عرض _MENU_ حقل في الصفحه',
        paginate: {
          first: 'الاولى',
          last: 'الاخيرة',
          next: 'التالي',
          previous: 'السابق'
        },
        info: 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable: 'لا يوجد معلومات لعرضها'
      }
    };
  }

  ngAfterViewInit(): void {
    this.fetchStudents(true);
  }
  showModal(item: Installment = null) {
    this.mainForm.reset();
    if (item) {
      this.mode = 'editing';
      this.mainForm.patchValue(item);
      this.mainForm.patchValue({
        selected_date: item.selected_date.split('T')[0]
      });
      this.insallment = item;
    } else {
      this.mode = 'adding';
    }
    $('#modal-animate').modal();
  }
  submit(print: boolean = false) {
    this.blockUIChat.start('جاري الحفظ');
    if (this.mode === 'adding') {
      this.API.createInstallmetBus({
        ...this.mainForm.value,
        student: this.selectedStudent._id,
      }).then(student => {
        this.blockUIChat.stop();
        if (student) {
          this.selectStudent(this.selectedStudent);
          if (print) {
            this.print();
          }
          setTimeout(() => {
            this.mainForm.reset();
            $('#modal-animate').modal('hide');
          }, 1000);
        }
      }).catch((error) => {
        this.blockUIChat.stop();
      });
    } else if (this.mode === 'editing') {
      this.API.updateInstallmetBus(this.mainForm.value, this.insallment._id).then(student => {
        this.blockUIChat.stop();
        if (student) {
          this.selectStudent(this.selectedStudent);
          if (print) {
            this.print();
          }
          setTimeout(() => {
            this.mainForm.reset();
            $('#modal-animate').modal('hide');
          }, 1000);
        }
      }).catch((error) => {
        this.blockUIChat.stop();
      });
    }
  }
  delete(id: string) {
    if (!confirm('هل انت متاكد من عملية الحذف ؟')) {
      return;
    }
    this.blockUIChat.start('جاري الحفظ');
    this.API.deleteInstallmetBus(id).then(student => {
      this.blockUIChat.stop();
      if (student) {
        this.selectStudent(this.selectedStudent);
        setTimeout(() => {
          this.mainForm.reset();
          $('#modal-animate').modal('hide');
        }, 1000);
      }
    }).catch((error) => {
      this.blockUIChat.stop();
    });
  }
  print() {
    const win = window.open('', 'Print', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + screen.width + ',height=' + screen.height + ',top=0,left=0');
    const date = this.datePipe.transform(new Date(), 'yyyy/MM/dd', '+0300');
    win.document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    win.document.body.innerHTML = `<html dir="rtl" lang="ar">
<style>
    body {
        width:210mm;
        margin-left: auto;
        margin-right: auto;
    }
@page {
    size: 7in 9.25in;
    margin: 27mm 16mm 27mm 16mm;
}
h1,h2,h3,h4,h5,h6 {
  font-weight: normal;
  margin: 10px;
}
.bold {
  font-weight: bold;
}
div {
  float: right;
}
table{
    margin: 0 auto;
    border-collapse:collapse;
    text-align:center;
    border:1px solid #000;
    font-size:12px;
    }</style>
  <section>
  <div style="width: 70%; float: right">
    <h1 class="bold" style="font-size: 20pt">${this.API.school.name}</h1>
    <h2>مؤسسة تعليمية</h2>
    <h3>هاتف : ${this.API.school.phone1}</h3>
    <h3>هاتف : ${this.API.school.phone2}</h3>
  </div>
  <div style="width: 25%; float: right; text-align:left">
    <h1 style="font-size: 26pt; margin-bottom: 35px">سند قبض</h1>
    <span>INVOICE # ${this.mainForm.value.no}</span>
    <br/>
    <span>DATE: ${date}</span>
  </div>
  </section>
  <section>
    <div style="width: 100%">
      <h2 class="bold">الى :</h2>
      <h3>ولي امر الطالب : ${this.selectedStudent.name}</h3>
      <h3>الصف : ${this.selectedStudent.class.name}</h3>
      <h3>الشعبة : ${this.selectedStudent.branch.name}</h3>
      <h3>اسم المستخدم : ${this.selectedStudent.parentUsername}</h3>
      <h3 class="bold">الملاحظات او التعليقات الخاصة او التعليمات العامة</h3>
      <div style="height: 100px!important;">${this.mainForm.value.comment || ''}</div>
    </div>
  </section>
  <section>
    <table style="width: 693px; height: 192px;" border="1">
      <tbody>
        <tr style="height: 25px;">
          <td class="bold" style="width: 137.125px; height: 25px;">المبلغ المدفوع</td>
          <td class="bold" style="width: 137.125px; height: 25px;">الوصف</td>
          <td class="bold" style="width: 137.125px; height: 25px;">التاريخ</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 137.125px; height: 25px;">${this.mainForm.value.amount}</td>
          <td style="width: 401.422px; height: 25px;">قسط الباص</td>
          <td style="width: 153.453px; height: 25px;">${date}</td>
        </tr>
        <tr style="height: 25.875px;">
          <td style="width: 137.125px; height: 25.875px;">&nbsp;</td>
          <td style="width: 401.422px; height: 25.875px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25.875px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 137.125px; height: 25px;">&nbsp;</td>
          <td style="width: 401.422px; height: 25px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 137.125px; height: 25px;">&nbsp;</td>
          <td style="width: 401.422px; height: 25px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25px;">&nbsp;</td>
        </tr>
          <tr style="height: 25px;">
          <td style="width: 137.125px; height: 25px;">&nbsp;</td>
          <td style="width: 401.422px; height: 25px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 137.125px; height: 25px;">&nbsp;</td>
          <td style="width: 401.422px; height: 25px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 137.125px; height: 25px;">&nbsp;</td>
          <td style="width: 401.422px; height: 25px;">&nbsp;</td>
          <td style="width: 153.453px; height: 25px;">&nbsp;</td>
        </tr>
        <tr style="height: 25px;">
          <td style="width: 137.125px; height: 25px;">${this.mainForm.value.amount}</td>
          <td colspan="2">المجموع الفرعي</td>
        </tr>
      </tbody>
    </table>
  </section>
  <section>
    <div dir="ltr" style="width: 100%; margin-top: 20px; text-align: left">
      © Al Sadeem Systems
    </div>
  </section>
</html>`;
    win.print();
    win.onafterprint = () => {
      win.close();
    };
  }
}
