import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {APIService} from '../../services/API.service';
import {APIConversation} from '../../interfaces/API';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ResponsiveService} from '../../services/responsive.service';
import {TranslateService} from '@ngx-translate/core';
declare var $: any;

@Component({
  selector   : 'app-header',
  templateUrl: './header.component.html',
  styleUrls  : ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title = '';
  lang = 'ar';
  languages = {
    ar: {
      name: 'العربية',
      dir: 'rtl'
    },
    en: {
      name: 'English',
      dir: 'ltr'
    },
    fr: {
      name: 'Français',
      dir: 'ltr'
    },
  };
  Keys = Object.keys;
  dark: boolean = localStorage.getItem('mode') === 'dark';
  constructor(public auth: AuthService, public API: APIService, public router: Router,
              public responsive: ResponsiveService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService,
              private titleService: Title) {
    this.lang = localStorage.getItem('lang') || 'ar';
    this.changeLang(this.lang);
    responsive.check();
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
      } else if (val instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root);
        this.title = title[this.getTitle(router.routerState, router.routerState.root).length - 1];
      }
    });
    this.translate.onLangChange.subscribe(() => {
      const title = this.getTitle(router.routerState, router.routerState.root);
      this.title = title[this.getTitle(router.routerState, router.routerState.root).length - 1];
      this.lang = this.translate.currentLang;
    });
  }
  changeLang(lang: string) {
    const dir = this.languages[lang].dir;
    this.lang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    localStorage.setItem('direction', dir);
    document.getElementsByTagName('html')[0].setAttribute('dir', dir);
  }
  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(this.translate.instant(parent.snapshot.data.title));
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  toggleDark() {
    this.dark = !this.dark;
    if (this.dark) {
      $('html').addClass('bg-dark');
    } else {
      $('html').removeClass('bg-dark');
    }
    localStorage.setItem('mode', this.dark ? 'dark' : 'light');
  }

  ngOnInit() {
    if (this.dark) {
      $('html').addClass('bg-dark');
    } else {
      $('html').removeClass('bg-dark');
    }
  }

  seen(notificationIndex: number) {
    if (!this.API.notifications.data[notificationIndex].seen) {
      this.API.notifications.data[notificationIndex].seen = true;
      this.API.notifications.unseen--;
      this.API.seenNotification(this.API.notifications.data[notificationIndex]._id).then(() => {
      });
    }
    // if (this.API.notifications.data[notificationIndex].page) {
    //   this.router.navigate(['/', ...this.API.notifications.data[notificationIndex].page.split('/')]);
    // }
  }

  seenConversation(conversation: APIConversation) {
    this.API.seenConversation(conversation._id).then((res) => this.router.navigate(['/', 'chat', conversation.student._id]));
  }
}
