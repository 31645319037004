import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { APIComment, Post } from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ImageBox } from '../../services/image-box.service';
import { ResponsiveService } from '../../services/responsive.service';

declare var $: any;

interface Form {
  mode: 'adding' | 'deleting' | 'editing';
  form: FormControl;
  modePost: 'adding' | 'deleting' | 'editing';
  post: FormControl;
}

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class StatsComponent implements AfterViewInit, OnInit {
  @BlockUI('table') blockUI: NgBlockUI;
  @ViewChild('videoUpload', {static: true})videoUpload: ElementRef;
  @ViewChild('imageUpload', {static: true})imageUpload: ElementRef;
  postBody = new FormControl('', [Validators.required]);
  filtering = 'homework';
  statistics = {
    homework: 0,
    homework_done: 0,
    report: 0,
    report_seen: 0,
    post: 0,
    post_seen: 0,
    lesson: 0,
    lesson_seen: 0,
  };
  posts: Post[] = [];
  postsData: Post[] = [];
  more = true;
  loadingMore = true;
  commentForm: Form[] = [];
  selectedPostIndex = -1;
  selectedCommentIndex = -1;
  student: string;
  items: FormArray;
  media: {
    image: string,
    file: File | string,
    type: 'video' | 'image' | 'youtube' | 'loading'
  }[] = [];
  postId = null;
  page = 0;
  constructor(public responsive: ResponsiveService,
              public API: APIService,
              private formBuilder: FormBuilder,
              public domSanitizationService: DomSanitizer,
              public imageBox: ImageBox,
              private router: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document) {
                this.student = this.router.snapshot.params.student;
  }

  ngAfterViewInit() {
    this.fetch();
    // if (this.postId) {
    //   this.API.fetchPostById().then(post => this.posts.unshift(post));
    // }
  }
  initFormControl(index: number): FormControl {
    this.router.params.subscribe(({postId}) => {
      this.postId = postId ? postId : null;
    });
    const form = new FormControl('', [Validators.required]);
    const formPost = new FormControl('', [Validators.required]);
    this.commentForm.push({
      mode: 'adding',
      modePost: 'adding',
      form,
      post: formPost
    });
    return form;
  }

  getFormControl(index: number): Form {
    return this.commentForm[index];
  }

  ngOnInit(): void {
    this.responsive.check();
  }


  filterEmployees(type: string) {
    // this.selected.splice(0, this.selected.length);
    this.filtering = type;
    // this.employees = this.employeesData.filter((employee: Employee) => type === 'all' || employee.type === type);
    this.fetch();
  }
  dropDown(event: MouseEvent, postId: number): void {
    const dropdownMenu = $('#post-dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.selectedPostIndex = postId;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click(e => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  dropDownComment(event: MouseEvent, postId: number, commentId: number): void {
    const dropdownMenu = $('#comment-dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    // console.log(tempX);
    this.selectedPostIndex = postId;
    this.selectedCommentIndex = commentId;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click(e => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetchPage() {
    this.page++;
    this.loadingMore = true;
    this.API.fetchStats(this.page, this.student, this.filtering).then((res: {posts: Post[], statistics: any}) => {
      const posts = res.posts;
      this.statistics = res.statistics;
      this.loadingMore = false;
      this.more = posts.length > 0;
      this.postsData = this.posts;
      this.posts = [];
      this.posts = posts;
      this.commentForm = [];
      this.posts = this.posts.map((post: Post, index: number) => {
        post.attachments = post.attachments.map(attachment => attachment.type !== 'video' ? ({...attachment, url: this.domSanitizationService.bypassSecurityTrustResourceUrl(attachment.url as string)}) : attachment);
        return post;
      });
      this.postsData.push(...this.posts);
      this.posts = [];
      this.posts.push(...this.postsData);
      this.commentForm = [];
      this.posts.forEach((post: Post, index: number) => {
        this.initFormControl(index);
      });
      this.blockUI.stop();
    });
  }
  fetch() {
    this.page = 0;
    this.loadingMore = true;
    this.API.fetchStats(this.page, this.student, this.filtering).then((res: {posts: Post[], statistics: any}) => {
      const posts = res.posts;
      console.log(posts);
      this.statistics = res.statistics;
      this.loadingMore = false;
      this.more = posts.length > 0;
      this.posts = [];
      this.commentForm = [];
      this.posts = posts;
      this.posts = this.posts.map((post: Post, index: number) => {
        this.initFormControl(index);
        post.attachments = post.attachments.map(attachment => attachment.type !== 'video' ? ({...attachment, url: this.domSanitizationService.bypassSecurityTrustResourceUrl(attachment.url as string)}) : attachment);
        return post;
      });
      this.blockUI.stop();
      this.postsData = this.posts;
    });
  }

  submitComment(formControl: Form, postIndex: number, commentIndex: number = -1) {
    this.blockUI.start('جاري التحميل...');
    const modal = $('#modal-alert');
    switch (formControl.mode) {
      case 'adding':
        this.API.createComment(formControl.form, this.posts[postIndex]._id).then((comment: APIComment) => {
          this.posts[postIndex].comments.push(comment);
          formControl.form.reset();
          formControl.mode = 'adding';
          this.blockUI.stop();
        }).catch(error => modal.modal());
        break;
      case 'editing':
        this.API.updateComment(formControl.form, this.posts[this.selectedPostIndex].comments[this.selectedCommentIndex]._id).then((comment: any) => {
          this.posts[this.selectedPostIndex].comments[this.selectedCommentIndex].comment = formControl.form.value;
          formControl.form.reset();
          formControl.mode = 'adding';
          this.blockUI.stop();
        }).catch(error => modal.modal());
        break;
      case 'deleting':
        if (!confirm('هل انت متأكد من حذف التعليق ؟')) {
          this.blockUI.stop();
          return;
        }
        this.API.deleteComment(this.posts[this.selectedPostIndex].comments[this.selectedCommentIndex]._id).then((post: boolean) => {
          this.posts[this.selectedPostIndex].comments.splice(this.selectedCommentIndex, 1);
          formControl.form.reset();
          formControl.mode = 'adding';
          this.blockUI.stop();
        }).catch(error => modal.modal());
        break;
    }
  }
}
