import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { APIConfig } from '../config/API';
import {
  Account, APIComment,
  APINotification,
  APISubject,
  Attendance,

  AttendanceResponse,
  AttendanceStatisticsResponse,
  Branch,
  Bus,
  BusResponse,
  Class,
  ClassResponse,
  Conversation,
  ConversationHeader,
  ConversationResponse,
  DailyMarks,
  DailyRates,
  DailyReports,
  Employee, EmployeeAttendance,


  EmployeeAttendanceResponse, EmployeeResponse,
  ExamSchedule, FullReport,
  Group, GroupConversation,
  GroupHistory,
  HomeWork,
  HomeWorkResponse, IGroupMessage,
  IMessage,
  Library,
  MonthlyMarks,
  Notifications,
  Outgoing,
  Post,
  PostResponse,
  Report,
  School,
  Self,
  Statistics,
  Student,
  StudentRates,
  StudentResponse,
  WeeklyHomeWork,
  WeeklySchedule
} from '../interfaces/API';
import { ErrorMsg } from '../interfaces/error-msg';
import { AuthService } from './auth.service';
import { ResponsiveService } from './responsive.service';
import { WebsocketService } from './websocket.service';


declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class APIService {
  @BlockUI('main') blockUI: NgBlockUI;
  @BlockUI('table') blockUI2: NgBlockUI;
  school: School;
  employee: Employee                     = JSON.parse(localStorage.getItem('user'));
  notifications: Notifications           = {
    data  : [],
    unseen: 0,
  };
  conversations: ConversationHeader      = {
    data  : [],
    unseen: 0,
  };
  classes: Class[]                       = [];
  employees: Employee[]                  = [];
  permissions: string[]                  = JSON.parse(localStorage.getItem('permissions') || '[]');
  buses: Bus[]                           = [];
  students: Student[]                    = [];
  groups: Group[]                        = [];
  accounts: Account[] = [];
  statistics: Statistics                 = {
    students: {
      total: 0,
      moved: 0,
      left: 0,
      deleted: 0,
      online: 0
    },
    employees:
      {
        total: 0,
        teachers: 0,
        managers: 0,
        drivers: 0,
        online: 0,
      },
    attendance:
      {
        attended: 0,
        absent: 0,
        leave: 0,
        not: 0
      },
    homeWorks:
      {
        total: 0,
        subjects: 0
      },
    success:
      {
        success: 0,
        failed: 0
      },
    post: 0,
    lesson: 0,
    report: 0,
    homeWork: 0,
    subjects: 0,
    totals:
      {
        post: 0,
        lesson: 0,
        library: 0,
        comment: 0,
        message: 0,
        report: 0
      },
    message: 0,
    messageNotSeen: 0,
  }
  ;
  headers: HttpHeaders;
  alertMsg: ErrorMsg                     = null;
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient, public Auth: AuthService, private websocket: WebsocketService, private responsive: ResponsiveService, private router: Router) {
    if (this.Auth.authenticated) {
      this.headers = this.Auth.getHeaders();
      this.websocket.notificationEmitter
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((notification: APINotification) => {
          this.notifications.data.unshift(notification);
          this.notifications.unseen++;
          this.responsive.playNotify('notify', false);
        });
      this.websocket.messageEmitter
        .subscribe((notification: APINotification) => {
          this.fetchSelf().then((self) => this.conversations = self.conversations);
        });
    }
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.blockUI.stop();
        this.blockUI2.stop();
      }
    });
  }

  get AuthHeaders(): HttpHeaders {
    return this.Auth.getHeaders();
  }

  can(permissions: string | string[], operator: 'and' | 'or' = 'and'): boolean {
    if (!permissions) {
      return true;
    }
    if (typeof permissions === 'string') {
      permissions = [permissions];
    }
    let ability = true;
    permissions.forEach(permission => {
      if (this.permissions.indexOf(permission) === -1 && operator === 'and') {
        ability = false;
      }
      if (this.permissions.indexOf(permission) !== -1 && operator === 'or') {
        ability = true;
        return true;
      }
    });
    return ability;
  }

  showAlert(message, type, fields: Array<{title: string; value: string}> = []) {
    this.blockUI.stop();
    this.alertMsg = {
      message,
      type,
      fields,
    };
    $('#alert').modal('show');
    // setTimeout(() => (this.alertMsg = null), type === 'danger' ? 5000 : 1000);
  }

  fetch(classFetch = true) {
    if (classFetch) {
      this.fetchClasses().then(classes => this.classes = classes);
    }
    this.fetchGroups().then(groups => this.groups = groups);
    this.fetchBuses().then(buses => this.buses = buses);
    this.fetchAccounts().then(accounts => this.accounts = accounts);
    this.fetchSelf().then(self => {
      localStorage.setItem('user', JSON.stringify(self.employee));
      localStorage.setItem('permissions', JSON.stringify(this.permissions));
    });
    this.fetchEmployees().then((employees: Employee[]) => {
      this.employees = employees;

    });
    // setTimeout(() => this.fetch(false), 60000);
  }

  fetchSelf(): Promise<Self> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'employee/self', {headers: this.AuthHeaders})
        .subscribe((response: Self) => {
            this.school        = response.employee.school;
            this.employee      = response.employee;
            if (response.employee.role) {
              this.permissions   = response.employee.role.permissions.map(permission => permission.slug);
            }
            this.notifications = response.notifications;
            this.conversations = response.conversations;
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchSchool');
            this.Auth.doLogout();
            reject(error);
            console.log(error);
          });
    });
  }

  fetchStatistics(): Promise<Statistics> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'statistics', {headers: this.AuthHeaders})
        .subscribe((response: Statistics) => {
            this.statistics = response;
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchSchool');
            reject(error);
            console.log(error);
          });
    });
  }
  fetchLeaderBoard(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'employee/leaderboard', {headers: this.AuthHeaders})
        .subscribe((response: any[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchSchool');
            reject(error);
            console.log(error);
          });
    });
  }
  fetchAccountingStatistics(): Promise<{
    salary: number;
    OUT: number;
    installment: number;
    installment_bus: number;
    IN: number;
  }> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'school/accountingstats', {headers: this.AuthHeaders})
        .subscribe((response: {
          salary: number;
          OUT: number;
          installment: number;
          installment_bus: number;
          IN: number;
        }) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchSchool');
            reject(error);
            console.log(error);
          });
    });
  }

  updateSelfEmployee(employee: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'employee/self/update', employee, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            this.fetchSelf().then((data) => {
            });
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateSelfPassword(employee: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'employee/self/password', employee, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            this.fetchSelf().then((data) => {
            });
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  seenNotification(notificationId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'notification/seen/' + notificationId, {}, {headers: this.AuthHeaders})
        .subscribe((response: any) => {
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }

  fetchBuses(): Promise<Bus[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'bus/', {headers: this.AuthHeaders})
        .subscribe((response: BusResponse) => {
            resolve(response.buses);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchBuses');
            reject(error);
            console.log(error);
          });
    });
  }
  fetchAccounts(): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'account/', {headers: this.AuthHeaders})
        .subscribe((response: Account[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchBuses');
            reject(error);
            console.log(error);
          });
    });
  }

  createBus(bus: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'bus/', bus, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  qrAttendance(qr: string): Promise<Student> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'attendance/qr', {qr}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            // this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  qrAttendanceLeave(qr: string): Promise<Student> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'attendance/qrLeave', {qr}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            // this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateBus(busId: string, bus: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'bus/' + busId, bus, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteBus(busId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'bus/' + busId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchReports(classId: string = null, branchId: string = null): Promise<Report[]> {
    let params = '?';
    if (classId) {
      params += '&classId=' + classId;
    }
    if (branchId) {
      params += '&branchId=' + branchId;
    }
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'report/' + params, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Report[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchGroups');
            reject(error);
            console.log(error);
          });
    });
  }

  createReport(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'report/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateReport(reportId: string, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'report/' + reportId, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteReport(reportId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'report/' + reportId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchGroups(): Promise<Group[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'group/', {headers: this.AuthHeaders})
        .subscribe((response: Group[]) => {
            resolve(response);
            this.groups = response;
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchGroups');
            reject(error);
            console.log(error);
          });
    });
  }

  fetchGroupHistory(groupId: string): Promise<GroupHistory[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'group/history/' + groupId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: GroupHistory[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchGroups');
            reject(error);
            console.log(error);
          });
    });
  }

  sendGroupMessage(groupId: string, message: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'group/sendMessage', {
        groupId,
        message,
        date: new Date().toISOString().substring(0, 10)
      }, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الارسال بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  sendGroupAttachment(groupId: string, file: File): Promise<boolean> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('groupId', groupId);
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'group/sendAttachment', formData, {headers: this.Auth.getHeaders(true)})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الارسال بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  uploadSchoolImage(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append('file', file);
      this.http.post(APIConfig.uri + 'school/image/', formData, {headers: this.Auth.getHeaders(true)})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم التغيير بنجاح', 'success');
            resolve(response.path);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  uploadFileConversation(conversationId: string, file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append('file', file);
      this.http.post(APIConfig.uri + 'conversation/' + conversationId + '/message/attachment/', formData, {headers: this.Auth.getHeaders(true)})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(response.path);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  uploadFileConversationGroup(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append('file', file);
      this.http.post(APIConfig.uri + 'groupChat/message/attachment', formData, {headers: this.Auth.getHeaders(true)})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(response.path);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  uploadFile(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const formData: FormData = new FormData();
      formData.append('file', file);
      this.http.post(APIConfig.uri + 'upload', formData, {headers: this.Auth.getHeaders(true)})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(response.path);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchGroup(groupId: string): Promise<Group> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'group/' + groupId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Group) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchGroup');
            reject(error);
            console.log(error);
          });
    });
  }

  createGroup(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'group/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateGroup(groupId: string, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'group/' + groupId, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteGroup(groupId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'group/' + groupId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  addGroupStudents(groupId: string, studentIds: string[], notify: boolean = true): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'group/addStudents', {
        groupId,
        studentIds,
        notify
      }, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            // this.showAlert('تم الحذف بنجاح', 'success');
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteGroupStudent(groupId: string, studentId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'group/deleteStudent', {
        groupId,
        studentId
      }, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            // this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchOutgoings(): Promise<Outgoing[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'outgoing/', {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Outgoing[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchOutgoings');
            reject(error);
            console.log(error);
          });
    });
  }

  createOutgoing(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'outgoing/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateOutgoing(bookId: string, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'outgoing/' + bookId, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  checkOutgoing(bookNo: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'outgoing/check?book_no=' + bookNo, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Outgoing) => {
            this.showAlert(`الكتاب مصدر من ${response.school.name} ويحمل الرقم ${response.book_no}`, 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert('لم يتم العثور على الكتاب', 'danger');
            reject(error);
          });
    });
  }

  deleteOutgoing(bookId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'outgoing/' + bookId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchStudents(branchId: string = null, classId: string = null): Promise<Student[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'student/?' + (branchId && branchId.length === 24 ? '&branchId=' + branchId : '') + (classId ? '&classId=' + classId : ''), {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StudentResponse) => {
            this.students = response.students;
            resolve(response.students);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchStudents');
            reject(error);
          });
    });
  }
  fetchInstallmentStudents(page: number, search: string = null, type: string = null): Promise<Student[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'installment/students?' + (search ? '&search=' + search : '') + (type ? '&type=' + type : '') + (page ? '&page=' + page : ''), {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchStudents');
            reject(error);
          });
    });
  }
  fetchInstallmentBusStudents(page: number, search: string = null): Promise<Student[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'installment-bus/students?' + (search ? '&search=' + search : '') + (page ? '&page=' + page : ''), {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchStudents');
            reject(error);
          });
    });
  }

  createStudent(student: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/', student, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم اضافة الطالب بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  createInstallmet(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'installment/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  updateInstallmet(payload: any, id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'installment/' + id, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  deleteInstallmet(installmetId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'installment/' + installmetId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  createInstallmetBus(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'installment-bus/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  updateInstallmetBus(payload: any, id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'installment-bus/' + id, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  deleteInstallmetBus(installmetId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'installment-bus/' + installmetId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  createAccount(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'account/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الاضافة بنجاح', 'success');
            this.fetchAccounts().then(accounts => this.accounts = accounts);
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateAccount(accountId: string, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'account/' + accountId, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            this.fetchAccounts().then(accounts => this.accounts = accounts);
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  deleteAccount(accountId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'account/' + accountId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            this.fetchAccounts().then(accounts => this.accounts = accounts);
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  createTransaction(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'transaction/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الاضافة بنجاح', 'success');
            this.fetchAccounts().then(accounts => this.accounts = accounts);
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateTransaction(transactionId: string, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'transaction/' + transactionId, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            this.fetchAccounts().then(accounts => this.accounts = accounts);
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  deleteTransaction(transactionId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'transaction/' + transactionId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            this.fetchAccounts().then(accounts => this.accounts = accounts);
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  createSalary(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'salary/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  fetchSalaryEmployees(page: number, search: string = null): Promise<Employee[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'salary/employees?' + (search ? '&search=' + search : '') + (page ? '&page=' + page : ''), {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchSalaryEmployees');
            reject(error);
          });
    });
  }
  createStudents(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/bulk', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم اضافة الطلبة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateStudent(student: any, studentId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'student/' + studentId, student, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم تعديل الطالب بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteStudents(studentIds: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/delete', {studentIds}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم تجميد الطالب بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  notifyStudents(studentIds: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/study', {studentIds}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم ارسال التاكيد على الدراسة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  undoDeleteStudents(studentIds: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/delete/undo', {studentIds}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم استعادة الطالب بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  undoExpiredStudents(studentIds: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/expired', {studentIds}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم تفعيل الطالب بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  updateInstallmentGroup(studentIds: string[], installment: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/installment', {studentIds, installment}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Student) => {
            this.showAlert('تم تعديل القسط بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchEmployees(type: string = null, deleted: boolean = false): Promise<Employee[]> {
    return new Promise((resolve, reject) => {
      let query = '?';
      if (type) {
        query += `&type=${type}`;
      }
      if (deleted) {
        query += `&deleted=true`;
      }
      this.http.get(APIConfig.uri + 'employee' + query, {headers: this.AuthHeaders})
        .subscribe((response: EmployeeResponse) => {
            this.employees = response.employees.map((emplyee: any) => {
              emplyee.subjects = emplyee.subjects.map(subject => subject._id);
              return emplyee;
            });
            resolve(response.employees);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchEmployees');
            reject(error);
          });
    });
  }

  createEmployee(employee: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'employee/', employee, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateEmployee(employee: any, employeeId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'employee/' + employeeId, employee, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  updateEmployeeLissons(employee: any, employeeId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'employee/' + employeeId + '/lessons', employee, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteEmployee(employeeId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'employee/' + employeeId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  openEmployeeConversation(employeeId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'employee/allowChat/' + employeeId, {}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchClasses(): Promise<Class[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'class/', {headers: this.AuthHeaders})
        .subscribe((response: ClassResponse) => {
            this.classes = response.classes;
            resolve(response.classes);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchClasses');
            console.log(error);
            reject(error);
          });
    });
  }

  createClass(myClass: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'class/', myClass, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Class) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateClass(myClass: any, classId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'class/' + classId, myClass, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Class) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteClass(classId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'class/' + classId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  createBranch(branch: any, classId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'branch/' + classId, branch, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Branch) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateBranch(branch: any, branchId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'branch/' + branchId, branch, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Branch) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteBranch(branchId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'branch/' + branchId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  createSubject(subject: any, subjectId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'subject/' + subjectId, subject, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: APISubject) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateSubject(subject: any, subjectId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'subject/' + subjectId, subject, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: APISubject) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteSubject(subjectId: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + `subject/${subjectId}/delete`, {password}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchPosts(type: string = 'post', page: number = 0, classId = 'all', branchId = 'all', subjectId = 'all', date = ''): Promise<Post[]> {
    let URI = APIConfig.uri + 'post/?';
    URI += '&type=' + type;
    URI += '&page=' + page;
    URI += (classId !== 'all' ? '&classId=' + classId : '');
    URI += (branchId !== 'all' ? '&branchId=' + branchId : '');
    URI += (subjectId !== 'all' ? '&subjectId=' + subjectId : '');
    URI += (date !== '' ? '&date=' + date : '');
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: PostResponse) => {
            resolve(response.posts);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchPosts');
            console.log(error);
            reject(error);
          });
    });
  }

  fetchStats(page: number = 0, student: string, filter: string = 'homework'): Promise<{posts: Post[], statistics: any}> {
    let URI = APIConfig.uri + 'post/filter?';
    // URI += '&type=' + type;
    URI += '&page=' + page;
    URI += '&student=' + student;
    URI += '&filter=' + filter;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: {posts: Post[], statistics: any}) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchPosts');
            console.log(error);
            reject(error);
          });
    });
  }

  createPost(body: string, attachments: any[], type: string = 'post', classId: string = null, branchId: string[] = null, subjectId: string = null, extraData: any = {}): Promise<boolean> {
    const data = {body, attachments, type, classId, branchId, subjectId, ...extraData};
    if (!classId) {
      delete data.classId;
    }
    if (!branchId) {
      delete data.branchId;
    }
    if (!subjectId) {
      delete data.subjectId;
    }
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'post/', data, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Post) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updatePost(myPost: any, postId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'post/' + postId, {body: myPost.value}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Post) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  updatePost2(myPost: any, postId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'post/' + postId, myPost, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Post) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deletePost(postId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'post/' + postId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  createComment(myComment: FormControl, postId: string): Promise<APIComment> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'comment/' + postId, {comment: myComment.value}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: APIComment) => {
            // this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateComment(myComment: any, commentId: string): Promise<APIComment> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'comment/' + commentId, {comment: myComment.value}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: APIComment) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteComment(commentId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'comment/' + commentId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchConversations(page: number, type = 'all'): Promise<Conversation[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'conversation/?page=' + page + '&type=' + type, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ConversationResponse) => {
            resolve(response.conversations);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchConversations');
            console.log(error);
            reject(error);
          });
    });
  }

  fetchGroupConversations(page: number, type = 'all'): Promise<GroupConversation[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'groupChat/?page=' + page + '&type=' + type, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: GroupConversation[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error groupChat');
            console.log(error);
            reject(error);
          });
    });
  }

  fetchConversation(type: 'id' | 'student', id: string): Promise<Conversation> {
    let URI;
    if (type === 'id') {
      URI = APIConfig.uri + 'conversation/' + id;
    } else {
      URI = APIConfig.uri + 'conversation/student/' + id;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Conversation) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchConversation');
            console.log(error);
            reject(error);
          });
    });
  }

  seenConversation(conversationId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'conversation/seen/' + conversationId, {}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
            this.fetchSelf().then();
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }
  seenGroupConversation(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'groupChat/seen', {}, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
            this.fetchSelf().then();
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }

  deleteConversation(conversationId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'conversation/' + conversationId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchMessages(conversationId: string, lastId: string = null): Promise<IMessage[]> {
    let URI = APIConfig.uri + 'conversation/' + conversationId + '/messages/';
    if (lastId) {
      URI += '?lastId=' + lastId;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: IMessage[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }
  fetchGroupMessages(conversationId: string, lastId: string = null): Promise<IGroupMessage[]> {
    let URI = APIConfig.uri + 'groupChat/messages/';
    if (lastId) {
      URI += '?lastId=' + lastId;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: IGroupMessage[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }

  sendMessage(conversationId: string, message: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'conversation/' + conversationId + '/message', {
        conversationId,
        message
      }, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }
  sendGroupCMessage(message: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'groupChat/message', {
        message
      }, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            reject(error);
          });
    });
  }

  fetchAttendances(date: string = null, studentId: string = null, branchId: string = null): Promise<AttendanceResponse> {
    let URI = APIConfig.uri + 'attendance/?';
    if (date) {
      URI += '&date=' + date;
    }
    if (studentId) {
      URI += '&studentId=' + studentId;
    }
    if (branchId) {
      URI += '&branchId=' + branchId;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AttendanceResponse) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchConversations');
            console.log(error);
            reject(error);
          });
    });
  }

  fetchEmployeeAttendances(date: string = null, employeeId: string = null): Promise<EmployeeAttendanceResponse> {
    let URI = APIConfig.uri + 'employee/attendance/?';
    if (date) {
      URI += '&date=' + date;
    }
    if (employeeId) {
      URI += '&employeeId=' + employeeId;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: EmployeeAttendanceResponse) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchConversations');
            console.log(error);
            reject(error);
          });
    });
  }

  createEmployeeAttendances(payload: any): Promise<EmployeeAttendance[]> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'employee/attendance/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: EmployeeAttendance[]) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchAttendancesStatistics(branchId: string): Promise<AttendanceStatisticsResponse> {
    const URI = APIConfig.uri + 'attendance/branch/' + branchId;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AttendanceStatisticsResponse) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            console.log('error fetchAttendancesStatistics');
            console.log(error);
            reject(error);
          });
    });
  }

  createAttendances(payload: any): Promise<Attendance[]> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'attendance/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Attendance[]) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  createArchive(payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'student/archive', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تمت الارشفة بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchDailyReports(date: string = null): Promise<DailyReports[]> {
    let URI = APIConfig.uri + 'dailyReport/?';
    if (date) {
      URI += '&date=' + date;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyReports[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.showAlert(error.error.message, 'danger');
            // console.log('error fetchDailyMarks');
            console.log(error);
            reject(null);
          });
    });
  }

  createDailyReports(payload: any): Promise<DailyReports> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'dailyReport/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyReports) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteDailyReports(reportId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'dailyReport/' + reportId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: boolean) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchLibrary(subjectId: string): Promise<Library[]> {
    let URI = APIConfig.uri + 'library/?';
    URI += '&subjectId=' + subjectId;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Library[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.showAlert(error.error.message, 'danger');
            // console.log('error fetchDailyMarks');
            console.log(error);
            reject(null);
          });
    });
  }
  public uploadLibraryFile(payload: any): any {
    return this.http.post<any>(APIConfig.uri + 'library/', payload, {
      headers: this.Auth.getHeaders(true),
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', data: progress };
          case HttpEventType.Response:
            return {status: 'done', data: event.body};
          default:
            return {status: event.type};
        }
      })
    );
  }

  updateLibraryFile(fileId: string, payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'library/' + fileId, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.showAlert(error.error.message, 'danger');
            console.log(error);
            reject(null);
          });
    });
  }

  deleteLibraryFile(fileId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'library/' + fileId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: boolean) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchExamSchedule(branchId: string): Promise<ExamSchedule[]> {
    let URI = APIConfig.uri + 'examSchedule/?';
    if (branchId) {
      URI += '&branchId=' + branchId;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ExamSchedule[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.showAlert(error.error.message, 'danger');
            // console.log('error fetchDailyMarks');
            console.log(error);
            reject(null);
          });
    });
  }

  createExamSchedule(payload: any): Promise<boolean> {
    const URI = APIConfig.uri + 'examSchedule/?';
    return new Promise((resolve, reject) => {
      this.http.post(URI, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.showAlert(error.error.message, 'danger');
            // console.log('error fetchDailyMarks');
            console.log(error);
            reject(null);
          });
    });
  }

  updateExamSchedule(scheduleId: string, payload: any): Promise<boolean> {
    let URI = APIConfig.uri + 'examSchedule/?';
    URI += '&scheduleId=' + scheduleId;
    return new Promise((resolve, reject) => {
      this.http.put(URI, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.showAlert(error.error.message, 'danger');
            console.log(error);
            reject(null);
          });
    });
  }

  deleteExamSchedule(scheduleId: string): Promise<boolean> {
    let URI = APIConfig.uri + 'examSchedule/';
    URI += scheduleId;
    return new Promise((resolve, reject) => {
      this.http.delete(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            this.showAlert(error.error.message, 'danger');
            console.log(error);
            reject(error);
          });
    });
  }

  fetchDailyRates(subjectId: string, date: string = new Date().toISOString().substring(0, 10)): Promise<DailyRates> {
    let URI = APIConfig.uri + 'dailyRate/?';
    URI += '&date=' + date;
    URI += '&subjectId=' + subjectId;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyRates) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  fetchAllDailyRates(): Promise<DailyRates[]> {
    const URI = APIConfig.uri + 'dailyRate/all';
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyRates[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  createDailyRate(payload: any): Promise<DailyRates> {
    const URI = APIConfig.uri + 'dailyRate/';
    return new Promise((resolve, reject) => {
      this.http.post(URI, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyRates) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchStudentRates(branchId: string): Promise<StudentRates[]> {
    const URI = APIConfig.uri + 'studentRates/all?branchId=' + branchId;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StudentRates[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  fetchStudentRate(branchId: string, date: string = new Date().toISOString().substring(0, 10)): Promise<StudentRates> {
    let URI = APIConfig.uri + 'studentRates/?';
    URI += '&date=' + date;
    URI += '&branchId=' + branchId;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StudentRates) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  createStudentRates(payload: any): Promise<DailyRates> {
    const URI = APIConfig.uri + 'studentRates/';
    return new Promise((resolve, reject) => {
      this.http.post(URI, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyRates) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateStudentRateOptions(payload: any): Promise<DailyRates> {
    const URI = APIConfig.uri + 'studentRates/options';
    return new Promise((resolve, reject) => {
      this.http.post(URI, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyRates) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }


  fetchDailyMarks(subjectId: string, date: string = new Date().toISOString().substring(0, 10)): Promise<DailyMarks> {
    let URI = APIConfig.uri + 'dailyMark/?';
    if (date) {
      URI += '&date=' + date;
    }
    if (subjectId) {
      URI += '&subjectId=' + subjectId;
    }
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyMarks) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  fetchAllDailyMarks(): Promise<DailyMarks[]> {
    const URI = APIConfig.uri + 'dailyMark/all';
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyMarks[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  fetchStudentDailyMarks(studentId: string): Promise<DailyMarks[]> {
    const URI = APIConfig.uri + 'dailyMark/' + studentId;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyMarks[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }
  fetchStudentReport(studentId: string): Promise<FullReport> {
    const URI = APIConfig.uri + 'student/' + studentId + '/history';
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: FullReport) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  createDailyMark(payload: any): Promise<DailyMarks> {
    const URI = APIConfig.uri + 'dailyMark/';
    return new Promise((resolve, reject) => {
      this.http.post(URI, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: DailyMarks) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchMonthlyMarks(subjectId: string, month: string = 'month_1', branch: string): Promise<MonthlyMarks> {
    let URI = APIConfig.uri + 'monthlyMark/?';
    URI += '&month=' + month;
    URI += '&branch=' + branch;
    URI += '&subjectId=' + subjectId;
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: MonthlyMarks) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  fetchAllMonthlyMarks(): Promise<MonthlyMarks[]> {
    const URI = APIConfig.uri + 'monthlyMark/all';
    return new Promise((resolve, reject) => {
      this.http.get(URI, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: MonthlyMarks[]) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.blockUI.stop();
            // console.log('error fetchDailyMarks');
            console.log(error);
            resolve(null);
          });
    });
  }

  createMonthlyMark(payload: any): Promise<MonthlyMarks> {
    const URI = APIConfig.uri + 'monthlyMark/';
    return new Promise((resolve, reject) => {
      this.http.post(URI, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: MonthlyMarks) => {
            this.showAlert('تم الحفظ بنجاح', 'success');
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchHomeWorks(date: string = new Date().toISOString().substring(0, 10)): Promise<HomeWork[]> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'homeWork/' + (date !== null ? '?date=' + date : ''), {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: HomeWorkResponse) => {
            resolve(response.homeWorks);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchHomeWorks');
            reject(error);
          });
    });
  }

  createHomeWork(homework: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'homeWork/', homework, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: HomeWork) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchWeeklyHomeWorks(branchId: string, date: string = new Date().toISOString().substring(0, 10)): Promise<WeeklyHomeWork> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'weeklyHomeWork/?branchId=' + branchId + '&date=' + date, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: WeeklyHomeWork) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchWeeklyHomeWorks');
            reject(error);
          });
    });
  }

  createWeeklyHomeWorks(payload: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'weeklyHomeWork/', payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: any) => {
            this.showAlert('تمت الاضافة بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  updateHomeWork(homework: any, homeworkId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.put(APIConfig.uri + 'homeWork/' + homeworkId, homework, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: HomeWork) => {
            this.showAlert('تم التعديل بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  deleteHomeWork(homeworkId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.delete(APIConfig.uri + 'homeWork/' + homeworkId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: boolean) => {
            this.showAlert('تم الحذف بنجاح', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }

  fetchWeeklySchedule(branchId: string, classId: string): Promise<WeeklySchedule> {
    return new Promise((resolve, reject) => {
      this.http.get(APIConfig.uri + 'weeklySchedule/' + branchId + '?classId=' + classId, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: WeeklySchedule) => {
            resolve(response);
          },
          (error: HttpErrorResponse) => {
            console.log('error fetchWeeklySchedule');
            reject(error);
          });
    });
  }

  createWeeklySchedule(payload: any, branchId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'weeklySchedule/' + branchId, payload, {headers: this.AuthHeaders})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: Employee) => {
            this.showAlert('تم الحفظ', 'success');
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert(error.error.message, 'danger');
            reject(error);
          });
    });
  }
  createZoom(code: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.post(APIConfig.uri + 'zoom/token', {code}, {headers: this.AuthHeaders})
        .subscribe((response: Employee) => {
            this.showAlert('تم الربط بنجاح', 'success');
            this.fetchSelf();
            resolve(true);
          },
          (error: HttpErrorResponse) => {
            this.showAlert('خطأ في الربط', 'danger');
            reject(error);
          });
    });
  }
  fetchsolutions(homeWorkId: string): Observable<any> {
    return this.http.get(APIConfig.uri + `homeWork/${homeWorkId}/solutions`, {headers: this.AuthHeaders});
  }
  downloadSolutions(homeWorkId: string): Observable<any> {
    return this.http.get(APIConfig.uri + `homeWork/${homeWorkId}/solutions/download`, {headers: this.AuthHeaders});
  }
  correctSolution(solutionId: string, payload: any): Observable<any> {
    return this.http.post(APIConfig.uri + `homeWork/${solutionId}/correct`, payload, {headers: this.AuthHeaders});
  }
  createStorage(payload: any): Observable<any> {
    return this.http.post(APIConfig.uri + `storage`, payload, {headers: this.AuthHeaders});
  }
  updateStorage(storageId: string, payload: any): Observable<any> {
    return this.http.put(APIConfig.uri + `storage/${storageId}`, payload, {headers: this.AuthHeaders});
  }
  deleteStorage(storageId: string): Observable<any> {
    return this.http.delete(APIConfig.uri + `storage/${storageId}`, {headers: this.AuthHeaders});
  }
  logStorage(storageId: string, payload: any): Observable<any> {
    return this.http.post(APIConfig.uri + `storage/${storageId}/insert`, payload, {headers: this.AuthHeaders});
  }
  fetchLink(): Observable<any> {
    return this.http.get(APIConfig.uri + `registration/`, {headers: this.AuthHeaders});
  }
  resetLink(): Observable<any> {
    return this.http.post(APIConfig.uri + `registration/reset`, {}, {headers: this.AuthHeaders});
  }
  updateLink(payload: any): Observable<any> {
    return this.http.put(APIConfig.uri + `registration/`, payload, {headers: this.AuthHeaders});
  }
  updateRegistrationStatus(id: string, payload: any): Observable<any> {
    return this.http.put(APIConfig.uri + `registration/${id}`, payload, {headers: this.AuthHeaders});
  }
  fetchCurrencies(): Observable<any> {
    return this.http.get(APIConfig.uri + `Currency/`, {headers: this.AuthHeaders});
  }
  changeCurrencies(currencyId: string): Observable<any> {
    return this.http.post(APIConfig.uri + `Currency/`, {currencyId}, {headers: this.AuthHeaders});
  }
  fetchPermissions(length: number = 15, skip: number = 0): Observable<any> {
    let query = '?';
    if (length) {
      query += `&length=${length}`;
    }
    if (skip) {
      query += `&skip=${skip}`;
    }
    return this.http.get(APIConfig.uri + `role/permissions${query}`, {headers: this.AuthHeaders});
  }
  fetchRoles(): Observable<any> {
    return this.http.get(APIConfig.uri + `role/roles`, {headers: this.AuthHeaders});
  }
  createRole(payload: any): Observable<any> {
    return this.http.post(APIConfig.uri + `role/`, payload, {headers: this.AuthHeaders});
  }
  updateRole(id: string, payload: any): Observable<any> {
    return this.http.put(APIConfig.uri + `role/${id}`, payload, {headers: this.AuthHeaders});
  }
  deleteRole(id: string): Observable<any> {
    return this.http.delete(APIConfig.uri + `role/${id}`, {headers: this.AuthHeaders});
  }
  schoolArchive(payload: any): Observable<any> {
    return this.http.post(APIConfig.uri + `school/archive`, payload, {headers: this.AuthHeaders});
  }
  joinZoom(meetingNumber, password, role): Observable<any> {
    return this.http.post(APIConfig.uri + 'zoom', {meetingNumber, password, role,}, {headers: this.AuthHeaders});
  }
}
