import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { APIService } from './../../services/API.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  stats: {
    salary: number;
    OUT: number;
    installment: number;
    installment_bus: number;
    IN: number;
  } = {
    salary: 0,
    OUT:  0,
    installment: 0,
    installment_bus: 0,
    IN: 0,
  };
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = ['مجموع الواردات', 'مجموع الصادرات'];
  public pieChartData: number[] = [(this.stats.installment + this.stats.IN), (this.stats.salary + this.stats.OUT)];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  // public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(0,255,0,0.3)', 'rgba(255,0,0,0.3)'],
    },
  ];

  constructor(
    private API: APIService
  ) { }

  ngOnInit() {
    this.API.fetchAccountingStatistics().then(stats => {
      this.stats = stats;
      this.pieChartData = [(this.stats.installment + this.stats.IN), (this.stats.salary + this.stats.OUT)];
      // this.changeLegendPosition();
    });
  }
  changeLegendPosition(): void {
    this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
  }

}
