import { Component, OnInit } from '@angular/core';
import {APIService} from '../../services/API.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {

  constructor(public API: APIService) { }

  ngOnInit() {
    // alert(1);
  }

}
