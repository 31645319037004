import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './pages/account/account.component';
import { AttendanceEmployeeComponent } from './pages/attendance-employee/attendance-employee.component';
import { ChatComponent } from './pages/chat/chat.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EmployeeLessonsComponent } from './pages/employeeLessons/employeeLessons.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { InstallmentComponent } from './pages/installment/installment.component';
import { LibraryComponent } from './pages/library/library.component';
import { LoginComponent } from './pages/login/login.component';
import { NewsComponent } from './pages/news/news.component';
import { News2Component } from './pages/news2/news2.component';
import { News3Component } from './pages/news3/news3.component';
import { NewsEmployeeComponent } from './pages/newsEmployee/newsEmployee.component';
import { QRComponent } from './pages/qr/qr.component';
import { QRLeaveComponent } from './pages/qrLeave/qr.component';
import { SalaryComponent } from './pages/salary/salary.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SoonComponent } from './pages/soon/soon.component';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { StatsComponent } from './pages/stats/news.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { TransactionOutComponent } from './pages/transactionOUT/transaction.component';
import { PrintComponent } from './print/print/print.component';
import { TestComponent } from './print/template/test/test.component';
import {NotificationComponent} from './pages/notification/notification.component';
import {InstallmentHistoryComponent} from './pages/installment-history/installment-history.component';
import {InstallmentClassComponent} from './pages/installment-class/installment-class.component';
import {ChatGroupComponent} from './pages/chatGroup/chat-group.component';
import {PollComponent} from './pages/poll/poll.component';
import {AttendPostComponent} from './pages/attend-post/attend-post.component';
import {StorageComponent} from './pages/storage/storage.component';
import {StorageLogComponent} from './pages/storage-log/storage-log.component';
import {InstallmentBusComponent} from './pages/installment-bus/installment-bus.component';
import {RoleComponent} from './pages/role/role.component';
import {PermissionV2Guard} from './permission-v2.guard';
import {StudentFullReportComponent} from './pages/student-full-report/student-full-report.component';
const routes: Routes = [
      {
        path: 'home',
        component: DashboardComponent,
        data: {title: 'الرئيسية', animation: 'isLeft'}
      },
      {
        path: 'employee/attendance',
        canActivate: [PermissionV2Guard],
        component: AttendanceEmployeeComponent,
        data: {title: 'غيابات الموظفين', animation: 'isLeft'}
      },
      {
        path: 'employees',
        canActivate: [PermissionV2Guard],
        component: EmployeesComponent,
        data: {permission: 'employee-view', title: 'ادارة الموظفين', animation: 'isLeft'}
      },
      {
        path: 'employeeLessons',
        canActivate: [PermissionV2Guard],
        component: EmployeeLessonsComponent,
        data: {permission: 'employee-view', title: 'توزيع الحصص', animation: 'isLeft'}
      },
      {
        path: 'employeeLessons/:employeeId',
        canActivate: [PermissionV2Guard],
        component: EmployeeLessonsComponent,
        data: {permission: 'employee-view', title: 'توزيع الحصص', animation: 'isLeft'}
      },
      {
        path: 'roles',
        canActivate: [PermissionV2Guard],
        component: RoleComponent,
        data: {
          permission: 'role-management',
          title: 'ادارة الادوار',
          animation: 'isLeft'
        }
      },
      {
        path: 'news',
        canActivate: [PermissionV2Guard],
        component: NewsComponent,
        data: {
          permission: 'post-news',
          title: 'الاخبار', animation: 'isLeft'}
      },
      {
        path: 'polls',
        canActivate: [PermissionV2Guard],
        component: PollComponent,
        data: {
          permission: 'post-poll',
          title: 'الاستطلاعات', animation: 'isLeft'}
      },
      {
        path: 'attend',
        canActivate: [PermissionV2Guard],
        component: AttendPostComponent,
        data: {
          permission: 'post-attendance',
          title: 'تسجيل الحضور', animation: 'isLeft'}
      },
      {
        path: 'newsTeacher',
        canActivate: [PermissionV2Guard],
        component: NewsEmployeeComponent,
        data: {
          permission: 'post-teacher',
          title: 'تبليغات المعلمين', animation: 'isLeft'}
      },
      {
        path: 'news/:postId',
        canActivate: [PermissionV2Guard],
        component: NewsComponent,
        data: {
          permission: 'post-news',
          title: 'الاخبار', animation: 'isLeft'}
      },
      {
        path: 'news2',
        canActivate: [PermissionV2Guard],
        component: News2Component
      },
      {
        path: 'stats/:student',
        canActivate: [PermissionV2Guard],
        component: StatsComponent,
        data: {
          permission: 'student-view',
          title: 'سجل الطالب', animation: 'isLeft'}
      },
      {
        path: 'fullReport',
        canActivate: [PermissionV2Guard],
        component: StudentFullReportComponent,
        data: {
          permission: 'student-view',
          title: 'تقارير الطلبة', animation: 'isLeft'}
      },
      {
        path: 'fullReport/:studentId',
        canActivate: [PermissionV2Guard],
        component: StudentFullReportComponent,
        data: {
          permission: 'student-view',
          title: 'تقارير الطلبة', animation: 'isLeft'}
      },
      {
        path: 'installment',
        canActivate: [PermissionV2Guard],
        component: InstallmentComponent,
        data: {
          permission: 'finance-installment',
          title: 'اقساط الطلبة', animation: 'isLeft'}
      },
      {
        path: 'installment-bus',
        canActivate: [PermissionV2Guard],
        component: InstallmentBusComponent,
        data: {
          permission: 'finance-installment',
          title: 'اقساط باص الطلبة', animation: 'isLeft'}
      },
      {
        path: 'installment-group',
        canActivate: [PermissionV2Guard],
        component: InstallmentClassComponent,
        data: {
          permission: 'finance-installment',
          title: 'سجل اقساط الطلبة', animation: 'isLeft'}
      },
      {
        path: 'installment-history',
        canActivate: [PermissionV2Guard],
        component: InstallmentHistoryComponent,
        data: {
          permission: 'finance-installment',
          title: 'سجل اقساط الطلبة', animation: 'isLeft'}
      },
      {
        path: 'account',
        canActivate: [PermissionV2Guard],
        component: AccountComponent,
        data: {title: 'اعدادات الحسابات', animation: 'isLeft'}
      },
      {
        path: 'transactionIN',
        canActivate: [PermissionV2Guard],
        component: TransactionComponent,
        data: {
          permission: 'finance-income',
          title: 'الواردات', animation: 'isLeft'}
      },
      {
        path: 'transactionOUT',
        canActivate: [PermissionV2Guard],
        component: TransactionOutComponent,
        data: {
          permission: 'finance-outcome',
          title: 'المصروفات', animation: 'isLeft'}
      },
      {
        path: 'salary',
        canActivate: [PermissionV2Guard],
        component: SalaryComponent,
        data: {
          permission: 'finance-salary',
          title: 'رواتب الموظفين', animation: 'isLeft'}
      },
      {
        path: 'statistics',
        canActivate: [PermissionV2Guard],
        component: StatisticsComponent,
        data: {
          permission: 'finance-statistics',
          title: 'احصائيات الحسابات', animation: 'isLeft'}
      },
      {
        path: 'notification',
        canActivate: [PermissionV2Guard],
        component: NotificationComponent,
        data: {title: 'الاشعارات', animation: 'isLeft'}
      },
      {
        path: 'news3',
        canActivate: [PermissionV2Guard],
        component: News3Component
      },
      {
        path: 'school',
        loadChildren: './school.module#SchoolModule',
        data: {title: 'المؤسسة', animation: 'isLeft'}
      },
      {
        path: 'qr',
        canActivate: [PermissionV2Guard],
        component: QRComponent,
        data: {
          permission: 'attendance-management',
          title: 'تسجل حضور الطلاب QR', animation: 'isLeft'}
      },
      {
        path: 'qrLeave',
        canActivate: [PermissionV2Guard],
        component: QRLeaveComponent,
        data: {
          permission: 'attendance-management',
          title: 'تسجل خروج الطلاب QR', animation: 'isLeft'}
      },
      { path: 'print',
        canActivate: [PermissionV2Guard],
        component: PrintComponent,
        children: [
          { path: 'student/:type', component: TestComponent }
        ]
      },
      { path: 'students',
        loadChildren: './student.module#StudentModule',
        data: {title: 'ادارة الطلبة', animation: 'isLeft'}
      },
      {
        path: 'chat',
        canActivate: [PermissionV2Guard],
        component: ChatComponent,
        data: {
          permission: 'conversation-view',
          title: 'الرسائل', animation: 'isLeft'}
      },
      {
        path: 'chat-group',
        canActivate: [PermissionV2Guard],
        component: ChatGroupComponent,
        data: {
          permission: 'conversation-view',
          title: 'مجموعات المحادثات', animation: 'isLeft'}
      },
      {
        path: 'chat/:studentId',
        canActivate: [PermissionV2Guard],
        component: ChatComponent,
        data: {
          permission: 'conversation-view',
          title: 'الرسائل', animation: 'isLeft'}
      },
      {
        path: 'login',
        component: LoginComponent,
        data: {title: 'تسجيل الدخول'}
      },
      {
        path: 'library',
        canActivate: [PermissionV2Guard],
        component: LibraryComponent,
        data: {
          permission: 'library-management',
          title: 'المكتبة الالكترونية', animation: 'isRight'}
      },
      {
        path: 'settings',
        canActivate: [PermissionV2Guard],
        component: SettingsComponent,
        data: {title: 'الاعدادات', animation: 'isRight'}
      },
      {
        path: 'storage',
        canActivate: [PermissionV2Guard],
        component: StorageComponent,
        data: {
          permission: 'storage-management',
          title: 'ادارة المخزن', animation: 'isLeft'}
      },
      {
        path: 'storage-log',
        canActivate: [PermissionV2Guard],
        component: StorageLogComponent,
        data: {
          permission: 'storage-management',
          title: 'تقارير المخزن', animation: 'isLeft'}
      },
      {
        path: 'soon',
        canActivate: [PermissionV2Guard],
        component: SoonComponent,
        data: {title: 'قريباً', animation: 'isLeft'}
      },
      {
        path: '**',
        redirectTo: 'home'
      },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
