import {EventEmitter, Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {APINotification, IMessage} from '../interfaces/API';
import {APIConfig} from '../config/API';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  messageEmitter = new EventEmitter<{ conversationId: string, message: IMessage }>();
  groupMessageEmitter = new EventEmitter<{ message: IMessage }>();
  notificationEmitter = new EventEmitter<APINotification>();
  studentOnlineStatus: { [key: string]: 'online' | Date } = {};
  private socket$: SocketIOClient.Socket;

  constructor() {
  }

  disconnect() {
    this.socket$.close();
    this.socket$ = null;
  }

  connect(token: string = null, url: string = APIConfig.socket) {
    if (this.socket$) {
      return this.socket$;
    }
    this.socket$ = io(url, {
      reconnection: true,
      transports: ['websocket'],
      path: APIConfig.socketPath,
      query: {
        authorization: token
      }
    });
    this.socket$
      .on('message', (data: any) => {
        const {conversationId, message} = data;
        this.messageEmitter.emit({conversationId, message});
      })
      .on('GroupMessage', (data: any) => {
        const {conversationId, message} = data;
        this.groupMessageEmitter.emit({message});
      })
      .on('notification', (data: APINotification) => {
        this.notificationEmitter.emit(data);
      })
      .on('updateStatus', (data: { clientId, type }) => {
        this.studentOnlineStatus[data.clientId] = data.type;
      });
  }
}
