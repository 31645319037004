import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Subject } from 'rxjs';
import { APIConfig } from '../../config/API';
import { Account, Notification } from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ResponsiveService } from '../../services/responsive.service';

declare var $: any;

@Component({
  selector   : 'app-transportation',
  templateUrl: './notification.component.html',
  styleUrls  : ['./notification.component.scss']
})
export class NotificationComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  formMode: 'adding' | 'editing' | 'deleting' | 'details' | 'filter' = 'adding';
  notificationsData: Notification[]                                   = [];
  notifications: Notification[]                                       = [];
  dropdown                                                = false;
  dropdownData: Notification                                    = null;
  mainForm: FormGroup;
  filterForm: FormGroup;
  dtTrigger: Subject<any>                                 = new Subject();
  selected: string[]             = [];
  total = 0;
  types                                                  = {
    IQD  : {
      text : 'دينار عراقي',
      class: 'success'
    },
    USD: {
      text : 'دولار امريكي',
      class: 'warning'
    },
  };
  methods                                                 = {
    cash    : {
      text : 'كاش',
      class: 'success'
    },
    cheque    : {
      text : 'شيك',
      class: 'primary'
    },
  };
  dataTablesParameters: any;
  dtOptions: DataTables.Settings                          = {};
  filtered = false;
  totalAmount = 0;
  adding = false;
  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, private formBuilder: FormBuilder, public API: APIService,
              private http: HttpClient,
              private datePipe: DatePipe) {
  }

  ngAfterViewInit() {
    const self = this;
    const selectDepartment = $('#account-filter');
    selectDepartment.select2();
    selectDepartment.on('change', function() {
      self.filterForm.patchValue({
        account: $(this).val(),
      });
    });
    const selectCusomter2 = $('#account-filter2');
    selectCusomter2.select2({ width: '100%' });
    selectCusomter2.on('change', function() {
      self.mainForm.patchValue({
        account: $(this).val(),
      });
    });
  }

  ngOnInit(): void {
    this.responsive.check();
    this.dtOptions = {
      info        : false,
      search      : false,
      lengthMenu  : [10, 25, 50, 100, 250, 500],
      lengthChange: false,
      pageLength  : 20,
      dom         : 'Rrtlip',
      autoWidth   : false,
      columns     : [
        {width: '60%', name: 'message', orderable: true},
        {width: '30%', name: 'date', orderable: true},
        {width: '10%', name: 'date', orderable: true},
      ],
      columnDefs  : [
        {orderable: true, targets: [2]}
      ],
      order       : [1, 'desc'],
      serverSide  : true,
      processing  : true,
      ajax        : (dataTablesParameters: any, callback) => {
        this.dataTablesParameters = dataTablesParameters;
        this.blockUI.stop();
        // this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: Notification[];
            recordsTotal: number;
            recordsFiltered: number;
            total: number;
          }>(
            APIConfig.uri + `notification/datatable`, dataTablesParameters, {headers: this.API.AuthHeaders}
          ).subscribe(resp => {
          this.blockUI.stop();
          this.notifications     = resp.data;
          this.notificationsData = resp.data;
          this.totalAmount = resp.total;
          callback({
            recordsTotal   : resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data           : []
          });
        });
      },
      language    : {
        search     : 'بحث :',
        lengthMenu : 'عرض _MENU_ حقل في الصفحه',
        paginate   : {
          first   : 'الاولى',
          last    : 'الاخيرة',
          next    : 'التالي',
          previous: 'السابق'
        },
        info       : 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable : 'لا يوجد معلومات لعرضها'
      }
    };
    setTimeout(() => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        if (this.notifications.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    });
  }

  dropDown(event: MouseEvent, language: Notification): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets  = document.body.getBoundingClientRect();
    const tempX        = !this.responsive.isMobile ? bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width() : event.pageX + dropdownMenu.width();
    const tempY        = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData  = language;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetch() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
  seen(notification: Notification) {
    this.API.seenNotification(notification._id).then(() => {
      notification.seen = true;
    });
  }
}
