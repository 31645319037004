import {AfterViewInit, Component, OnInit } from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';
@Component({
  selector: 'app-news3',
  templateUrl: './news3.component.html',
  styleUrls: ['./news3.component.scss']
})
export class News3Component implements AfterViewInit, OnInit {



  constructor(public responsive: ResponsiveService) {
  }
  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.responsive.check();
  }

}
