import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {APIConfig} from '../config/API';
import {EmployeeLogin} from '../interfaces/API';

import {Router} from '@angular/router';
import {WebsocketService} from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = false;
  token = null;

  constructor(private http: HttpClient, private router: Router, private ws: WebsocketService) {
    this.isAuthenticated();
  }

  checkToken() {
    this.token = localStorage.getItem('token');
    console.log(this.token);
    if (this.token !== null) {
      this.authenticated = true;
      this.router.navigate(['/', 'home']);
    }
  }

  isAuthenticated(): boolean {
    this.token = localStorage.getItem('token');
    this.authenticated = this.token !== null;
    if (this.authenticated) {
      this.ws.connect(this.token);
    }
    return this.token !== null;
  }

  getHeaders(onlyAuth: boolean = false): HttpHeaders {
    const headers = {
      Authorization: this.token
    };
    if (!onlyAuth) {
      headers['Content-Type'] = 'application/json';
    }
    return new HttpHeaders(headers);
  }

  doLogin({username, password}: { [key: string]: string }) {
    return new Promise((resolve, reject) => {
      const body = {username, password};
      this.http.post(APIConfig.uri + 'employee/login', body).subscribe((response: EmployeeLogin) => {
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response));
          localStorage.setItem('notifications_desktop', JSON.stringify(false));
          localStorage.setItem('notifications_news', JSON.stringify(false));
          localStorage.setItem('notifications_sounds', JSON.stringify(true));
          localStorage.setItem('route_change_effect', JSON.stringify(false));
          this.authenticated = true;
          this.token = response.token;
          this.ws.connect(this.token);
          resolve(true);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        });
    });
  }

  doLogout() {
    this.ws.disconnect();
    this.token = null;
    this.authenticated = false;
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.router.navigate(['/', 'login']);
    localStorage.clear();
    window.location.reload();
  }
}
