import {AfterViewInit, Component} from '@angular/core';
import {Router} from '@angular/router';
import {APIService} from '../../services/API.service';

declare let $: any;

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss']
})
export class SideComponent implements AfterViewInit {
  constructor(private router: Router, public API: APIService) {
    router.events.subscribe((event: any) => {
      $('#aside').modal('hide');
      const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    });
  }

  ngAfterViewInit() {
    $('body').on('click', e =>
      e.target === $('.modal-backdrop')[0] ? $('#aside').modal('hide') : ''
    );
  }
}
