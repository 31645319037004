import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';
import {ScriptLoaderService} from '../../../script-loader.service';
import {DataTableDirective} from 'angular-datatables';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Employee, ExamSchedule, IPermission, IRole, IStorage, Library, Student} from '../../interfaces/API';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {APIService} from '../../services/API.service';

// @ts-ignore
import * as findIndex from 'lodash/findIndex';
import {APIConfig} from '../../config/API';
import {HttpClient} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

interface Form {
  subject: FormControl;
  date: FormControl;
  details: FormControl;
  comment: FormControl;
}

@Component({
  selector: 'app-exam-schedule',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  @BlockUI('modal') modalBlockUi: NgBlockUI;
  uploadProgress = 0;
  filter = {
    fromDate: new FormControl('', []),
    toDate: new FormControl('', []),
  };
  STATUSES = {
    new: {
      text: 'طلب جديد',
      color: 'warning'
    },
    reviewed: {
      text: 'تمت المراجعة',
      color: 'primary'
    },
    accepted: {
      text: 'تمت الموافقة',
      color: 'success'
    },
    refused: {
      text: 'تمت الموافقة',
      color: 'danger'
    },
  };
  statistics = {
    all: 0,
    new: 0,
    reviewed: 0,
    accepted: 0,
    refused: 0
  };
  formMode: 'adding' | 'editing' | 'deleting' | 'details' | 'IN' | 'OUT' | 'RETURN' | 'LOST' = 'adding';
  alert = null;
  selectedFormClassIndex;
  items: IRole[] = [];
  itemsData: IRole[] = [];
  dropdown = false;
  dropdownData: IRole = null;
  dtOptions: DataTables.Settings;
  dtTrigger: Subject<any> = new Subject();
  filtering = '';
  myForm = this.fb.group({
    slug: ['', [Validators.required]],
    description: ['', []],
  });
  groups: Set<string> = new Set();
  permissionControls: {[key: string]: Array<{
      formControl: FormControl;
      slug: string;
      _id: string;
    }>} = {};
  constructor(
    public responsive: ResponsiveService,
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    public API: APIService,
    private scriptLoader: ScriptLoaderService,
    private fb: FormBuilder,
    private tr: TranslateService,
    private datePipe: DatePipe) {
    this.API.fetchPermissions(100).subscribe((permissions: IPermission[]) => {
      permissions.forEach((permission) => {
        this.groups.add(permission.group);
        if (!this.permissionControls[permission.group]) {
          this.permissionControls[permission.group] = [];
        }
        this.permissionControls[permission.group].push({
          formControl:  new FormControl(false, []),
          slug: permission.slug,
          _id: permission._id,
        });
      });
    });
  }

  filterChange(type: string) {
    this.filtering = type;
    this.fetch();
  }


  dateChanged({target}, formControl: FormControl) {
    const {value} = target;
    formControl.patchValue(value);
    this.fetch();
  }
  ngAfterViewInit() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.on('draw.dt', () => {
        if (this.itemsData.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    this.scriptLoader
      .loadScripts('body', [
        'assets/js/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
      ])
      .then(() => {
        $.fn.datepicker.dates.en = {
          ...$.fn.datepicker.dates.en,
          today: 'تاريخ اليوم',
          clear: 'مسح',
          format: 'yyyy-mm-dd'
        };
        $('[data-plugin="datepicker"]').datepicker({
          todayBtn: 'linked',
          today: 'اليوم',
          format: 'yyyy-mm-dd'
        }).on('changeDate', (event) => this.dateChanged(event, this.filter.fromDate));
        $('[data-plugin="datepicker2"]').datepicker({
          todayBtn: 'linked',
          today: 'اليوم',
          format: 'yyyy-mm-dd'
        }).on('changeDate', (event) => this.dateChanged(event, this.filter.toDate));
      });
  }

  showModal(mode: 'adding' | 'editing' | 'deleting' | 'details' | 'IN' | 'OUT' | 'RETURN' | 'LOST') {
    this.formMode = mode;
    this.myForm.reset();
    let permissions = [];
    if (mode === 'editing') {
      this.myForm.patchValue(this.dropdownData);
      permissions = this.dropdownData.permissions.map(permission => permission.slug);
    }
    this.groups.forEach(group => {
      this.permissionControls[group].forEach(permission => {
        permission.formControl.patchValue(permissions.indexOf(permission.slug) !== -1);
      });
    });
    $('#modal-animate').modal('show');
  }

  fetch() {
    $('#modal-animate').modal('hide');
    this.blockUI.stop();
    this.modalBlockUi.stop();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  ngOnInit(): void {
    this.responsive.check();
    this.dtOptions = {
      info: false,
      search: true,
      lengthMenu: [10, 25, 50],
      lengthChange: true,
      pageLength: 10,
      dom: 'Rfrtlip',
      scrollY: '70vh',
      scrollCollapse: true,
      columnDefs: [
        { orderable: true, targets: [2] }
      ],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.modalBlockUi.stop();
        this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: IRole[];
            recordsTotal: number;
            statistics: any;
            recordsFiltered: number;
          }>(
            APIConfig.uri + 'role/datatable',
            {
              ...dataTablesParameters
            },
            { headers: this.API.AuthHeaders }
          ).subscribe(resp => {
            this.statistics = resp.statistics;
            this.blockUI.stop();
            this.items = resp.data;
            this.itemsData = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
        });
      },
      language: {
        search: 'بحث :',
        lengthMenu: 'عرض _MENU_ حقل في الصفحه',
        paginate: {
          first: 'الاولى',
          last: 'الاخيرة',
          next: 'التالي',
          previous: 'السابق'
        },
        info: 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable: 'لا يوجد معلومات لعرضها'
      }
    };
  }

  dropDown(event: MouseEvent, items: IRole): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData = items;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }
  submit() {
    const permissions: string[] = [];
    this.groups.forEach(group => {
      this.permissionControls[group].forEach(permission => {
        if (permission.formControl.value) {
          permissions.push(permission._id);
        }
      });
    });
    const payload = {
      permissions,
      ...this.myForm.value,
    };
    if (this.formMode === 'adding') {
      this.API.createRole(payload).subscribe(() => {
          this.fetch();
          this.API.showAlert('تم اضافة الصلاحية بنجاح', 'success');
        },
        error => {
          console.log(error);
          this.API.showAlert(error.error.message, 'danger');
        });
    } else if (this.formMode === 'editing') {
      this.API.updateRole(this.dropdownData._id, payload).subscribe(() => {
          this.fetch();
          this.API.showAlert('تم تعديل الصلاحية بنجاح', 'success');
          this.API.fetch();
        },
        error => {
          console.log(error);
          this.API.showAlert(error.error.message, 'danger');
        });
    } else if (this.formMode === 'deleting') {
      this.API.deleteRole(this.dropdownData._id).subscribe(() => {
          this.fetch();
          this.API.showAlert('تم حذف الصلاحية بنجاح', 'success');
        },
        error => {
          console.log(error);
          this.API.showAlert(error.error.message, 'danger');
        });
    }
  }
}
