import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { APIConfig } from '../../config/API';
import { Account } from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ResponsiveService } from '../../services/responsive.service';
declare var $: any;

@Component({
  selector   : 'app-account',
  templateUrl: './account.component.html',
  styleUrls  : ['./account.component.scss']
})
export class AccountComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  formMode: 'adding' | 'editing' | 'deleting' | 'details' | 'filter' = 'adding';
  accountsData: Account[]                                   = [];
  accounts: Account[]                                       = [];
  dropdown                                                = false;
  dropdownData: Account                                    = null;
  mainForm: FormGroup;
  dtTrigger: Subject<any>                                 = new Subject();
  selectedData: {[key: string]: Account}             = {};
  selected: string[]             = [];
  dataTablesParameters: any;
  dtOptions: DataTables.Settings                          = {};
  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, private formBuilder: FormBuilder, public API: APIService,
              private http: HttpClient,
              private datePipe: DatePipe) {
    this.mainForm = this.formBuilder.group({
      title   : ['', [Validators.required]],
    });
  }
  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.responsive.check();
    this.dtOptions = {
      info        : false,
      search      : true,
      lengthMenu  : [10, 25, 50, 100, 250, 500],
      lengthChange: true,
      pageLength  : 10,
      dom         : 'Rfrtlip',
      autoWidth   : false,
      columns     : [
        {width: '10%', name: 'select', orderable: true},
        {width: '60%', name: 'title', orderable: true},
        {width: '30%', name: 'settings', orderable: false},
      ],
      columnDefs  : [
        {orderable: true, targets: [2]}
      ],
      order       : [1, 'desc'],
      serverSide  : true,
      processing  : true,
      ajax        : (dataTablesParameters: any, callback) => {
        this.dataTablesParameters = dataTablesParameters;
        this.blockUI.stop();
        // this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: Account[];
            recordsTotal: number;
            recordsFiltered: number;
          }>(
            APIConfig.uri + `account/datatable`, {
              ...dataTablesParameters,
            }, {headers: this.API.AuthHeaders}
          ).subscribe(resp => {
          this.blockUI.stop();
          this.accounts     = resp.data;
          this.accountsData = resp.data;
          callback({
            recordsTotal   : resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data           : []
          });
        });
      },
      language    : {
        search     : 'بحث :',
        lengthMenu : 'عرض _MENU_ حقل في الصفحه',
        paginate   : {
          first   : 'الاولى',
          last    : 'الاخيرة',
          next    : 'التالي',
          previous: 'السابق'
        },
        info       : 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable : 'لا يوجد معلومات لعرضها'
      }
    };
    setTimeout(() => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        if (this.accounts.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    });
  }

  dropDown(event: MouseEvent, language: Account): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets  = document.body.getBoundingClientRect();
    const tempX        = !this.responsive.isMobile ? bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width() : event.pageX + dropdownMenu.width();
    const tempY        = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData  = language;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetch() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
  showModal(mode: 'adding' | 'editing' | 'deleting' | 'details' | 'filter') {
    this.formMode = mode;
    switch (mode) {
      case 'adding':
        this.mainForm.reset();
        break;
      case 'editing':
        this.mainForm.reset();
        this.mainForm.patchValue(this.dropdownData);
        break;
      case 'details':
      case 'deleting':
    }
    $('#modal-animate').modal('show');
  }

  submitForm() {
    this.blockUI.start('جاري الحفظ ...');
    if (this.formMode === 'adding') {
      this.API.createAccount({
        ...this.mainForm.value,
        target: 'IN',
      }).then(result => {
          if (result) {
            this.fetch();
            setTimeout(() => {
              $('#modal-animate').modal('hide');
              this.mainForm.reset();
            }, 1000);
          }
        },
        (error) => {
          this.API.showAlert(error.message, 'danger');
          this.blockUI.stop();
        });
    } else if (this.formMode === 'editing') {
      this.API.updateAccount(this.dropdownData._id, this.mainForm.value).then(data => {
          if (data) {
            this.fetch();
            setTimeout(() => {
              $('#modal-animate').modal('hide');
              this.mainForm.reset();
            }, 1000);
          }
        },
        (error) => {
          this.blockUI.stop();
        });
    } else if (this.formMode === 'deleting') {
      this.API.deleteAccount(this.dropdownData._id).then(data => {
          if (data) {
            this.fetch();
            setTimeout(() => {
              $('#modal-animate').modal('hide');
              this.mainForm.reset();
            }, 1000);
          }
        },
        (error) => {
          this.blockUI.stop();
        });
    } else if (this.formMode === 'filter') {
      this.fetch();
      $('#modal-animate').modal('hide');
    }
  }
  toggleSelect(event, data: Account) {
    if (event.target.checked) {
      this.selected.push(event.target.value);
      this.selectedData[data._id] = data;
    } else {
      this.selected.splice(this.selected.indexOf(event.target.value), 1);
      delete this.selectedData[data._id];
    }
  }
  selectAll() {
    this.accounts.forEach(account => {
      if (this.selected.indexOf(account._id) === -1) {
        this.selected.push(account._id);
        this.selectedData[account._id] = account;
      }
    });
  }

  unselectAll() {
    this.selected.slice(0, this.selected.length);
    this.selectedData = {};
  }
}
