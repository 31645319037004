import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';
import {DataTableDirective} from 'angular-datatables';
import fileUpload from 'fuctbase64';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {APISubject, Branch, Class, Employee} from '../../interfaces/API';
import {Subject} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {APIService} from '../../services/API.service';
import {PrintService} from '../../services/print.service';
import {ActivatedRoute} from '@angular/router';

declare var $: any;

@Component({
  selector   : 'app-employees',
  templateUrl: './employeeLessons.component.html',
  styleUrls  : ['./employeeLessons.component.scss']
})
export class EmployeeLessonsComponent implements AfterViewInit, OnInit {
  @ViewChild('imageUpload', {static: true}) imageUpload: ElementRef;
  imageSrc                                                                 = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('modal') modalBlockUi: NgBlockUI;
  @BlockUI('table') blockUI: NgBlockUI;
  statistics                                                               = {
    all    : 0,
    teacher: 0,
    manager: 0,
    driver : 0,
  };
  filtering                                                                = 'all';
  formMode: 'adding' | 'editing' | 'editingClass' | 'deleting' | 'details' = 'adding';
  employeesData: Employee[]                                                = [];
  employees: Employee[]                                                    = [];
  allSelected                                                              = false;
  selected: number[]                                                       = [];
  dropdown                                                                 = false;
  dropdownData: number                                                     = null;
  employeeForm: FormGroup;
  classes: Class[]                                                         = [];
  branches: Branch[]                                                       = [];
  subjects: APISubject[]                                                   = [];
  classesDetails: string[]                                                 = [];
  subjectsDetails: string[]                                                = [];
  branchesDetails: string[]                                                = [];
  dtTrigger: Subject<any>                                                  = new Subject();
  selectedEmployeeIndex: number = null;
  dtOptions: DataTables.Settings                                           = {
    info          : false,
    search        : true,
    lengthChange  : false,
    pageLength    : 10,
    dom           : 'Rfrtlip',
    scrollY       : '70vh',
    scrollCollapse: true,
    columnDefs    : [
      {orderable: true, targets: [2]}
    ],
    language      : {
      search     : 'بحث :',
      lengthMenu : 'عرض _MENU_ حقل في الصفحه',
      paginate   : {
        first   : 'الاولى',
        last    : 'الاخيرة',
        next    : 'التالي',
        previous: 'السابق'
      },
      info       : 'عرض _END_ من اصل _TOTAL_ المجموع',
      zeroRecords: 'لا يوجد يوجد تطابق مع البحث',
      emptyTable : 'لا يوجد معلومات لعرضها'
    }
  };
  oldFormType                                                              = '';
  employeeId: string = null;
  selectedClassIndex: number = null;
  selectedBranchIndex: number = null;
  selectedSubjectIndex: number = null;
  selectedLessonIndex: number = null;
  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, private formBuilder: FormBuilder, public API: APIService, public print: PrintService,
              private cd: ChangeDetectorRef,
              public route: ActivatedRoute) {
    this.employeeId = this.route.snapshot.params.employeeId;
    this.employeeForm = this.formBuilder.group({
      selectedClassIndex   : ['', [Validators.required]],
      selectedBranchIndex  : ['', [Validators.required]],
      selectedSubjectIndex : ['', [Validators.required]],
      class                : ['', [Validators.required, Validators.pattern('^[0-9a-fA-F]{24}$')]],
      branch               : ['', [Validators.required, Validators.pattern('^[0-9a-fA-F]{24}$')]],
      subject              : ['', [Validators.required, Validators.pattern('^[0-9a-fA-F]{24}$')]],
      lessons              : ['', [Validators.required, Validators.min(0)]],
    });
  }

  ngAfterViewInit() {
    this.fetch();
  }

  ngOnInit(): void {
    this.responsive.check();
  }

  printSelected(type = 'employeeDetails') {
    const data = [];
    this.selected.forEach((employeeIndex) => data.push(this.employees[employeeIndex]));
    this.print.data = data;
    this.print.printDocument('student', type);
  }

  dropDown(event: MouseEvent, index: number): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets  = document.body.getBoundingClientRect();
    let tempX          = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();

    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY       = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData = index;
    this.selectedLessonIndex = index;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetch() {
    this.modalBlockUi.stop();
    this.classes  = [];
    this.branches = [];
    this.subjects = [];
    this.blockUI.stop();
    this.blockUI.start('جاري التحميل...');
    this.statistics = {
      all    : 0,
      teacher: 0,
      manager: 0,
      driver : 0,
    };
    this.API.fetchEmployees('teacher').then((employees: Employee[]) => {
      this.employeesData = employees;
      this.employees     = employees;
      if (this.employeeId) {
        this.employees.forEach((employee, index) => {
          if (employee._id === this.employeeId) {
            this.selectedEmployeeIndex = index;
            this.filtering = this.employeeId;
            this.employeeId = null;
          }
        });
      }
      this.redrawDataTable();
    });
  }

  showModal(mode: 'adding' | 'editing' | 'editingClass' | 'deleting' | 'details') {
    // this.imageUpload.nativeElement.value = '';
    this.formMode = mode;
    switch (mode) {
      case 'adding':
        this.selectedClassIndex = -1;
        this.selectedBranchIndex = -1;
        this.selectedSubjectIndex = -1;
        this.employeeForm.reset();
        break;
      case 'editing':
        this.employeeForm.reset();
        this.employeeForm.patchValue(this.employees[this.selectedEmployeeIndex].lessons[this.dropdownData]);
        break;
      case 'deleting':
    }
    $('#modal-animate').modal('show');
  }

  submitForm(myForm: FormGroup) {
    this.modalBlockUi.start('جاري الحفظ ...');
    if (this.formMode === 'adding') {
      this.employees[this.selectedEmployeeIndex].lessons.push({
        ...this.employeeForm.value,
        _id: Math.random(),
        class: this.API.classes[this.selectedClassIndex],
        branch: this.API.classes[this.selectedClassIndex].branches[this.selectedBranchIndex],
        subject: this.API.classes[this.selectedClassIndex].subjects[this.selectedSubjectIndex],
      });
      this.cd.detectChanges();
    } else if (this.formMode === 'editing') {
      this.employees[this.selectedEmployeeIndex].lessons.splice(this.selectedLessonIndex, 1);
      this.employees[this.selectedEmployeeIndex].lessons.push({
        ...this.employeeForm.value,
        _id: Math.random(),
        class: this.API.classes[this.selectedClassIndex],
        branch: this.API.classes[this.selectedClassIndex].branches[this.selectedBranchIndex],
        subject: this.API.classes[this.selectedClassIndex].subjects[this.selectedSubjectIndex],
      });
      this.cd.detectChanges();
    } else if (this.formMode === 'deleting') {
      this.employees[this.selectedEmployeeIndex].lessons.splice(this.selectedLessonIndex, 1);
      this.cd.detectChanges();
    }
    this.API.updateEmployeeLissons({lessons: this.employees[this.selectedEmployeeIndex].lessons}, this.employees[this.selectedEmployeeIndex]._id).then(student => {
      if (student) {
        setTimeout(() => {
          $('#modal-animate').modal('hide');
          this.fetch();
          this.employeeForm.reset();
        }, 1000);
      }
    }).catch((error) => {
      this.modalBlockUi.stop();
    });
  }

  toggleSelect(event) {
    if (event.target.checked) {
      this.selected.push(event.target.value);
    } else {
      this.selected.splice(this.selected.indexOf(event.target.value), 1);
    }
  }

  toggleSelectAll() {
    if (this.allSelected) {
      this.selected.splice(0, this.selected.length);
    } else {
      this.employees.forEach((item, index) => this.selected.push(index));
    }
    this.allSelected = !this.allSelected;
  }

  filterEmployees(employee: number) {
    this.selected.splice(0, this.selected.length);
    this.filtering = this.employees[employee]._id;
    this.selectedEmployeeIndex = employee;
    this.redrawDataTable();
  }

  redrawDataTable() {
    const instance = this.dtElement.dtInstance;
    console.log(instance);
    if (instance) {
      instance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.blockUI.stop();
        this.cd.detectChanges();
      });
    } else {
      this.dtTrigger.next();
      this.blockUI.stop();
      this.cd.detectChanges();
    }
  }
  classChanged(event: any) {
    const index = event.target.value;
    this.employeeForm.patchValue({
      class: this.API.classes[index]._id,
    });
    this.selectedClassIndex = index;
  }
  branchChanged(event: any) {
    const index = event.target.value;
    this.employeeForm.patchValue({
      branch: this.API.classes[this.selectedClassIndex].branches[index]._id,
    });
    this.selectedBranchIndex = index;
  }
  subjectChanged(event: any) {
    const index = event.target.value;
    this.employeeForm.patchValue({
      subject: this.API.classes[this.selectedClassIndex].subjects[index]._id,
    });
    this.selectedSubjectIndex = index;
  }
  sum(lessons: any) {
    return lessons.reduce((a, b) => a + (b.lessons || 0), 0);
  }
}
