import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorMsg} from '../../interfaces/error-msg';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {APIService} from '../../services/API.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  alertMsg: ErrorMsg = null;
  loading = false;
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  lang = 'ar';
  languages = {
    ar: {
      name: 'العربية',
      dir: 'rtl'
    },
    en: {
      name: 'English',
      dir: 'ltr'
    },
    fr: {
      name: 'Français',
      dir: 'ltr'
    },
  };
  Keys = Object.keys;
  constructor(private router: Router, private auth: AuthService, private API: APIService,
              private translate: TranslateService) {
    this.lang = localStorage.getItem('lang') || 'ar';
    this.changeLang(this.lang);
  }

  ngOnInit() {
  }

  changeLang(lang: string) {
    const dir = this.languages[lang].dir;
    this.lang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    localStorage.setItem('direction', dir);
    document.getElementsByTagName('html')[0].setAttribute('dir', dir);
  }
  showAlert(message, type) {
    this.alertMsg = {
      message,
      type
    };
    setTimeout(() => (this.alertMsg = null), 5000);
  }

  doLogin() {
    this.loading = true;
    this.auth.doLogin(this.loginForm.value).then(login => {
      console.log(login);
      this.loading = false;
      this.API.fetch();
      this.showAlert('تم تسجيل الدخول بنجاح', 'success');
      setTimeout(() => this.auth.checkToken(), 1000);
    })
      .catch(error => {
        console.log(error);
        this.loading = false;
        this.showAlert(error.error.message, 'danger');
      });
  }

}
