import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import * as chartJs from 'chart.js';
import { APIService } from '../../services/API.service';

declare var feather: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit {
  leaderboard: Array<{
    _id: string,
    name: string,
    image: string,
    stats: {
      interactive: {
        count: number;
        success: number;
        avg: number;
      };
      homework: {
        count: number;
        success: number;
        avg: number;
      };
      exam: {
        count: number;
        avg: number;
      };
    };
    overAll: number;
  }> = [];
  opt = {
    barColor(percent) {
      const ctx = this.renderer.getCtx();
      const canvas = this.renderer.getCanvas();
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, '#31c971');
      gradient.addColorStop(1, '#f4c414');
      return gradient;
    },
    trackColor: '#f9f9f9',
    scaleColor: 'transparent',
    scaleLength: 5,
    responsive: true,
    lineCap: 'round',
    lineWidth: 3,
    size: 90,
    rotate: 0,
    animate: {
      duration: 2000,
      enabled: true
    }
  };
  opt2 = {
    barColor(percent) {
      const ctx = this.renderer.getCtx();
      const canvas = this.renderer.getCanvas();
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, '#14bae4');
      gradient.addColorStop(1, '#f4c414');
      return gradient;
    },
    trackColor: '#f9f9f9',
    scaleColor: 'transparent',
    scaleLength: 5,
    responsive: true,
    lineCap: 'round',
    lineWidth: 3,
    size: 90,
    rotate: 0,
    animate: {
      duration: 2000,
      enabled: true
    }
  };
  today: Date = new Date();
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = ['الشهر الاول', 'الشهر الثاني', 'الشهر الثالث'];
  public barChartType: chartJs.ChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {data: [65, 72, 60], label: 'الرسوب'},
    {data: [87, 35, 89], label: 'النجاح', backgroundColor: '#31c971'},
    {data: [0, 15, 3], label: 'لم يدخل', backgroundColor: '#6c757d'}
  ];
  constructor(private router: Router, public API: APIService, private cd: ChangeDetectorRef) {
    if (this.API.employee && this.API.employee.type !== 'manager') {
      this.router.navigate(['/', 'news']);
    }
    this.API.fetchStatistics().then(() => {
      // this.cd.
    });
    this.API.fetchLeaderBoard().then((data) => {
      this.leaderboard = data;
      console.log(data);
    });
  }
  get TopLeaderboard() {
    return this.leaderboard.slice(0, 10);
  }
  get LowLeaderboard() {
    return this.leaderboard.filter((data) => data.overAll < 50).reverse().slice(0, 10);
  }

  ngAfterViewInit() {
    feather.replace({width: 16, height: 16});
  }

}
