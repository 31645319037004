import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { APIConfig } from 'src/app/config/API';
import { Salary } from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ImageBox } from '../../services/image-box.service';
import { ResponsiveService } from '../../services/responsive.service';
import { WebsocketService } from '../../services/websocket.service';
import { Employee } from './../../interfaces/API';
declare var $: any;

@Component({
  selector: 'app-salary',
  templateUrl: './employee-stats.component.html',
  styleUrls: ['./employee-stats.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: '0'}),
        animate('1s ease-in', style({opacity: '1'})),
      ]),
    ]),
  ],
})
export class EmployeeStatsComponent implements OnInit, AfterViewInit {
  @BlockUI('main') blockUI: NgBlockUI;
  @BlockUI('chat') blockUIChat: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  page = 1;
  paid = 0;
  employees: Employee[] = [];
  selectedStudentIndex = null;
  selectedEmployee: Employee;
  salarys: Salary[] = [];
  mainForm: FormGroup = this.fb.group({
    amount: [0, [Validators.required, Validators.min(0)]],
    no: ['', [Validators.required]],
    comment: ['', []],
  });
  query = '';
  type: 'all' | 'seen' | 'not_seen' = 'all';
  dtOptions: DataTables.Settings = {};
  salary = new FormControl('', [Validators.required]);
  isEditing = false;
  isSaving = false;

  constructor(
    public responsive: ResponsiveService,
    public API: APIService,
    private websocket: WebsocketService,
    private router: ActivatedRoute,
    private fb: FormBuilder,
    public imageBox: ImageBox,
    public http: HttpClient,
  ) {
  }
  editSalary() {
    this.isSaving = true;
    this.API.updateEmployee({
      salary: this.salary.value,
    }, this.selectedEmployee._id).then(() => {
      this.isEditing = false;
      this.isSaving = false;
      this.selectedEmployee.salary = this.salary.value;
    });
  }
  selectEmployee(employee: Employee) {
    this.selectedEmployee = employee;
    this.isEditing = false;
    this.salary.patchValue(employee.salary);
    setTimeout(() => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        if (this.salarys.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    });
  }
  fetchStudents(reload: boolean = false) {
    this.blockUI.start();
    if (reload) {
      this.page = 1;
      this.employees = [];
    } else {
      this.page++;
    }
    this.API.fetchSalaryEmployees(this.page, this.query).then((employees: Employee[]) => {
      this.employees.push(...employees);
      this.blockUI.stop();
    });
  }

  ngOnInit() {
    this.responsive.check();
    this.dtOptions = {
      info: false,
      search: true,
      lengthMenu: [10, 25, 50],
      lengthChange: true,
      pageLength: 10,
      dom: 'Rfrtlip',
      scrollY: '70vh',
      scrollCollapse: true,
      columnDefs: [
        { orderable: true, targets: [2] }
      ],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        if (!this.selectedEmployee) {
          return;
        }
        this.blockUI.stop();
        this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: Salary[];
            recordsTotal: number;
            recordsFiltered: number;
            paid: number;
          }>(
            APIConfig.uri + 'salary/datatable?' + (this.selectEmployee ? '&employee=' + this.selectedEmployee._id : ''),
            { ...dataTablesParameters, filter: this.type }, { headers: this.API.AuthHeaders }
          ).subscribe(resp => {
            this.blockUI.stop();
            this.salarys = resp.data;
            this.paid = resp.paid;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      language: {
        search: 'بحث :',
        lengthMenu: 'عرض _MENU_ حقل في الصفحه',
        paginate: {
          first: 'الاولى',
          last: 'الاخيرة',
          next: 'التالي',
          previous: 'السابق'
        },
        info: 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable: 'لا يوجد معلومات لعرضها'
      }
    };
  }

  ngAfterViewInit(): void {
    this.fetchStudents(true);
  }
  showModal() {
    this.mainForm.reset();
    $('#modal-animate').modal();
  }
  submit() {
    this.blockUIChat.start('جاري الحفظ');
    this.API.createSalary({
      ...this.mainForm.value,
      employee: this.selectedEmployee._id,
    }).then(student => {
      this.blockUIChat.stop();
      if (student) {
        this.selectEmployee(this.selectedEmployee);
        setTimeout(() => {
          this.mainForm.reset();
          $('#modal-animate').modal('hide');
        }, 1000);
      }
    }).catch((error) => {
      this.blockUIChat.stop();
    });
  }
}
