import {AfterViewInit, Component} from '@angular/core';
import * as $ from 'jquery';
import {APIService} from '../../../../services/API.service';

declare var feather: any;

@Component({
  selector: 'app-side-content',
  templateUrl: './side-content.component.html',
  styleUrls: ['./side-content.component.scss']
})
export class SideContentComponent implements AfterViewInit {

  constructor( public API: APIService) {
  }

  ngAfterViewInit() {
    feather.replace({width: 16, height: 16});
    $(document).on('click', '[data-nav] a', function(e) {
      const $this = $(this);

      const $li = $this.parent();
      const $lili = $li.parents('li');

      const $active = $li.closest('[data-nav]').find('.active');

      $lili.addClass('active');
      if ($this.next().is('ul')) {
        $li.toggleClass('active');
      } else {
        $li.addClass('active');
      }

      $active.not($lili).not($li).removeClass('active');

      if ($this.attr('href') && $this.attr('href') !== '#') {
        $(document).trigger('Nav:changed');
      }
    });
  }

}
