import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {APIComment, Post} from '../../interfaces/API';
import {ResponsiveService} from '../../services/responsive.service';
import {APIService} from '../../services/API.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ImageBox} from '../../services/image-box.service';
import {ActivatedRoute} from '@angular/router';
import {DOCUMENT} from '@angular/common';

declare var $: any;

interface Form {
  mode: 'adding' | 'deleting' | 'editing';
  form: FormControl;
  modePost: 'adding' | 'deleting' | 'editing';
  post: FormControl;
}


@Component({
  selector: 'app-polls',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class AttendPostComponent implements AfterViewInit, OnInit {
  @BlockUI('table') blockUI: NgBlockUI;
  @ViewChild('videoUpload', {static: true}) videoUpload: ElementRef;
  @ViewChild('imageUpload', {static: true}) imageUpload: ElementRef;
  postBody = new FormControl('', [Validators.required]);
  posts: Post[] = [];
  postsData: Post[] = [];
  more = true;
  loadingMore = true;
  commentForm: Form[] = [];
  selectedPostIndex = -1;
  selectedCommentIndex = -1;
  items: FormArray;
  media: {
    image: string,
    file: File | string,
    type: 'video' | 'image' | 'youtube' | 'loading'
  }[] = [];
  postId = null;
  page = 0;
  attendStart = new FormControl('', [Validators.required]);
  attendEnd = new FormControl('', [Validators.required]);

  constructor(public responsive: ResponsiveService,
              public API: APIService,
              private formBuilder: FormBuilder,
              public domSanitizationService: DomSanitizer,
              public imageBox: ImageBox,
              private router: ActivatedRoute,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngAfterViewInit() {
    this.fetch();
    // if (this.postId) {
    //   this.API.fetchPostById().then(post => this.posts.unshift(post));
    // }
  }

  initFormControl(index: number): FormControl {
    this.router.params.subscribe(({postId}) => {
      this.postId = postId ? postId : null;
    });
    const form = new FormControl('', [Validators.required]);
    const formPost = new FormControl('', [Validators.required]);
    this.commentForm.push({
      mode: 'adding',
      modePost: 'adding',
      form,
      post: formPost
    });
    return form;
  }

  getFormControl(index: number): Form {
    return this.commentForm[index];
  }

  ngOnInit(): void {
    this.responsive.check();
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const blob = event.target.files[i];
        const type = blob.type.split('/')[0];
        if (type === 'image') {
          const reader = new FileReader();
          reader.onload = (readerEvent: any) => {
            this.imageUpload.nativeElement.value = '';
            const index = this.media.push({
              image: readerEvent.target.result,
              file: blob,
              type: 'loading',
            }) - 1;
            this.API.uploadFile(blob).then(res => {
              this.media[index].file = res;
              this.media[index].type = 'image';
            });
          };
          reader.readAsDataURL(blob);
        } else {
          this.generateThumbnail(blob).then(image => {
            const index = this.media.push({
              image,
              file: blob,
              type: 'loading',
            }) - 1;
            this.API.uploadFile(blob).then(res => {
              this.media[index].file = res;
              this.media[index].type = 'video';
            });
          });
        }
      }
    }
  }

  public generateThumbnail(videoFile: Blob): Promise<string> {
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<string>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      video.addEventListener('error', reject);
      video.addEventListener('canplay', event => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
    });
  }

  public youtubeVideo() {
    const videoLink = prompt('ادخل رابط الفديو');
    if (videoLink !== undefined || videoLink !== '') {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
      const match = videoLink.match(regExp);
      if (match && match[2].length === 11) {
        const thumbnail = 'http://img.youtube.com/vi/' + match[2] + '/2.jpg';
        this.media.push({
          image: thumbnail,
          file: 'https://www.youtube.com/embed/' + match[2],
          type: 'youtube',
        });
      } else {
        alert(1);
      }
    }
  }

  dropDown(event: MouseEvent, postId: number): void {
    const dropdownMenu = $('#post-dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.selectedPostIndex = postId;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click(e => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  dropDownComment(event: MouseEvent, postId: number, commentId: number): void {
    const dropdownMenu = $('#comment-dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.selectedPostIndex = postId;
    this.selectedCommentIndex = commentId;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click(e => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetchPage() {
    this.page++;
    this.loadingMore = true;
    this.API.fetchPosts('attend', this.page).then((posts: Post[]) => {
      this.loadingMore = false;
      this.more = posts.length > 0;
      this.postsData = this.posts;
      this.posts = [];
      this.posts = posts;
      this.commentForm = [];
      this.posts = this.posts.map((post: Post, index: number) => {
        post.attachments = post.attachments.map(attachment => attachment.type !== 'video' ? ({
          ...attachment,
          url: this.domSanitizationService.bypassSecurityTrustResourceUrl(attachment.url as string)
        }) : attachment);
        return post;
      });
      this.postsData.push(...this.posts);
      this.posts = [];
      this.posts.push(...this.postsData);
      this.commentForm = [];
      this.posts.forEach((post: Post, index: number) => {
        this.initFormControl(index);
      });
      this.blockUI.stop();
    });
  }

  fetch() {
    this.page = 0;
    this.loadingMore = true;
    this.API.fetchPosts('attend').then((posts: Post[]) => {
      this.loadingMore = false;
      this.more = posts.length > 0;
      this.posts = [];
      this.commentForm = [];
      this.posts = posts;
      this.posts = this.posts.map((post: Post, index: number) => {
        this.initFormControl(index);
        post.attachments = post.attachments.map(attachment => attachment.type !== 'video' ? ({
          ...attachment,
          url: this.domSanitizationService.bypassSecurityTrustResourceUrl(attachment.url as string)
        }) : attachment);
        return post;
      });
      this.blockUI.stop();
      this.postsData = this.posts;
    });
  }

  updateComment(postId: string, comment: boolean) {
    this.API.updatePost2({
      comment
    }, postId).then((post: boolean) => {
      this.fetch();
    });
  }

  submitPost(formControl: Form, postIndex: number) {
    this.blockUI.start('جاري التحميل...');
    switch (formControl ? formControl.modePost : 'adding') {
      case 'adding':
        const attachments: { url: File | string, type: string }[] = [];
        this.media.forEach(media => {
          if (media.type === 'loading') {
            this.API.showAlert('رجاءاً انتظر يوجد ملف على قيد الرفع', 'warning');
            return;
          }
          attachments.push({
            url: media.file,
            type: media.type,
          });
        });
        const payload = {
          attendStart: new Date(this.attendStart.value).toISOString(),
          attendEnd: new Date(this.attendEnd.value).toISOString(),
        };
        this.API.createPost(this.postBody.value, attachments, 'attend', null, null, null, payload).then((post: boolean) => {
          this.fetch();
          this.media.splice(0, this.media.length);
          this.postBody.reset();
        });
        break;
      case 'editing':
        this.API.updatePost(formControl.post, this.posts[postIndex]._id).then((post: boolean) => {
          this.fetch();
          formControl.post.reset();
          formControl.modePost = 'adding';
        });
        break;
      case 'deleting':
        if (!confirm('هل انت متأكد من حذف الخبر ؟')) {
          this.blockUI.stop();
          return;
        }
        this.API.deletePost(this.posts[postIndex]._id).then((post: boolean) => {
          this.fetch();
          formControl.post.reset();
          formControl.modePost = 'adding';
        });
        break;
    }
  }

  submitComment(formControl: Form, postIndex: number, commentIndex: number = -1) {
    this.blockUI.start('جاري التحميل...');
    const modal = $('#modal-alert');
    switch (formControl.mode) {
      case 'adding':
        this.API.createComment(formControl.form, this.posts[postIndex]._id).then((comment: APIComment) => {
          this.posts[postIndex].comments.push(comment);
          formControl.form.reset();
          formControl.mode = 'adding';
          this.blockUI.stop();
        }).catch(error => modal.modal());
        break;
      case 'editing':
        this.API.updateComment(formControl.form, this.posts[this.selectedPostIndex].comments[this.selectedCommentIndex]._id).then((comment: any) => {
          this.posts[this.selectedPostIndex].comments[this.selectedCommentIndex].comment = formControl.form.value;
          formControl.form.reset();
          formControl.mode = 'adding';
          this.blockUI.stop();
        }).catch(error => modal.modal());
        break;
      case 'deleting':
        if (!confirm('هل انت متأكد من حذف التعليق ؟')) {
          this.blockUI.stop();
          return;
        }
        this.API.deleteComment(this.posts[this.selectedPostIndex].comments[this.selectedCommentIndex]._id).then((post: boolean) => {
          this.posts[this.selectedPostIndex].comments.splice(this.selectedCommentIndex, 1);
          formControl.form.reset();
          formControl.mode = 'adding';
          this.blockUI.stop();
        }).catch(error => modal.modal());
        break;
    }
  }
  totalVoters(options: Post['options']) {
    let total = 0;
    options.forEach((option) => total += option.students.length);
    return total;
  }
  showStudents(index: number) {
    this.selectedPostIndex = index;
    $('#modal-new').modal('show');
  }
}
