import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Subject } from 'rxjs';
import { APIConfig } from '../../config/API';
import { Account, Transaction } from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ResponsiveService } from '../../services/responsive.service';

declare var $: any;

@Component({
  selector   : 'app-transportation',
  templateUrl: './transaction.component.html',
  styleUrls  : ['./transaction.component.scss']
})
export class TransactionComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  formMode: 'adding' | 'editing' | 'deleting' | 'details' | 'filter' = 'adding';
  transactionsData: Transaction[]                                   = [];
  transactions: Transaction[]                                       = [];
  dropdown                                                = false;
  printHeader = {
    title: localStorage.getItem('print-title') || 'عنوان رئيسي',
    subtitle: localStorage.getItem('print-sub-title') || 'عنوان ثانوي',
  };
  dropdownData: Transaction                                    = null;
  mainForm: FormGroup;
  filterForm: FormGroup;
  dtTrigger: Subject<any>                                 = new Subject();
  selectedData: {[key: string]: Transaction}             = {};
  selected: string[]             = [];
  total = 0;
  types                                                  = {
    IQD  : {
      text : 'دينار عراقي',
      class: 'success'
    },
    USD: {
      text : 'دولار امريكي',
      class: 'warning'
    },
  };
  methods                                                 = {
    cash    : {
      text : 'كاش',
      class: 'success'
    },
    cheque    : {
      text : 'شيك',
      class: 'primary'
    },
  };
  dataTablesParameters: any;
  dtOptions: DataTables.Settings                          = {};
  changingId: string                                      = null;
  filtered = false;
  totalAmount = 0;
  adding = false;
  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, private formBuilder: FormBuilder, public API: APIService,
              private http: HttpClient,
              private datePipe: DatePipe) {
    this.mainForm = this.formBuilder.group({
      amount : ['', [Validators.required]],
      account: ['', [Validators.required]],
      transaction_date: ['', [Validators.required]],
      comment   : ['', []],
      no   : ['', [Validators.required]],
    });
    this.filterForm = this.formBuilder.group({
      account: ['', []],
    });
    this.filterForm.valueChanges.subscribe(data => {
      this.filtered = data.date !== '' || data.account !== '' || data.method !== '';
      this.fetch();
      this.unselectAll();
    });
  }

  displayWith(obj?: Account): string | undefined {
    return obj ? obj.title : undefined;
  }
  clearFilter() {
    this.filterForm.patchValue({
      date   : '',
      type   : 'USD',
      account: '',
      method : '',
    });
    this.filtered = false;
  }
  ngAfterViewInit() {
    const self = this;
    const selectDepartment = $('#account-filter');
    selectDepartment.select2();
    selectDepartment.on('change', function() {
      self.filterForm.patchValue({
        account: $(this).val(),
      });
    });
    const selectCusomter2 = $('#account-filter2');
    selectCusomter2.select2({ width: '100%' });
    selectCusomter2.on('change', function() {
      self.mainForm.patchValue({
        account: $(this).val(),
      });
    });
  }

  ngOnInit(): void {
    this.responsive.check();
    this.dtOptions = {
      info        : false,
      search      : true,
      lengthMenu  : [10, 25, 50, 100, 250, 500],
      lengthChange: true,
      pageLength  : 10,
      dom         : 'Rfrtlip',
      autoWidth   : false,
      columns     : [
        {width: '5%', name: 'created_At', orderable: true},
        {width: '15%', name: 'no', orderable: true},
        {width: '10%', name: 'transaction_date', orderable: true},
        {width: '10%', name: 'date', orderable: true},
        {width: '20%', name: 'amount', orderable: true},
        {width: '15%', name: 'account', orderable: true},
        {width: '20%', name: 'comment', orderable: true},
        {width: '5%', name: 'settings', orderable: false},
      ],
      columnDefs  : [
        {orderable: true, targets: [2]}
      ],
      order       : [1, 'desc'],
      serverSide  : true,
      processing  : true,
      ajax        : (dataTablesParameters: any, callback) => {
        this.dataTablesParameters = dataTablesParameters;
        this.blockUI.stop();
        // this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: Transaction[];
            recordsTotal: number;
            recordsFiltered: number;
            total: number;
          }>(
            APIConfig.uri + `transaction/datatable?type=IN`, {
              ...dataTablesParameters,
              account: this.filterForm.value.account ? this.filterForm.value.account : '',
            }, {headers: this.API.AuthHeaders}
          ).subscribe(resp => {
          this.blockUI.stop();
          this.transactions     = resp.data;
          this.transactionsData = resp.data;
          this.totalAmount = resp.total;
          callback({
            recordsTotal   : resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data           : []
          });
        });
      },
      language    : {
        search     : 'بحث :',
        lengthMenu : 'عرض _MENU_ حقل في الصفحه',
        paginate   : {
          first   : 'الاولى',
          last    : 'الاخيرة',
          next    : 'التالي',
          previous: 'السابق'
        },
        info       : 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable : 'لا يوجد معلومات لعرضها'
      }
    };
    setTimeout(() => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        if (this.transactions.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    });
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().substring(0, 10);
  }

  dropDown(event: MouseEvent, language: Transaction): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets  = document.body.getBoundingClientRect();
    const tempX        = !this.responsive.isMobile ? bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width() : event.pageX + dropdownMenu.width();
    const tempY        = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData  = language;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetch() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  redrawDataTable() {
    const instance = this.dtElement.dtInstance;
    if (instance) {
      instance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.blockUI.stop();
      });
    } else {
      this.dtTrigger.next();
      this.blockUI.stop();
    }
    this.changingId = null;
  }

  showModal(mode: 'adding' | 'editing' | 'deleting' | 'details' | 'filter') {
    this.formMode = mode;
    switch (mode) {
      case 'adding':
        this.mainForm.reset();
        this.mainForm.patchValue({
          date: this.currentDate(),
        });
        break;
      case 'editing':
        this.mainForm.reset();
        this.mainForm.patchValue(this.dropdownData);
        this.mainForm.patchValue({
          account: this.dropdownData.account._id,
          transaction_date: this.datePipe.transform(this.dropdownData.transaction_date, 'yyyy-MM-dd', '+0300')
        });
        $('#account-filter2').val(this.dropdownData.account._id).trigger('change');
        break;
      case 'details':
      case 'deleting':
    }
    $('#modal-animate').modal('show');
  }

  submitForm() {
    this.blockUI.start('جاري الحفظ ...');
    this.adding = true;
    if (this.formMode === 'adding') {
      this.API.createTransaction({
        ...this.mainForm.value,
        type: 'IN',
      }).then(result => {
          if (result) {
            this.fetch();
            setTimeout(() => {
              $('#modal-animate').modal('hide');
              this.mainForm.reset();
              this.adding = false;
            }, 1000);
          }
        },
        (error) => {
          this.API.showAlert(error.message, 'danger');
          this.blockUI.stop();
        });
    } else if (this.formMode === 'editing') {
      this.changingId = this.dropdownData._id;
      this.API.updateTransaction(this.dropdownData._id, this.mainForm.value).then(data => {
          if (data) {
            this.fetch();
            setTimeout(() => {
              $('#modal-animate').modal('hide');
              this.mainForm.reset();
            }, 1000);
          }
        },
        (error) => {
          this.blockUI.stop();
          this.changingId = null;
        });
    } else if (this.formMode === 'deleting') {
      this.changingId = this.dropdownData._id;
      this.API.deleteTransaction(this.dropdownData._id).then(data => {
          if (data) {
            this.fetch();
            setTimeout(() => {
              $('#modal-animate').modal('hide');
              this.mainForm.reset();
            }, 1000);
          }
        },
        (error) => {
          this.blockUI.stop();
          this.changingId = null;
        });
    } else if (this.formMode === 'filter') {
      this.fetch();
      $('#modal-animate').modal('hide');
    }
  }
  toggleSelect(event, data: Transaction) {
    if (event.target.checked) {
      this.selected.push(event.target.value);
      this.selectedData[data._id] = data;
      this.total += data.amount;
    } else {
      this.selected.splice(this.selected.indexOf(event.target.value), 1);
      delete this.selectedData[data._id];
      this.total -= data.amount;
    }
  }
  selectAll() {
    this.transactions.forEach(transaction => {
      if (this.selected.indexOf(transaction._id) === -1) {
        this.selected.push(transaction._id);
        this.selectedData[transaction._id] = transaction;
        this.total += transaction.amount;
      }
    });
  }
  printSelected() {
    window.print();
  }
  exportSelected() {

    const data = [];
    Object.keys(this.selectedData).forEach( (key) => {
      const transaction: Transaction = this.selectedData[key];
      data.push({
        no: transaction.no,
        date: this.datePipe.transform(transaction.date, 'yyyy-MM-dd', '+0300'),
        amount: transaction.amount,
        account: transaction.account.title,
        comment: transaction.comment,
      });
    });

    const csv = new ngxCsv(data, 'Excel', {
      showLabels: true,
      showTitle: true,
      title: 'الوارد العام',
      headers: [
        'رقم السند ',
        'تاريخ الوصل',
        'المبلغ',
        'الحساب',
        'الملاحظات',
      ]
    });
  }

  unselectAll() {
    this.selected.slice(0, this.selected.length);
    this.selectedData = {};
  }
  getSelectedOptionsC(selected) {
    this.mainForm.patchValue({customer: selected});
  }
  getSelectedOptionsD(selected) {
    this.mainForm.patchValue({department: selected});
  }
}
