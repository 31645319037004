import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../../services/API.service';
import { AuthService } from '../../services/auth.service';
import { Student } from './../../interfaces/API';

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QRComponent implements OnInit {
  studentForm: FormGroup;
  student: Student;
  errored = false;
  qr = '';

  constructor(public API: APIService, public AUTH: AuthService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.studentForm = this.formBuilder.group({
      qr: ['', [Validators.required]],
    });
  }
  ngOnInit() {

  }
  al() {
    if (!this.studentForm.valid) {
      return;
    }
    this.errored = false;
    this.student = null;
    this.API.qrAttendance(this.studentForm.value.qr).then((student: Student) => {
      this.student = student;
    }, () => {
      this.errored = true;
    });
    this.studentForm.reset();
  }
}
