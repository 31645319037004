import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  oldUrl = '';
  data: any;
  previousFilter =  {
    class: null,
    branch: null,
    date: null,
  };

  constructor(private router: Router) { }

  printDocument(documentName: string, documentData: string) {
    this.isPrinting = true;
    this.oldUrl = this.router.url;
    this.router.navigate(['/', 'print', documentName , documentData]);
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([this.oldUrl]);
    });
  }
}
