import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Student } from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-qr-leave',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss']
})
export class QRLeaveComponent implements OnInit {
  studentForm: FormGroup;
  student: Student;
  errored: string;
  qr = '';

  constructor(public API: APIService, public AUTH: AuthService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    this.studentForm = this.formBuilder.group({
      qr: ['', [Validators.required]],
    });
  }
  ngOnInit() {

  }
  al() {
    if (!this.studentForm.valid) {
      return;
    }
    this.errored = null;
    this.student = null;
    this.API.qrAttendanceLeave(this.studentForm.value.qr).then((student: Student) => {
      this.student = student;
    }, (error) => {
      this.errored = error.error.message;
    });
    this.studentForm.reset();
  }
}
