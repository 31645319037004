import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import fileUpload from 'fuctbase64';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import {APISubject, Branch, Class, Employee, Role, Student} from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { PrintService } from '../../services/print.service';
import { ResponsiveService } from '../../services/responsive.service';
import { Contries } from './contries';
import {ngxCsv} from 'ngx-csv';
import {Md5} from 'ts-md5';

declare var $: any;
declare var QRious: any;

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements AfterViewInit, OnInit {
  secret = 'E1E26251CBF23622327378EE8BBC4';
  @ViewChild('imageUpload', {static: true})imageUpload: ElementRef;
  @ViewChild('qrCanvas', { static: false }) qrCanvas: ElementRef;
  imageSrc = null;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('modal') modalBlockUi: NgBlockUI;
  @BlockUI('table') blockUI: NgBlockUI;
  statistics = {
    all    : 0,
    teacher   : 0,
    manager: 0,
    driver : 0,
    accounting : 0,
  };
  filtering = 'all';
  formMode: 'adding' | 'adding1' | 'editing' | 'editingClass' | 'deleting' | 'details' | 'restore' = 'adding';
  employeesData: Employee[] = [];
  employees: Employee[] = [];
  allSelected = false;
  selected: number[] = [];
  dropdown = false;
  dropdownData: Employee = null;
  contries = Contries;
  employeeForm: FormGroup;
  classes: Class[] = [];
  branches: Branch[] = [];
  subjects: APISubject[] = [];
  classesDetails: string[] = [];
  subjectsDetails: string[] = [];
  branchesDetails: string[] = [];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {
    info: false,
    search: true,
    lengthChange: false,
    pageLength: 10,
    responsive: true,
    autoWidth: true,
    dom: 'Rfrtlip',
    scrollY:        '70vh',
    scrollCollapse: true,
    columnDefs: [
      {orderable: true, targets: [2]}
    ],

  };
  oldFormType = '';
  extraForm: FormGroup = this.formBuilder.group({
    custom_1: ['', []],
    custom_2: ['', []],
    custom_3: ['', []],
    custom_4: ['', []],
    custom_5: ['', []],
    custom_6: ['', []],
    custom_7: ['', []],
    custom_8: ['', []],
    custom_9: ['', []],
    custom_10: ['', []],
    custom_11: ['', []],
    custom_12: ['', []],
  });
  Keys = Object.keys;
  permissions = {
    teacher: {
      chat: 'المحادثات',
      post_all: 'النشر لكامل المؤسسة',
      post_attend: 'نشر منشورات تسجيل الحضور',
      library: 'المكتبة الالكترونية',
      post_meet: 'نشر الاجتماعات',
      post_lesson: 'نشر الدروس الالكترونية',
      exam: 'انشاء ونشر الامتحانات',
      post_poll: 'نشر استطلاعات الرأي',
      homework: 'نشر الواجبات المنزلية',
      post_report: 'نشر التبليغات',
      marks: 'الدرجات',
      attend: 'الحضور والغياب',
      accounting: 'ادارة الحسابات',
      student: 'ادارة الطلبة',
      employees: 'ادارة الموظفين',
      schedule: 'الجداول الاسبوعية',
      groupChat: 'المحادثات الجماعية',
      registration_manage: 'ادارة صفحة طلبات القبول',
      registration_add: 'الموافقة او رفض طلبات القبول',
      registration_accept: 'مراجعة طلبات القبول (التسجيل)',
    },
    driver: {},
    manager: {
      chat: 'المحادثات',
    }
  };
  roles: Role[] = [];
  permissionsForm: FormGroup = this.formBuilder.group({
    post_all: [false, []],
    post_attend: [false, []],
    library: [false, []],
    post_meet: [false, []],
    post_lesson: [false, []],
    exam: [false, []],
    post_poll: [false, []],
    homework: [false, []],
    post_report: [false, []],
    chat: [false, []],
    marks: [false, []],
    attend: [false, []],
    accounting: [false, []],
    student: [false, []],
    employees: [false, []],
    schedule: [false, []],
    groupChat: [false, []],
    registration_manage: [false, []],
    registration_add: [false, []],
    registration_accept: [false, []],
  });

  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, private formBuilder: FormBuilder, public API: APIService, public print: PrintService) {
    this.employeeForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      role: ['', [Validators.required]],
      image: ['', []],
      address: ['', []],
      type: ['', [Validators.required]],
      password: [''],
      salary: ['', [Validators.min(0)]],
      zoom_email: ['', []],
      zoom_password: ['', []],
    });
    // this.employeeForm.valueChanges.subscribe(value => {
    //   if (value.type !== this.oldFormType) {
    //     this.oldFormType = value.type;
    //     if (value.type !== 'teacher') {
    //       Object.keys(this.permissions).forEach(permission => {
    //         this.permissionsForm.controls[permission].disable({onlySelf: true});
    //         this.permissionsForm.controls[permission].patchValue(false);
    //       });
    //     } else {
    //       Object.keys(this.permissions).forEach(permission => {
    //         this.permissionsForm.controls[permission].enable({onlySelf: true});
    //         // this.employeeForm.controls[permission].patchValue(false);
    //       });
    //     }
    //   }
    // });
  }

  ngAfterViewInit() {
    this.fetch();
    this.API.fetchRoles().subscribe(roles => {
      this.roles = roles;
    });
  }

  ngOnInit(): void {
    this.responsive.check();
  }

  printSelected(type = 'employeeDetails') {
    const data = [];
    this.selected.forEach((employeeIndex) => data.push(this.employees[employeeIndex]));
    this.print.data = data;
    this.print.printDocument('student', type);
  }
  exportSelected() {
    const data = [];
    const types = {
      teacher: 'تدريسي',
      manager: 'اداري',
      driver: 'سائق',
      accounting: 'محاسب'
    };
    this.selected.forEach((employeeIndex, index) => {
      const employee: Employee = this.employees[employeeIndex];
      data.push({
        index: index + 1,
        name: employee.name,
        type: types[employee.type],
        phone: employee.phone ? employee.phone : '',
        username: employee.username,
        password: employee.password,
      });
    });
    const csv = new ngxCsv(data, 'Excel', {
      showLabels: true,
      showTitle: true,
      title: 'ادارة الموظفين',
      headers: [
        '#',
        'اسم الموظف',
        'النوع',
        'رقم الهاتف',
        'اسم المستخدم',
        'كلمة المرور',
      ]
    });
  }
  dropDown(event: MouseEvent, employee: Employee): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets = document.body.getBoundingClientRect();
    let tempX = bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width();
    if (this.responsive.isMobile) {
      tempX = bodyOffsets.right - event.pageX - dropdownMenu.width();
    }
    const tempY = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData = employee;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetch() {
    this.modalBlockUi.stop();
    this.classes = [];
    this.branches = [];
    this.subjects = [];
    this.blockUI.stop();
    this.blockUI.start('جاري التحميل...');
    this.statistics = {
      all    : 0,
      teacher   : 0,
      manager: 0,
      driver : 0,
      accounting : 0,
    };
    this.API.fetchEmployees(null, true).then((employees: Employee[]) => {
      this.employeesData = employees;
      this.employees = employees;
      employees.forEach(employee => {
        this.statistics.all++;
        this.statistics[employee.type]++;
      });
      this.redrawDataTable();
    });
  }

  showModal(mode: 'adding' | 'adding1' | 'editing' | 'editingClass' | 'deleting' | 'details' | 'restore') {
    // this.imageUpload.nativeElement.value = '';
    this.imageSrc = null;
    if (this.imageUpload) {
      this.imageUpload.nativeElement.value = '';
    }
    this.formMode = mode;
    switch (mode) {
      case 'adding':
        this.employeeForm.reset();
        this.extraForm.reset();
        this.permissionsForm.reset();
        break;
      case 'editing':
        this.employeeForm.reset();
        this.extraForm.reset();
        this.employeeForm.patchValue(this.dropdownData);
        if (this.dropdownData.role) {
          this.employeeForm.patchValue({
            role: this.dropdownData.role._id
          });
        }
        this.permissionsForm.patchValue(this.dropdownData.permissions);
        if (this.dropdownData.extra) {
          this.extraForm.patchValue(this.dropdownData.extra);
        }
        break;
      case 'editingClass':
        this.classes = [];
        this.branches = [];
        this.subjects = [];
        const userClasses = this.dropdownData.classes.map(userClass => userClass._id);
        const userBranches = this.dropdownData.branches.map(userBranch => userBranch._id);
        const userSubjects = this.dropdownData.subjects.map(userSubject => userSubject._id ? userSubject._id : userSubject);
        console.log(this.dropdownData.subjects);
        this.API.classes.forEach(myClass => {
          if (userClasses.indexOf(myClass._id) !== -1) {
            this.classes.push(myClass);
          }
          myClass.subjects.forEach(mySubject => {
            if (userSubjects.indexOf(mySubject._id) !== -1) {
              this.subjects.push(mySubject);
            }
          });
          myClass.branches.forEach(myBranch => {
            if (userBranches.indexOf(myBranch._id) !== -1) {
              this.branches.push(myBranch);
            }
          });
        });
        break;
      case 'details':
        this.classesDetails = [];
        this.branchesDetails = [];
        this.subjectsDetails = [];
        const userSubjects2 = this.dropdownData.subjects.map(userSubject => userSubject._id ? userSubject._id : userSubject);
        const userBranches2 = this.dropdownData.branches.map(userBranch => userBranch._id);
        this.classesDetails = this.dropdownData.classes.map(myClass => myClass.name);
        this.API.classes.forEach(myClass => {
          myClass.subjects.forEach(mySubject => {
            if (userSubjects2.indexOf(mySubject._id) !== -1) {
              this.subjectsDetails.push(`${myClass.name} - ${mySubject.name}`);
            }
          });
          myClass.branches.forEach(myBranch => {
            if (userBranches2.indexOf(myBranch._id) !== -1) {
              this.branchesDetails.push(`${myClass.name} - ${myBranch.name}`);
            }
          });
        });
        break;
      case 'deleting':
      case 'restore':
    }
    $('#modal-animate').modal('show');
  }

  submitForm(myForm: FormGroup) {
    this.modalBlockUi.start('جاري الحفظ ...');
    if (this.formMode === 'adding') {
      this.API.createEmployee({
        ...myForm.value,
        permissions: this.permissionsForm.value,
        extra: this.extraForm.value,
      }).then(result => {
        if (result) {
          this.fetch();
          setTimeout(() => {
            $('#modal-animate').modal('hide');
            this.employeeForm.reset();
          }, 1000);
        }
      }).catch((error) => {
        this.modalBlockUi.stop();
      });
    } else if (this.formMode === 'editing') {
      if (!this.imageSrc) {
        myForm.patchValue({image: null});
      }
      this.API.updateEmployee({
        ...myForm.value,
        permissions: this.permissionsForm.value,
        extra: this.extraForm.value,
      }, this.dropdownData._id).then(student => {
        if (student) {
          this.fetch();
          setTimeout(() => {
            $('#modal-animate').modal('hide');
            this.employeeForm.reset();
            this.extraForm.reset();
          }, 1000);
        }
      }).catch((error) => {
        this.modalBlockUi.stop();
      });
    } else if (this.formMode === 'editingClass') {
      this.API.updateEmployee({
        classes: this.classes.map(myClass => myClass._id),
        branches: this.branches.map(myBranch => myBranch._id),
        subjects: this.subjects.map(subject => subject._id)
      }, this.dropdownData._id).then(student => {
        if (student) {
          this.fetch();
          setTimeout(() => {
            $('#modal-animate').modal('hide');
            this.employeeForm.reset();
            this.extraForm.reset();
          }, 1000);
        }
      }).catch((error) => {
        this.modalBlockUi.stop();
      });
    } else if (this.formMode === 'deleting') {
      this.API.deleteEmployee(this.dropdownData._id).then(student => {
        if (student) {
          this.fetch();
          setTimeout(() => {
            $('#modal-animate').modal('hide');
            this.employeeForm.reset();
            this.extraForm.reset();
          }, 1000);
        }
      }).catch((error) => {
        this.modalBlockUi.stop();
      });
    } else if (this.formMode === 'restore') {
      this.API.updateEmployee({deleted: false} , this.dropdownData._id).then(student => {
        if (student) {
          this.fetch();
          setTimeout(() => {
            $('#modal-animate').modal('hide');
            this.employeeForm.reset();
            this.extraForm.reset();
          }, 1000);
        }
      }).catch((error) => {
        this.modalBlockUi.stop();
      });
    }

    if (this.imageUpload) {
      this.imageUpload.nativeElement.value = '';
    }
    this.imageSrc = null;
  }
  toggleSelect(event) {
    if (event.target.checked) {
      this.selected.push(event.target.value);
    } else {
      this.selected.splice(this.selected.indexOf(event.target.value), 1);
    }
  }
  toggleSelectAll() {
    if (this.allSelected) {
      this.selected.splice(0, this.selected.length);
    } else {
      this.employees.forEach((item, index) => this.selected.push(index));
    }
    this.allSelected = !this.allSelected;
  }
  filterEmployees(type: string) {
    this.selected.splice(0, this.selected.length);
    this.filtering = type;
    this.employees = this.employeesData.filter((employee: Employee) => type === 'all' || employee.type === type);
    this.redrawDataTable();
  }

  fileChanged(event: any) {
    fileUpload(event).then(result => {
      this.employeeForm.patchValue({
        image: `data:image/jpeg;base64, ${result.base64}`
      });
      this.imageSrc = (`data:image/jpeg;base64, ${result.base64}`);
    });
  }
  redrawDataTable() {
    const instance = this.dtElement.dtInstance;
    if (instance) {
      instance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.blockUI.stop();
      });
    } else {
      this.dtTrigger.next();
      this.blockUI.stop();
    }
  }
  openConversation() {
    this.blockUI.start('جاري التحميل ...');
    this.API.openEmployeeConversation(this.dropdownData._id).then(student => {
      if (student) {
        this.blockUI.stop();
      }
    }).catch((error) => {
      this.blockUI.stop();
    });
  }
  generateHTMLCode(item: Employee) {
    const html = `<page size="A4">
    <span id="name" dir="rtl">${item.name}</span><br>
    <span id="username">${item.username}</span><br>
    <span id="password">${item.password}</span><br>
    <span id="school">${this.API.school.name}</span><br>
    </page>`;
    return html;
  }
  async printHTML(student: Employee = null) {
    const htmls = [];
    if (!student) {
      const data = [];
      this.selected.forEach((employeeIndex) => data.push(this.employees[employeeIndex]));
      for (const item of data) {
        htmls.push(await this.generateHTMLCode(item));
      }
    } else {
      htmls.push(await this.generateHTMLCode(student));
    }

    // tslint:disable-next-line: max-line-length
    const win = window.open('', 'Print', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + screen.width + ',height=' + screen.height + ',top=0,left=0');
    win.document.body.innerHTML = `<!DOCTYPE html>
    <html>
    <head>
    <meta charset="UTF-8" />
    <style id="webmakerstyle">
    span {
      position: relative;
      font-weight: bolder;
      font-size: 13pt;
      //background: #ff00001c;
      display: inline-block;
      width: 180px;
    }
    #school {
      top: 65px;
      left: 266px;
      text-align: center;
      width: 260px;
    }
    #name {
      top: 198px;
      left: 80px;
      direction: rtl;
      text-align: right;
    }
    #class {
      top: 205px;
      left: 65px;
      direction: rtl;
      text-align: right;
    }
    #username {
      top: 201px;
      left: 70px;
    }
    #password {
      top: 207px;
      left: 70px;
    }
    body {
      background: rgb(204,204,204);
    }
    page[size="A4"] {
      background: white;
      width: 21cm;
      height: 29.7cm;
      display: block;
      margin: 0 auto;
      margin-bottom: 0.5cm;
      box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
      background: url(https://dashboard.sadeemlight.com/bg2.jpg);
      background-repeat: no-repeat;
      background-size: cover;
    }
    @media print {
      body, page[size="A4"] {
        margin: 0;
        box-shadow: unset;
      }
    }
    </style>
    </head>
    <body>
    ${htmls.join('')}
    </body>
    </html>`;
  }
  isOnline(employee: Employee) {
    return Math.floor(new Date(employee.lastOnline).getTime() / 1000) >= (Math.floor(new Date().getTime() / 1000) - 300);
  }
  generateQRCodeSvg(item: Employee) {
    const canvas: HTMLCanvasElement = (this.qrCanvas.nativeElement as HTMLCanvasElement);
    // new QrCodeWithLogo({
    //   canvas,
    //   content: 'https://github.com/zxpsuper',
    //   width: 380,
    //   //   download: true,
    //   logo: {
    //     src: 'https://avatars1.githubusercontent.com/u/28730619?s=460&v=4'
    //   }
    // })
    //   .toImage()
    //   .then((test) => {
    //     console.log(test);
    //   });
    const md5 = new Md5();
    return new Promise((resolve, reject) => {
      // const canvas: HTMLCanvasElement = (this.qrCanvas.nativeElement as HTMLCanvasElement);
      const ctx = canvas.getContext('2d');
      const qr = new QRious({
        value: 'Sadeem:' + item.username + '_' + md5.appendStr(item.password + this.secret).end(),
        size: 480,
      });
      const logoImage = new Image();
      logoImage.src = 'assets/img/qr.jpg';
      logoImage.addEventListener('load', () => {
        const qrImage = new Image();
        qrImage.src = qr.image.src;
        qrImage.addEventListener('load', () => {
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.rotate(0);
          ctx.drawImage(qr.image, 0, 0, 480, 450);
          ctx.font = 'bolder 30px Arial';
          ctx.fillStyle = 'black';
          ctx.direction = 'rtl';
          ctx.fillText(item.name, 470, 470);
          this.drawLogo(qr, canvas, logoImage);
          resolve(canvas.toDataURL());
        });
      });
    });
  }
  drawLogo(qr: any, canvas: HTMLCanvasElement, logo: HTMLImageElement) {
    const ctx = canvas.getContext('2d');
    const logoWidth = logo.width;
    const logoHeight = logo.height;
    const width = 60;
    const height = 60;
    const x = (qr.size / 2) - (width / 2);
    const y = (qr.size / 2) - (height / 2);
    const maskPadding = qr.size / 30;

    ctx.globalCompositeOperation = 'destination-out';
    ctx.drawImage(logo, 0, 0, logoWidth, logoHeight, x - maskPadding, y - maskPadding, width + (maskPadding * 2), height + (maskPadding * 2));

    ctx.globalCompositeOperation = 'source-over';
    ctx.drawImage(logo, 0, 0, logoWidth, logoHeight, x, y, width, height);
  }
  async printQR(teacher: Employee = null) {
    const svgs = [];
    if (!teacher) {
      const data = [];
      Object.keys(this.selected).forEach(key => {
        data.push(this.employees[key]);
      });
      for (const item of data) {
        svgs.push(await this.generateQRCodeSvg(item));
      }
    } else {
      svgs.push(await this.generateQRCodeSvg(teacher));
    }

    // tslint:disable-next-line: max-line-length
    const win = window.open('', 'Print', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + screen.width + ',height=' + screen.height + ',top=0,left=0');
    win.document.body.innerHTML = `<div style='text-algin:center;'>${svgs.map(base => `<img src='${base}' />`).join('')}</div>`;
  }
}
