import { Injectable } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ImageBox {

  constructor(public domSanitizationService: DomSanitizer) { }
  openInBox(url: string) {
    $('#image').attr('src', url);
    $('#modal-image').modal('show');
  }
}
