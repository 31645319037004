export const Contries = [
    {
        name: 'جزر أولان ',
        code: 'AX'
    },
    {
        name: 'ألبانيا ',
        code: 'AL'
    },
    {
        name: 'الجزائر',
        code: 'DZ'
    },
    {
        name: 'ساموا الأمريكية ',
        code: 'AS'
    },
    {
        name: 'أندورا ',
        code: 'AD'
    },
    {
        name: 'أنغولا ',
        code: 'AO'
    },
    {
        name: 'أنغويلا ',
        code: 'AI'
    },
    {
        name: 'أنتاركتيكا ',
        code: 'AQ'
    },
    {
        name: 'أنتيغوا وبربودا ',
        code: 'AG'
    },
    {
        name: 'الأرجنتين ',
        code: 'AR'
    },
    {
        name: 'أرمينيا ',
        code: 'AM'
    },
    {
        name: 'آروبا ',
        code: 'AW'
    },
    {
        name: 'أستراليا ',
        code: 'AU'
    },
    {
        name: 'النمسا ',
        code: 'AT'
    },
    {
        name: 'أذربيجان ',
        code: 'AZ'
    },
    {
        name: 'الباهاما ',
        code: 'BS'
    },
    {
        name: 'البحرين',
        code: 'BH'
    },
    {
        name: 'بنجلاديش ',
        code: 'BD'
    },
    {
        name: 'بربادوس ',
        code: 'BB'
    },
    {
        name: 'روسيا البيضاء ',
        code: 'BY'
    },
    {
        name: 'بلجيكا ',
        code: 'BE'
    },
    {
        name: 'بليز ',
        code: 'BZ'
    },
    {
        name: 'بنين ',
        code: 'BJ'
    },
    {
        name: 'برمودا ',
        code: 'BM'
    },
    {
        name: 'بوتان ',
        code: 'BT'
    },
    {
        name: 'بوليفيا ',
        code: 'BO'
    },
    {
        name: 'هولندا الكاريبية ',
        code: 'BQ'
    },
    {
        name: 'البوسنة والهرسك ',
        code: 'BA'
    },
    {
        name: 'بتسوانا ',
        code: 'BW'
    },
    {
        name: 'جزيرة بوفيه ',
        code: 'BV'
    },
    {
        name: 'البرازيل ',
        code: 'BR'
    },
    {
        name: 'الإقليم البريطاني في المحيط الهندي ',
        code: 'IO'
    },
    {
        name: 'بروناي ',
        code: 'BN'
    },
    {
        name: 'بلغاريا ',
        code: 'BG'
    },
    {
        name: 'بوركينا فاسو ',
        code: 'BF'
    },
    {
        name: 'بوروندي ',
        code: 'BI'
    },
    {
        name: 'كمبوديا ',
        code: 'KH'
    },
    {
        name: 'الكاميرون ',
        code: 'CM'
    },
    {
        name: 'كندا ',
        code: 'CA'
    },
    {
        name: 'الرأس الأخضر ',
        code: 'CV'
    },
    {
        name: 'جزر الكايمن ',
        code: 'KY'
    },
    {
        name: 'جمهورية أفريقيا الوسطى ',
        code: 'CF'
    },
    {
        name: 'تشاد ',
        code: 'TD'
    },
    {
        name: 'شيلي ',
        code: 'CL'
    },
    {
        name: 'الصين ',
        code: 'CN'
    },
    {
        name: 'جزيرة الكريسماس ',
        code: 'CX'
    },
    {
        name: 'جزر كوكوس ',
        code: 'CC'
    },
    {
        name: 'كولومبيا ',
        code: 'CO'
    },
    {
        name: 'جزر القمر',
        code: 'KM'
    },
    {
        name: 'الكونغو - برازافيل ',
        code: 'CG'
    },
    {
        name: 'الكونغو - كينشاسا ',
        code: 'CD'
    },
    {
        name: 'جزر كوك ',
        code: 'CK'
    },
    {
        name: 'كوستاريكا ',
        code: 'CR'
    },
    {
        name: 'ساحل العاج ',
        code: 'CI'
    },
    {
        name: 'كرواتيا ',
        code: 'HR'
    },
    {
        name: 'كوبا ',
        code: 'CU'
    },
    {
        name: 'كوراساو ',
        code: 'CW'
    },
    {
        name: 'قبرص ',
        code: 'CY'
    },
    {
        name: 'جمهورية التشيك ',
        code: 'CZ'
    },
    {
        name: 'الدانمرك ',
        code: 'DK'
    },
    {
        name: 'جيبوتي ',
        code: 'DJ'
    },
    {
        name: 'دومينيكا ',
        code: 'DM'
    },
    {
        name: 'جمهورية الدومينيك ',
        code: 'DO'
    },
    {
        name: 'الإكوادور ',
        code: 'EC'
    },
    {
        name: 'مصر',
        code: 'EG'
    },
    {
        name: 'السلفادور ',
        code: 'SV'
    },
    {
        name: 'غينيا الإستوائية ',
        code: 'GQ'
    },
    {
        name: 'أريتريا ',
        code: 'ER'
    },
    {
        name: 'أستونيا ',
        code: 'EE'
    },
    {
        name: 'إثيوبيا ',
        code: 'ET'
    },
    {
        name: 'جزر فوكلاند ',
        code: 'FK'
    },
    {
        name: 'جزر فارو ',
        code: 'FO'
    },
    {
        name: 'فيجي ',
        code: 'FJ'
    },
    {
        name: 'فنلندا ',
        code: 'FI'
    },
    {
        name: 'فرنسا ',
        code: 'FR'
    },
    {
        name: 'غويانا الفرنسية ',
        code: 'GF'
    },
    {
        name: 'بولينيزيا الفرنسية ',
        code: 'PF'
    },
    {
        name: 'المقاطعات الجنوبية الفرنسية ',
        code: 'TF'
    },
    {
        name: 'الجابون ',
        code: 'GA'
    },
    {
        name: 'غامبيا ',
        code: 'GM'
    },
    {
        name: 'جورجيا ',
        code: 'GE'
    },
    {
        name: 'ألمانيا ',
        code: 'DE'
    },
    {
        name: 'غانا ',
        code: 'GH'
    },
    {
        name: 'جبل طارق ',
        code: 'GI'
    },
    {
        name: 'اليونان ',
        code: 'GR'
    },
    {
        name: 'غرينلاند ',
        code: 'GL'
    },
    {
        name: 'غرينادا ',
        code: 'GD'
    },
    {
        name: 'جوادلوب ',
        code: 'GP'
    },
    {
        name: 'غوام ',
        code: 'GU'
    },
    {
        name: 'غواتيمالا ',
        code: 'GT'
    },
    {
        name: 'غيرنزي ',
        code: 'GG'
    },
    {
        name: 'غينيا ',
        code: 'GN'
    },
    {
        name: 'غينيا بيساو ',
        code: 'GW'
    },
    {
        name: 'غيانا ',
        code: 'GY'
    },
    {
        name: 'هايتي ',
        code: 'HT'
    },
    {
        name: 'جزيرة هيرد وجزر ماكدونالد ',
        code: 'HM'
    },
    {
        name: 'الفاتيكان ',
        code: 'VA'
    },
    {
        name: 'هندوراس ',
        code: 'HN'
    },
    {
        name: 'هونغ كونغ ',
        code: 'HK'
    },
    {
        name: 'هنغاريا ',
        code: 'HU'
    },
    {
        name: 'أيسلندا ',
        code: 'IS'
    },
    {
        name: 'الهند ',
        code: 'IN'
    },
    {
        name: 'أندونيسيا ',
        code: 'ID'
    },
    {
        name: 'ایران',
        code: 'IR'
    },
    {
        name: 'العراق',
        code: 'IQ'
    },
    {
        name: 'أيرلندا ',
        code: 'IE'
    },
    {
        name: 'جزيرة مان ',
        code: 'IM'
    },
    {
        name: 'إسرائيل',
        code: 'IL'
    },
    {
        name: 'إيطاليا ',
        code: 'IT'
    },
    {
        name: 'جامايكا ',
        code: 'JM'
    },
    {
        name: 'اليابان ',
        code: 'JP'
    },
    {
        name: 'جيرسي ',
        code: 'JE'
    },
    {
        name: 'الأردن',
        code: 'JO'
    },
    {
        name: 'كازاخستان ',
        code: 'KZ'
    },
    {
        name: 'كينيا ',
        code: 'KE'
    },
    {
        name: 'كيريباتي ',
        code: 'KI'
    },
    {
        name: 'كوريا الشمالية ',
        code: 'KP'
    },
    {
        name: 'كوريا الجنوبية ',
        code: 'KR'
    },
    {
        name: 'الكويت',
        code: 'KW'
    },
    {
        name: 'قرغيزستان ',
        code: 'KG'
    },
    {
        name: 'لاوس ',
        code: 'LA'
    },
    {
        name: 'لاتفيا ',
        code: 'LV'
    },
    {
        name: 'لبنان',
        code: 'LB'
    },
    {
        name: 'ليسوتو ',
        code: 'LS'
    },
    {
        name: 'ليبيريا ',
        code: 'LR'
    },
    {
        name: 'ليبيا',
        code: 'LY'
    },
    {
        name: 'ليختنشتاين ',
        code: 'LI'
    },
    {
        name: 'ليتوانيا ',
        code: 'LT'
    },
    {
        name: 'لوكسمبورغ ',
        code: 'LU'
    },
    {
        name: 'مكاو ',
        code: 'MO'
    },
    {
        name: 'مقدونيا ',
        code: 'MK'
    },
    {
        name: 'مدغشقر ',
        code: 'MG'
    },
    {
        name: 'ملاوي ',
        code: 'MW'
    },
    {
        name: 'ماليزيا ',
        code: 'MY'
    },
    {
        name: 'جزر المالديف ',
        code: 'MV'
    },
    {
        name: 'مالي ',
        code: 'ML'
    },
    {
        name: 'مالطا ',
        code: 'MT'
    },
    {
        name: 'جزر المارشال ',
        code: 'MH'
    },
    {
        name: 'مارتينيك ',
        code: 'MQ'
    },
    {
        name: 'موريتانيا',
        code: 'MR'
    },
    {
        name: 'موريشيوس ',
        code: 'MU'
    },
    {
        name: 'مايوت ',
        code: 'YT'
    },
    {
        name: 'المكسيك ',
        code: 'MX'
    },
    {
        name: 'ميكرونيزيا ',
        code: 'FM'
    },
    {
        name: 'مولدافيا ',
        code: 'MD'
    },
    {
        name: 'موناكو ',
        code: 'MC'
    },
    {
        name: 'منغوليا ',
        code: 'MN'
    },
    {
        name: 'الجبل الأسود ',
        code: 'ME'
    },
    {
        name: 'مونتسرات ',
        code: 'MS'
    },
    {
        name: 'المغرب',
        code: 'MA'
    },
    {
        name: 'موزمبيق ',
        code: 'MZ'
    },
    {
        name: 'ميانمار -بورما ',
        code: 'MM'
    },
    {
        name: 'ناميبيا ',
        code: 'NA'
    },
    {
        name: 'ناورو ',
        code: 'NR'
    },
    {
        name: 'نيبال ',
        code: 'NP'
    },
    {
        name: 'هولندا ',
        code: 'NL'
    },
    {
        name: 'كاليدونيا الجديدة ',
        code: 'NC'
    },
    {
        name: 'نيوزيلاندا ',
        code: 'NZ'
    },
    {
        name: 'نيكاراغوا ',
        code: 'NI'
    },
    {
        name: 'النيجر ',
        code: 'NE'
    },
    {
        name: 'نيجيريا ',
        code: 'NG'
    },
    {
        name: 'نيوي ',
        code: 'NU'
    },
    {
        name: 'جزيرة نورفوك ',
        code: 'NF'
    },
    {
        name: 'جزر ماريانا الشمالية ',
        code: 'MP'
    },
    {
        name: 'النرويج ',
        code: 'NO'
    },
    {
        name: 'عُمان',
        code: 'OM'
    },
    {
        name: 'باكستان',
        code: 'PK'
    },
    {
        name: 'بالاو ',
        code: 'PW'
    },
    {
        name: 'فلسطين',
        code: 'PS'
    },
    {
        name: 'بنما ',
        code: 'PA'
    },
    {
        name: 'بابوا غينيا الجديدة ',
        code: 'PG'
    },
    {
        name: 'باراغواي ',
        code: 'PY'
    },
    {
        name: 'بيرو ',
        code: 'PE'
    },
    {
        name: 'الفيلبين ',
        code: 'PH'
    },
    {
        name: 'جزر بيتكيرن ',
        code: 'PN'
    },
    {
        name: 'بولندا ',
        code: 'PL'
    },
    {
        name: 'البرتغال ',
        code: 'PT'
    },
    {
        name: 'بورتوريكو ',
        code: 'PR'
    },
    {
        name: 'قطر',
        code: 'QA'
    },
    {
        name: 'روينيون ',
        code: 'RE'
    },
    {
        name: 'رومانيا ',
        code: 'RO'
    },
    {
        name: 'روسيا ',
        code: 'RU'
    },
    {
        name: 'رواندا ',
        code: 'RW'
    },
    {
        name: 'سان بارتليمي ',
        code: 'BL'
    },
    {
        name: 'سانت هيلنا ',
        code: 'SH'
    },
    {
        name: 'سانت كيتس ونيفيس ',
        code: 'KN'
    },
    {
        name: 'سانت لوسيا ',
        code: 'LC'
    },
    {
        name: 'سانت مارتن ',
        code: 'MF'
    },
    {
        name: 'سانت بيير وميكولون ',
        code: 'PM'
    },
    {
        name: 'سانت فنسنت وغرنادين ',
        code: 'VC'
    },
    {
        name: 'ساموا ',
        code: 'WS'
    },
    {
        name: 'سان مارينو ',
        code: 'SM'
    },
    {
        name: 'ساو تومي وبرينسيبي ',
        code: 'ST'
    },
    {
        name: 'المملكة العربية السعودية',
        code: 'SA'
    },
    {
        name: 'السنغال ',
        code: 'SN'
    },
    {
        name: 'صربيا ',
        code: 'RS'
    },
    {
        name: 'سيشل ',
        code: 'SC'
    },
    {
        name: 'سيراليون ',
        code: 'SL'
    },
    {
        name: 'سنغافورة ',
        code: 'SG'
    },
    {
        name: 'سينت مارتن ',
        code: 'SX'
    },
    {
        name: 'سلوفاكيا ',
        code: 'SK'
    },
    {
        name: 'سلوفينيا ',
        code: 'SI'
    },
    {
        name: 'جزر سليمان ',
        code: 'SB'
    },
    {
        name: 'الصومال ',
        code: 'SO'
    },
    {
        name: 'جنوب أفريقيا ',
        code: 'ZA'
    },
    {
        name: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية ',
        code: 'GS'
    },
    {
        name: 'جنوب السودان',
        code: 'SS'
    },
    {
        name: 'إسبانيا ',
        code: 'ES'
    },
    {
        name: 'سريلانكا ',
        code: 'LK'
    },
    {
        name: 'السودان',
        code: 'SD'
    },
    {
        name: 'سورينام ',
        code: 'SR'
    },
    {
        name: 'سفالبارد وجان مايان ',
        code: 'SJ'
    },
    {
        name: 'سوازيلاند ',
        code: 'SZ'
    },
    {
        name: 'السويد ',
        code: 'SE'
    },
    {
        name: 'سويسرا ',
        code: 'CH'
    },
    {
        name: 'سوريا',
        code: 'SY'
    },
    {
        name: 'تايوان ',
        code: 'TW'
    },
    {
        name: 'طاجكستان ',
        code: 'TJ'
    },
    {
        name: 'تانزانيا ',
        code: 'TZ'
    },
    {
        name: 'تايلند ',
        code: 'TH'
    },
    {
        name: 'تيمور الشرقية ',
        code: 'TL'
    },
    {
        name: 'توجو ',
        code: 'TG'
    },
    {
        name: 'توكيلو ',
        code: 'TK'
    },
    {
        name: 'تونغا ',
        code: 'TO'
    },
    {
        name: 'ترينيداد وتوباغو ',
        code: 'TT'
    },
    {
        name: 'تونس',
        code: 'TN'
    },
    {
        name: 'تركيا ',
        code: 'TR'
    },
    {
        name: 'تركمانستان ',
        code: 'TM'
    },
    {
        name: 'جزر الترك وجايكوس ',
        code: 'TC'
    },
    {
        name: 'توفالو ',
        code: 'TV'
    },
    {
        name: 'أوغندا ',
        code: 'UG'
    },
    {
        name: 'أوكرانيا ',
        code: 'UA'
    },
    {
        name: 'الإمارات العربية المتحدة',
        code: 'AE'
    },
    {
        name: 'المملكة المتحدة ',
        code: 'GB'
    },
    {
        name: 'الولايات المتحدة ',
        code: 'US'
    },
    {
        name: 'جزر الولايات المتحدة البعيدة الصغيرة ',
        code: 'UM'
    },
    {
        name: 'أورغواي ',
        code: 'UY'
    },
    {
        name: 'أوزبكستان ',
        code: 'UZ'
    },
    {
        name: 'فانواتو ',
        code: 'VU'
    },
    {
        name: 'فنزويلا ',
        code: 'VE'
    },
    {
        name: 'فيتنام ',
        code: 'VN'
    },
    {
        name: 'جزر فرجين البريطانية ',
        code: 'VG'
    },
    {
        name: 'جزر فرجين الأمريكية ',
        code: 'VI'
    },
    {
        name: 'جزر والس وفوتونا ',
        code: 'WF'
    },
    {
        name: 'الصحراء الغربية',
        code: 'EH'
    },
    {
        name: 'اليمن',
        code: 'YE'
    },
    {
        name: 'زامبيا ',
        code: 'ZM'
    },
    {
        name: 'زيمبابوي ',
        code: 'ZW'
    }
];
