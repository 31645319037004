import { Pipe, PipeTransform } from '@angular/core';
import {ZoomData} from './interfaces/API';

@Pipe({
  name: 'zoom'
})
export class ZoomPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const zoomLink: ZoomData = value;
    return `https://us04web.zoom.us/j/${zoomLink.id}?pwd=${zoomLink.password}&prefer=1`;
  }

}
