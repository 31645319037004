import {animate, group, keyframes, query, style, transition, trigger} from '@angular/animations';

export const optional = {optional: true};
export const slider   =
               trigger('routeAnimations', [
                 transition('* => isLeft', fader()),
                 transition('* => isRight', fader()),
                 transition('isRight => *', fader()),
                 transition('isLeft => *', fader()),
                 transition('none => *', []),
                 transition('* => none', [])
               ]);

export function slideTo(direction) {
  return [
    query(':enter, :leave', [
      style({
        position   : 'absolute',
        top        : 0,
        [direction]: 0,
        width      : '100%'
      })
    ], optional),
    query(':enter', [
      style({[direction]: '-100%'})
    ], optional),
    group([
      query(':leave', [
        animate('600ms ease', style({[direction]: '100%'}))
      ], optional),
      query(':enter', [
        animate('600ms ease', style({[direction]: '0%'}))
      ], optional)
    ]),
    // Normalize the page style... Might not be necessary

    // Required only if you have child animations on the page
    // query(':leave', animateChild()),
    // query(':enter', animateChild()),
  ];
}

export const stepper     =
               trigger('routeAnimations', [
                 transition('* <=> *', [
                   query(':enter, :leave', [
                     style({
                       position: 'absolute',
                       left    : 0,
                       width   : '100%',
                     }),
                   ]),
                   group([
                     query(':enter', [
                       animate('2000ms ease', keyframes([
                         style({transform: 'scale(0) translateX(100%)', offset: 0}),
                         style({transform: 'scale(0.5) translateX(25%)', offset: 0.3}),
                         style({transform: 'scale(1) translateX(0%)', offset: 1}),
                       ])),
                     ]),
                     query(':leave', [
                       animate('2000ms ease', keyframes([
                         style({transform: 'scale(1)', offset: 0}),
                         style({transform: 'scale(0.5) translateX(-25%) rotate(0)', offset: 0.35}),
                         style({opacity: 0, transform: 'translateX(-50%) rotate(-180deg) scale(6)', offset: 1}),
                       ])),
                     ])
                   ]),
                 ])

               ]);
export const transformer =
               trigger('routeAnimations', [
                 transition('* => isLeft', transformTo({x: -100, y: -100, rotate: -720})),
                 transition('* => isRight', transformTo({x: 100, y: -100, rotate: 90})),
                 transition('isRight => *', transformTo({x: -100, y: -100, rotate: 360})),
                 transition('isLeft => *', transformTo({x: 100, y: -100, rotate: -360}))
               ]);


function transformTo({x = 100, y = 0, rotate = 0}) {
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top     : 0,
        left    : 0,
        width   : '100%'
      })
    ], optional),
    query(':enter', [
      style({transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`})
    ]),
    group([
      query(':leave', [
        animate('600ms ease-out', style({transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`}))
      ], optional),
      query(':enter', [
        animate('600ms ease-out', style({transform: `translate(0, 0) rotate(0)`}))
      ])
    ]),
  ];
}

export function fader() {
  return [
    query(':enter, :leave', [
      style({
        position : 'absolute',
        left     : 0,
        width    : '100%',
        opacity  : 0,
        transform: 'scale(0) translateY(100%)',
      }),
    ], optional),
    query(':enter', [
      animate('600ms ease', style({opacity: 1, transform: 'scale(1) translateY(0)'})),
    ], optional),
    query(':leave', [
      animate('0ms ease', style({opacity: 0, transform: 'scale(0) translateY(0)'})),
    ], optional)
  ];
}
