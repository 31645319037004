import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ResponsiveService} from '../../services/responsive.service';


@Component({
  selector: 'app-news2',
  templateUrl: './news2.component.html',
  styleUrls: ['./news2.component.scss']
})
export class News2Component implements AfterViewInit, OnInit {



  constructor(public responsive: ResponsiveService) {
  }
  ngAfterViewInit() {

  }

  ngOnInit(): void {
    this.responsive.check();
  }

}
