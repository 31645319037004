import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../../../services/print.service';
import {APIService} from '../../../services/API.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, AfterViewInit {
  type = 'details';

  studyMonths = {
    semester: {month_1: 'كانون الاول', month_2: 'كانون الثاني', month_3: 'تشرين الاول', month_4: 'تشرين الثاني', middle_year: 'نصف السنه', month_5: 'شباط', month_6: 'اذار', month_7: 'نيسان', month_8: 'ايار', last_year: 'الامتحان النهائي', completion_1: 'الدور التكميلي الاول', completion_2: 'الدور التكميلي الثاني'},
    course: {month_1: 'شهر الاول كورس اول', month_2: 'شهر الثاني كورس اول', middle_year: 'كورس اول', month_5: 'شهر الاول كورس الثاني', month_6: 'شهر الثاني كورس الثاني', last_year: 'كورس ثاني', completion_1: 'الدور التكميلي الاول', completion_2: 'الدور التكميلي الثاني'},
  };
  ratesTrans = { Excellent: 'ممتاز', 'Very Good': 'جيد جداً', Good: 'جيد', Average: 'متوسط', Failure: 'ضعيف'};

  translate: {[key: string]: string} = {
    attended: 'حاضر',
    leave: 'مجاز',
    absent: 'غائب'
  };
  classes = ['الاول', 'الثاني', 'الثالث', 'الرابع', 'الخامس', 'السادس', 'السابع'];
  constructor(private route: ActivatedRoute,
              public API: APIService,
              public printService: PrintService) {
  }

  ngOnInit() {
    this.type = this.route.snapshot.params.type;
  }
  ngAfterViewInit(): void {
    setTimeout(() => this.printService.onDataReady(), 1000);
  }

  getKeys(item: any, type: string = 'string') {
    return Object.keys(item).filter(key => (typeof item[key] === type) && item[key].length > 0);
  }
  getKeys2(item: any, type: string = 'string') {
    return Object.keys(item).filter(key => (typeof item[key] === type));
  }
}
