import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Subject } from 'rxjs';
import { APIConfig } from '../../config/API';
import {Account, Installment, InstallmentGroup} from '../../interfaces/API';
import { APIService } from '../../services/API.service';
import { ResponsiveService } from '../../services/responsive.service';

declare var $: any;

@Component({
  selector   : 'app-transportation',
  templateUrl: './installment-class.component.html',
  styleUrls  : ['./installment-class.component.scss']
})
export class InstallmentClassComponent implements AfterViewInit, OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  @BlockUI('table') blockUI: NgBlockUI;
  formMode: 'adding' | 'editing' | 'deleting' | 'details' | 'filter' = 'adding';
  installmentsData: InstallmentGroup[]                                   = [];
  installments: InstallmentGroup[]                                       = [];
  dropdown                                                = false;
  dropdownData: InstallmentGroup                                    = null;
  mainForm: FormGroup;
  filterForm: FormGroup;
  dtTrigger: Subject<any>                                 = new Subject();
  selectedData: {[key: string]: InstallmentGroup}             = {};
  selected: string[]             = [];
  total = 0;
  types                                                  = {
    IQD  : {
      text : 'دينار عراقي',
      class: 'success'
    },
    USD: {
      text : 'دولار امريكي',
      class: 'warning'
    },
  };
  methods                                                 = {
    cash    : {
      text : 'كاش',
      class: 'success'
    },
    cheque    : {
      text : 'شيك',
      class: 'primary'
    },
  };
  dataTablesParameters: any;
  dtOptions: DataTables.Settings                          = {};
  changingId: string                                      = null;
  filtered = false;
  totalAmount = 0;
  adding = false;
  constructor(public responsive: ResponsiveService, public sanitizer: DomSanitizer, private formBuilder: FormBuilder, public API: APIService,
              private http: HttpClient,
              private datePipe: DatePipe) {
    this.filterForm = this.formBuilder.group({
      class: ['', []],
      dateTo: ['', []],
      dateFrom: ['', []],
    });
    this.filterForm.valueChanges.subscribe(data => {
      this.filtered = data.dateFrom !== '' || data.dateTo !== '' || data.class !== '';
      this.fetch();
    });
  }

  displayWith(obj?: Account): string | undefined {
    return obj ? obj.title : undefined;
  }
  clearFilter() {
    this.filterForm.patchValue({
      class   : '',
      dateFrom   : '',
      dateTo   : '',
    });
    this.filtered = false;
  }
  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.responsive.check();
    this.dtOptions = {
      info        : false,
      search      : true,
      lengthMenu  : [10, 25, 50, 100, 250, 500],
      lengthChange: true,
      pageLength  : 10,
      dom         : 'Rfrtlip',
      autoWidth   : false,
      columns     : [
        {width: '25%', name: 'student', orderable: true},
        {width: '25%', name: 'installment', orderable: true},
        {width: '25%', name: 'amount', orderable: true},
        {width: '25%', name: 'remain', orderable: true},
      ],
      columnDefs  : [
        {orderable: true, targets: [4]}
      ],
      order       : [1, 'desc'],
      serverSide  : true,
      processing  : true,
      ajax        : (dataTablesParameters: any, callback) => {
        this.dataTablesParameters = dataTablesParameters;
        this.blockUI.stop();
        // this.blockUI.start('جاري التحميل...');
        this.http
          .post<{
            data: InstallmentGroup[];
            recordsTotal: number;
            recordsFiltered: number;
          }>(
            APIConfig.uri + `installment/class/datatable`, {
              ...dataTablesParameters,
              class: this.filterForm.value.class.length > 0 ? this.filterForm.value.class : '',
              dateFrom: this.filterForm.value.dateFrom ? this.filterForm.value.dateFrom : '',
              dateTo: this.filterForm.value.dateTo ? this.filterForm.value.dateTo : '',
            }, {headers: this.API.AuthHeaders}
          ).subscribe(resp => {
          this.blockUI.stop();
          this.installments     = resp.data;
          this.installmentsData = resp.data;
          callback({
            recordsTotal   : resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data           : []
          });
        });
      },
      language    : {
        search     : 'بحث :',
        lengthMenu : 'عرض _MENU_ حقل في الصفحه',
        paginate   : {
          first   : 'الاولى',
          last    : 'الاخيرة',
          next    : 'التالي',
          previous: 'السابق'
        },
        info       : 'عرض _END_ من اصل _TOTAL_ المجموع',
        zeroRecords: 'لا يوجد تطابق مع البحث',
        emptyTable : 'لا يوجد معلومات لعرضها'
      }
    };
    setTimeout(() => {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
      dtInstance.on('draw.dt', () => {
        if (this.installments.length > 0) {
          $('.dataTables_empty').remove();
        }
      });
    });
    });
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().substring(0, 10);
  }

  dropDown(event: MouseEvent, language: InstallmentGroup): void {
    const dropdownMenu = $('#dropdown');
    const bodyOffsets  = document.body.getBoundingClientRect();
    const tempX        = !this.responsive.isMobile ? bodyOffsets.right - event.pageX - dropdownMenu.width() - $('app-side').width() : event.pageX + dropdownMenu.width();
    const tempY        = event.pageY + dropdownMenu.height() > bodyOffsets.bottom ? bodyOffsets.bottom - dropdownMenu.height() - 50 : event.pageY;
    this.dropdownData  = language;
    dropdownMenu.css({top: tempY, right: tempX});
    dropdownMenu.addClass('show');
    $(document).click((e) => {
      if (!$(e.target).hasClass('dropdown')) {
        dropdownMenu.removeClass('show');
      }
    });
  }

  fetch() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  redrawDataTable() {
    const instance = this.dtElement.dtInstance;
    if (instance) {
      instance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        this.blockUI.stop();
      });
    } else {
      this.dtTrigger.next();
      this.blockUI.stop();
    }
    this.changingId = null;
  }
}
