import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {APIService} from '../../services/API.service';
import {AuthService} from '../../services/auth.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Employee, ICurrency} from '../../interfaces/API';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewChecked {
  infoForm: FormGroup;
  passwordForm: FormGroup;
  notificationsSounds: boolean = localStorage.getItem('notifications_sounds') === 'true';
  routeChangeEffect: boolean = localStorage.getItem('route_change_effect') === 'true';
  notificationsDesktop: boolean = localStorage.getItem('notifications_desktop') === 'true';
  notificationsNews: boolean = localStorage.getItem('notifications_news') === 'true';
  currencies: ICurrency[] = [];
  currency = new FormControl('', [Validators.required]);

  constructor(public API: APIService, public AUTH: AuthService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    if (this.route.snapshot.queryParams.code) {
      this.API.createZoom(this.route.snapshot.queryParams.code).then(() => {});
    }
    this.infoForm = this.formBuilder.group({
      username: [{value: '', disabled: 1}, []],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
    this.passwordForm = this.formBuilder.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6), (control => this.checkPasswords(control, 'new_password', this.passwordForm))]],
    });
    this.API.fetchCurrencies().subscribe((response: {currencies: ICurrency[]; selected: ICurrency}) => {
      this.currencies = response.currencies;
      this.currency.patchValue(response.selected._id);
    });
  }

  runValidators() {
    Object.keys(this.passwordForm.controls).forEach(field => {
      const control = this.passwordForm.get(field);
      control.updateValueAndValidity();
    });
  }
  changeCurrency() {
    this.API.changeCurrencies(this.currency.value).subscribe((success) => {
      this.API.showAlert('تم تغيير العملة بنجاح', 'success');
      this.API.fetchSelf();
    });
  }
  checkPasswords(formControl: FormControl, fieldName, group) {
    if (formControl.value === '') {
      return {notSame: true};
    }
    const pass = group.value[fieldName];
    console.log(pass);
    return pass === formControl.value ? null : {notSame: true};
  }

  ngOnInit() {
  }

  Change(type: string, payload: FormGroup | string | boolean) {
    switch (type) {
      case 'info':
        this.API.updateSelfEmployee(this.infoForm.value).then(result => {
        });
        break;
      case 'password':
        this.API.updateSelfPassword(this.passwordForm.value).then(result => {});
        break;
      case 'notifications_sounds':
        this.notificationsSounds = payload as boolean;
        localStorage.setItem('notifications_sounds', JSON.parse(payload.toString()));
        break;
      case 'route_change_effect':
        this.routeChangeEffect = payload as boolean;
        localStorage.setItem('route_change_effect', JSON.parse(payload.toString()));
        break;
    }
  }

  fillData(employee: Employee) {
    this.infoForm.patchValue(employee);
  }

  ngAfterViewChecked(): void {
    if (this.API.employee == null) {
      this.API.fetchSelf().then(data => this.fillData(data.employee));
    } else {
      this.fillData(this.API.employee);
    }
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      const blob = event.target.files[0];
      const type = blob.type.split('/')[0];
      this.API.uploadSchoolImage(blob).then(res => {
        this.API.school.image1 = res;
      });
    }
  }
}
