import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EasyPieChartModule} from 'ng2modules-easypiechart';
import {ChartsModule} from 'ng2-charts';
import {DataTablesModule} from 'angular-datatables';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatVideoModule} from 'mat-video';
import {TextContentTruncatePipe} from './pipes/text-content-truncate.pipe';
import {BlockUIModule} from 'ng-block-ui';
import { TranslateModule } from '@ngx-translate/core';
import {ZoomPipe} from "./zoom.pipe";



@NgModule({
  declarations: [TextContentTruncatePipe, ZoomPipe],
  imports: [
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    EasyPieChartModule,
    ChartsModule,
    DataTablesModule,
    MatSelectModule,
    MatOptionModule,
    MatFormFieldModule,
    NgbModule,
    BlockUIModule,
    MatVideoModule,
    TextContentTruncatePipe,
    ZoomPipe
  ]
})
export class SharedModule { }
